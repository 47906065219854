import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
];

const initialConfig = {
    options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
            display: false,
            text: "Sales Charts",
            fontColor: "black",
        },
        legend: {
            labels: {
                fontColor: "black",
            },
            align: "start",
            position: "top",
        },
        tooltips: {
            mode: "index",
            intersect: false,
        },
        hover: {
            mode: "nearest",
            intersect: true,
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Month",
                        fontColor: "black",
                    },
                    gridLines: {
                        display: false,
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: "rgba(33, 37, 41, 0.3)",
                        zeroLineColor: "rgba(0, 0, 0, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: "Value",
                        fontColor: "black",
                    },
                    gridLines: {
                        borderDash: [3],
                        borderDashOffset: [3],
                        drawBorder: false,
                        color: "rgba(255, 255, 255, 0.15)",
                        zeroLineColor: "rgba(33, 37, 41, 0)",
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                    },
                },
            ],
        },
    },
};

const LineChart = ({ data, className, style, period }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        console.log(data);
        let labels = data?.map((item) => `${item.day} ${months[item.month - 1]}`);

        if (period === "month") {
            labels = data?.map((item) => `${months[item.month - 1]}`);
        }

        if (period === "hour") {
            labels = data?.map((item) => `${item.hour}`);
        }

        let datas = data?.map(
            (data_) => data_.count
        );
        let ctx = chartRef?.current?.getContext("2d");

        if (typeof window !== "undefined" && window.bar !== undefined) {
            window.bar.destroy();
        }

        window.bar = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets:
                    data === ""
                        ? []
                        : [
                            {
                                label: "Satışlar",
                                backgroundColor: "#c72328",
                                borderColor: "#c72328",
                                data: datas,
                                fill: false,
                            },
                        ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false

            }
        });
    }, [data]);

    return (
        <div className={`${className}`}>
            <canvas style={style} ref={chartRef} id="line-chart"></canvas>
        </div>
    )
}

export default LineChart;
import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const getStatistics = () => {
    return axios.post("/api/graphql", {
        query: `
            query{getStatistics(
                period: DAILY
            ){
                totalPayment
                totalClosedOrder
                totalWaitingOrder
                ordersOfDays {
                    day
                    month
                    year
                    count
                }
                ordersOfWeek {
                    day
                    month
                    year
                    count
                }
                ordersOfMonth {
                    day
                    month
                    year
                    count
                }
                ordersOfToday {
                    hour
                    count
                }
                paymentsOfToday {
                    type
                    total
                }
                ordersOfTodayByMenuItem {
                    name
                    count
                }
            }}
            `,
    }, {
        headers: {

        }
    });

}
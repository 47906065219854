import React, { useEffect } from 'react';
import { Result, Button } from 'antd';
import { loadPlaceSubcsriptionDetailsService } from '../../services/places';

const PurchaseSuccess = () => {
    useEffect(() => {
        let place = JSON.parse(localStorage.getItem('authUser'))?.place;

        loadPlaceSubcsriptionDetailsService({ id: place.id }).then((response) => {
            if (response.data.data.places.length > 0) {
                place = {...place, ...response.data.data.places[0]};

                console.log(place);

                localStorage.setItem('authUser', JSON.stringify({ ...JSON.parse(localStorage.getItem('authUser')), place }));
            }
        }).catch((error) => {
            console.log(error);
        });

    }, []);
    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"} d-flex justify-content-center text-center`} style={{ borderRadius: "7px" }}>
                <Result
                    status="success"
                    title="Ödeme başarılı"
                    subTitle="Bizi Tercih ettiğiniz için teşekkürler!"
                    extra={[
                        <div className='d-flex justify-content-center'>
                            <Button type="primary" key="continue" size='large' onClick={() => window.location.href = "/"}>
                                Kullanmaya Başla
                            </Button>
                        </div>
                    ]}
                />
            </div>
        </React.Fragment>
    );
};

export default PurchaseSuccess;
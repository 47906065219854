import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"

import { registerService } from "../../../services/auth";
import { apiError } from "../login/actions";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    //console.log("user", user)
    let response = yield call(registerService, user);
    response = response.data;
    
    if (!response.errors) {
      response = response.data.registerPortal;
      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("place", response?.place?.id);
      console.log("response", response);
      //window.location.href = "/login";
      window.location.href = "/";
      yield put(registerUserSuccessful(response));
    } else {
      yield put(registerUserFailed(response.errors[0].message));

    }

  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga

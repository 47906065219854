import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const createMenuItemService = (form) => {
  return axios.post("/api/graphql", {
    query: `
            mutation{createMenuItem
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
        ?.replaceAll('":', ":")
        ?.replaceAll(',"', ",")
        ?.replaceAll('{"', "{")
        .slice(1)
        .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
  }, {
    headers: {

    }
  });

}

export const updateMenuItemService = (form) => {
  return axios.post("/api/graphql", {
    query: `
            mutation{updateMenuItem
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
        ?.replaceAll('":', ":")
        ?.replaceAll(',"', ",")
        ?.replaceAll('{"', "{")
        .slice(1)
        .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
  }, {
    headers: {

    }
  });

}

export const updateBulkPriceService = (data, menuItems) => {
  console.log(data);
  console.log(JSON.stringify(data)
  ?.replaceAll('":', ":")
  ?.replaceAll(',"', ",")
  ?.replaceAll('{"', "{")
  .slice(1)
  .slice(0, -1));
  console.log(data && Object?.values(data)?.length > 0)
  console.log(menuItems && menuItems?.length > 0)

  return axios.post("/api/graphql", {
    query: `
            mutation{updateBulkPrice${(data && Object?.values(data)?.length > 0) && (menuItems && menuItems?.length > 0) ? `(
              ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
              ?.replaceAll('":', ":")
              ?.replaceAll(',"', ",")
              ?.replaceAll('{"', "{")
              .slice(1)
              .slice(0, -1)}` : ""}

            ${menuItems && menuItems?.length > 0 ? `
            menuItems: ${menuItems && menuItems?.length > 0 ? `[${JSON.stringify(menuItems)
              ?.replaceAll('{"', "{")
              ?.replaceAll('":', ":")
              ?.replaceAll(/[/]/g, ",")
              .slice(1)
              }` : []}` : ""}
            )` : ""}{
                id
              }}
            `,
  }, {
    headers: {

    }
  });

}

export const deleteMenuItemService = (form) => {
  return axios.post("/api/graphql", {
    query: `
            mutation{deleteMenuItem
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
        ?.replaceAll("{", "")
        ?.replaceAll("}", "")
        ?.replaceAll('":', ":")
        ?.replaceAll(',"', ",")
        .slice(1)})` : ""}
              {
                id
              }}
            `,
  }, {
    headers: {

    }
  });

}
import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadTablesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{tables${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                    ?.replaceAll("{", "")
                    ?.replaceAll("}", "")
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    .slice(1)}
            )`: ""}{
                id
                name
                place { id name }
                addedBy { id fullname }
                createdAt
            }}
            `,
    }, {
        headers: {

        }
    });

}
export const loadTabsService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{areas${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                    ?.replaceAll("{", "")
                    ?.replaceAll("}", "")
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    .slice(1)}
            )`: ""}{
                id
                name
                tables { id name }
                createdAt
            }}
            `,
    }, {
        headers: {

        }
    });

}

export const createTableService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createTable
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {

        }
    });

}

export const createMultiTablesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{createMultiTables
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {

        }
    });

}

export const updateTableService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{updateTable
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {

        }
    });

}

export const deleteTableService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteTable
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)})` : ""}
              {
                id
              }}
            `,
    }, {
        headers: {

        }
    });

}

export const deleteTablesService = (tables) => {
    var tables = tables.tables;
    return axios.post("/api/graphql", {
        query: `
            mutation{deleteTables${(tables && tables?.length > 0) ? `(
                ${tables && tables?.length > 0 ? `
              tables: ${tables && tables?.length > 0 ? `[${JSON.stringify(tables)
                        ?.replaceAll('{"', "{")
                        ?.replaceAll('":', ":")
                        ?.replaceAll(/[/]/g, ",")
                        .slice(1)
                        }` : []}` : ""}
              )` : ""}{
                id
              }}
            `,
    }, {
        headers: {

        }
    });

}
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import logosm from "../../assets/images/logo-sm.png";

import { CardBody, Card, Alert, Container, Form, FormFeedback, Label } from "reactstrap";
import { Row, Col, Button, Input } from "antd";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

//Import config
import { facebook, google } from "../../config";

import { createSelector } from 'reselect';
import AuthenticationHeader from "./AuthenticationHeader";

const Login = props => {
  document.title = "Giriş Yap | Sipyo";

  const dispatch = useDispatch();
  var [height, setHeight] = React.useState(window.innerHeight);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen E-Postanızı giriniz"),
      password: Yup.string().required("Lütfen Şifrenizi giriniz"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  const loginpage = createSelector(
    (state) => state.login,
    (state) => ({
      error: state.error,
    })
  );
  // Inside your component
  const { error } = useSelector(loginpage);

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    document.body.className = "bg-e9ecef";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <AuthenticationHeader />

      <div style={{ height: height - 100 }}>
        <Row style={{ height: height - 100 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bg-white d-flex justify-content-center align-items-center" style={{ zIndex: "10" }}>
            <Container className="text-center">
              <h1 className="fw-bold fs-2">Tekrar Hoş Geldiniz!</h1>
              <h1 className="fw-medium fs-5 my-2" style={{ color: "#6D6D6D" }}>Hesabın yok mu? <span style={{ color: "#c72328" }}><Link style={{ color: "#c72328" }} to="/register">Şimdi Kaydolun!</Link></span></h1>
              <div className="p-4">
                <div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                    <Row>
                      <Col xs={24} sm={24} md={24}>
                        <div className="mb-4">
                          <Input
                            style={{ height: "50px" }}
                            name="email"
                            className=""
                            placeholder="E-Posta"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <Input.Password
                            style={{ height: "50px" }}
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Şifre"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid"><div> {validation.errors.password} </div></FormFeedback>
                          ) : null}
                        </div>
                        <div className="text-end">
                          <Link to="/forgot-password" className="text-muted fw-medium">Şifremi Unuttum</Link>
                        </div>
                        <div className="d-grid mt-4">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            style={{ fontWeight: "550" }}
                          >
                            Giriş Yap
                          </Button>
                        </div>
                        <div className="mt-4 text-center">
                        </div>

                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

import React from 'react';
import { Form, Input, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Purchase = () => {
    let place = JSON.parse(localStorage.getItem('authUser'))?.place;
    var location = useLocation();
    var [context, setContext] = React.useState();

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const digitalMenu = queryParams.get('digitalMenu');
        const digitalMenuPeriod = queryParams.get('digitalMenuPeriod');
        const orderSystem = queryParams.get('orderSystem');
        const orderSystemPeriod = queryParams.get('orderSystemPeriod');

        if (!digitalMenu && !digitalMenuPeriod && !orderSystem && !orderSystemPeriod) {
            window.location.href = "/purchase/failed";
        }

        if (place?.orderSystemPeriod == "yearly" && orderSystemPeriod == "monthly") {
            window.location.href = "/purchase/failed";
        }

        if (place?.digitalMenuPeriod == "yearly" && digitalMenuPeriod == "monthly") {
            window.location.href = "/purchase/failed";
        }

        console.log("digital" ,digitalMenu, digitalMenuPeriod);
        console.log("order" ,orderSystem, orderSystemPeriod);

        var authUser = JSON.parse(localStorage.getItem("authUser"));
        var token;
        if (authUser) {
            authUser = JSON.parse(localStorage.getItem("authUser"));
            token = authUser.token;
        } else {
            toast.error("Lütfen tekrar giriş yapınız!");

            setTimeout(() => {
                window.location.href = "/login";
            }, 2000);
        }

        fetch(`${process.env.REACT_APP_LIVE_URL}/api/payment/purchase?${
            (digitalMenu ? `digitalMenu=${digitalMenu}&` : '') +
            (digitalMenuPeriod ? `digitalMenuPeriod=${digitalMenuPeriod}&` : '') +
            (orderSystem ? `orderSystem=${orderSystem}&` : '') +
            (orderSystemPeriod ? `orderSystemPeriod=${orderSystemPeriod}&` : '')
            }`, { headers: {
                authorization: `Bearer ${token}`
            } })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setContext(data);
            });
    }, []);

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                {context ? <>
                    <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
                    <iframe src={`https://www.paytr.com/odeme/guvenli/${context.iframetoken}`} id="paytriframe" frameborder="0"
                        style={{ width: "100%", height: "100vh" }}></iframe>
                    <script>iFrameResize({ }, '#paytriframe');</script>
                </> : <div>Loading...</div>}
            </div>
        </React.Fragment>
    );
};
export default Purchase;
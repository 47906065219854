import React, { useRef, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Divider, Input, Row, Switch, Tooltip } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretUpOutlined, CopyFilled, CopyOutlined, DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PercentageOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, QuestionCircleFilled, QuestionCircleOutlined, SaveFilled, SettingOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button as ReactStrapButton, Collapse } from "reactstrap";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { loadPlaceSubcsriptionDetailsService, loadPlacesService, updatePlaceService } from "../../services/places";
import dayjs from 'dayjs'
import { loadPurchasesService } from "../../services/purchase";
import DataTable from "react-data-table-component";

const Subscription = () => {
    document.title = "Abonelik Bilgileri | Sipyo";

    const [purchases, setPurchases] = useState([]);
    const [place, setPlace] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        loadPlace();
        loadPurchases();

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const loadPlace = async () => {
        let place = localStorage.getItem("place");
        await loadPlaceSubcsriptionDetailsService({ id: place })
            .then((data) => {
                data = data.data.data.places;
                setPlace({
                    ...data[0],
                    openingTime: dayjs(data[0].openingTime, "HH:mm"),
                    closingTime: dayjs(data[0].closingTime, "HH:mm")
                });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const loadPurchases = async () => {
        await loadPurchasesService()
            .then((data) => {
                data = data.data.data.purchases;
                setPurchases(data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    display: width <= 575 ? "flex" : "",
                    flexDirection: width <= 575 ? "row" : "",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#3a3a3a",
                    height: width <= 575 ? "175px" : width <= 580 ? "90px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} align="middle" style={{ height: "90px" }} className="w-100">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ height: width <= 575 ? "" : "55px" }}>
                            <div style={{ display: "flex", flexDirection: width <= 575 ? "column" : "row", float: width <= 575 ? "" : "right" }}>
                                <Button
                                    onClick={() => {
                                        var placeData = {
                                            placeId: place.id,
                                            name: place.name,
                                            openingTime: dayjs(place.openingTime).format("HH:mm"),
                                            closingTime: dayjs(place.closingTime).format("HH:mm"),
                                            country: place.country,
                                            city: place.city,
                                            district: place.district,
                                            neighborhood: place.neighborhood,
                                            street: place.street,
                                            address: place.address,
                                            cash: place.cash,
                                            creditCard: place.creditCard,
                                            setCard: place.setCard,
                                            metropol: place.metropol,
                                        }

                                        /*updatePlaceService(placeData)
                                            .then((res) => {
                                                let newAuth = localStorage.getItem("authUser");
                                                newAuth = JSON.parse(newAuth);
                                                toast.success("Ayarlar başarıyla güncellendi.");
                                                newAuth.place = { ...newAuth.place };
                                                localStorage.setItem("authUser", JSON.stringify(newAuth));
                                            })
                                            .catch((err) => {
                                                toast.error("Ayarlar güncellenirken bir hata oluştu.");
                                            });*/

                                    }}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                    size="large"
                                    icon={<SaveFilled style={{ fontSize: "27px" }} />}
                                    className=" d-flex justify-content-center align-items-center "
                                >
                                    Kaydet
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row style={{ /*display: "grid", gridTemplateColumns: "40% 60%"*/ background: "#f8f4f4", boxShadow: "0px 0px 2px rgb(0 0 0 / 13%)", marginLeft: "0px", marginRight: "0px", minHeight: "100vh" }} gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                        <div className=" p-3 w-100 h-100 ">
                            <h1 className="sipyo-title mt-2 mb-4">Abonelik Bilgileri</h1>

                            {place?.trial && <div className="rounded my-3 sipyo-border">
                                <div className=" rounded rounded-bottom-0 " style={{ backgroundColor: "#F8E4E5" }}>
                                    <div className=" p-3 d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 text-2 ">Deneme Sürümü</h1>
                                        <span className=" paragraph-2 fw-semibold text-end " style={{ fontSize: "14px" }}>
                                            <span>Üyelik Bitiş Tarihi</span>
                                            <span className="ms-2 " style={{ color: "#C72328" }}>
                                                {place?.expireOrderSystem ? new Date(parseInt(place?.expireOrderSystem)).toLocaleDateString() : "Mevcut Değil"}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className=" w-100 py-4 bg-white rounded-bottom d-flex justify-content-center ">
                                    <Button size="large" style={{ width: "90%" }} type="primary" onClick={() => {
                                        window.location.href = "/plans";
                                    }}>
                                        Süreyi Uzat
                                    </Button>
                                </div>
                            </div>}

                            {!place?.trial && place?.expireOrderSystem && <div className="rounded my-3 sipyo-border">
                                <div className=" rounded rounded-bottom-0 " style={{ backgroundColor: "#F8E4E5" }}>
                                    <div className=" p-3 d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 text-2 ">Adisyon Sipariş Sistemi</h1>
                                        <span className=" paragraph-2 fw-semibold text-end " style={{ fontSize: "14px" }}>
                                            <span>Üyelik Bitiş Tarihi</span>
                                            <span className="ms-2 " style={{ color: "#C72328" }}>
                                                {place?.expireOrderSystem ? new Date(parseInt(place?.expireOrderSystem)).toLocaleDateString() : "Mevcut Değil"}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className=" w-100 py-4 bg-white rounded-bottom d-flex justify-content-center ">
                                    <Row gutter={[6,12]} className="p-1">
                                        <Col xs={24} sm={24} md={12}>
                                            <Button className="w-100" size="large" style={{ width: "90%" }} type="primary" onClick={() => {
                                                if (place?.expireOrderSystem) {
                                                    window.location.href =
                                                        `/purchase?` +
                                                        (place?.digitalMenuPeriod ? `digitalMenu=true&digitalMenuPeriod=${place?.digitalMenuPeriod}&` : '') +
                                                        (place?.orderSystemPeriod ? `orderSystem=true&orderSystemPeriod=${place?.orderSystemPeriod}&` : '');
                                                } else {
                                                    window.location.href = "/plans";
                                                }
                                            }}>
                                                <span className="px-2">{place?.trial && !place?.expireOrderSystem ? "Satın Al" : "Süreyi Uzat"}</span>
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            {
                                                !place?.trial && place?.expireOrderSystem && <Button className="w-100" size="large" type="primary" onClick={() => window.location = "/plans"}>
                                                    <span className="px-2">Paketi Yükselt</span>
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>}

                            {!place?.trial && place?.expireDigitalMenu && <div className="rounded my-3 sipyo-border">
                                <div className=" rounded rounded-bottom-0 " style={{ backgroundColor: "#F8E4E5" }}>
                                    <div className=" p-3 d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 text-2 ">Dijital Menü Entegrasyonu</h1>
                                        <span className=" paragraph-2 fw-semibold text-end " style={{ fontSize: "14px" }}>
                                            <span>Üyelik Bitiş Tarihi</span>
                                            <span className="ms-2 " style={{ color: "#C72328" }}>
                                                {place?.expireDigitalMenu ? new Date(parseInt(place?.expireDigitalMenu)).toLocaleDateString() : "Mevcut Değil"}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className=" w-100 py-4 bg-white rounded-bottom d-flex justify-content-center ">
                                    <Row gutter={[6,12]} className="p-1">
                                        <Col xs={24} sm={24} md={12}>
                                            <Button className="w-100" size="large" style={{ width: "90%" }} type="primary" onClick={() => {
                                                if (place?.expireDigitalMenu) {
                                                    window.location.href =
                                                        `/purchase?` +
                                                        (place?.digitalMenuPeriod ? `digitalMenu=true&digitalMenuPeriod=${place?.digitalMenuPeriod}&` : '') +
                                                        (place?.orderSystemPeriod ? `orderSystem=true&orderSystemPeriod=${place?.orderSystemPeriod}&` : '');
                                                } else {
                                                    window.location.href = "/plans";
                                                }
                                            }}>
                                                <span className="px-2">{place?.trial && !place?.expireDigitalMenu ? "Satın Al" : "Süreyi Uzat"}</span>
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            {
                                                !place?.trial && place?.expireDigitalMenu && <Button className="w-100" size="large" type="primary" onClick={() => window.location = "/plans"}>
                                                    <span className="px-2">Paketi Yükselt</span>
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                        <Col className=" p-3 w-100 h-100 sipyo-border-left ">
                            <div className=" mt-2 mb-4 d-flex flex-row align-items-center text-center  ">
                                <h1 className="me-2 sipyo-title">Ödeme Geçmişi</h1>
                            </div>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="rounded">
                                    <DataTable
                                        className="p-0"
                                        columns={[
                                            {
                                                name: <span className="fs-5">Tarih/Fatura</span>,
                                                selector: (row) => <span className=" paragraph-2 "><div className=" d-flex flex-row justify-content-between align-items-center ">
                                                    <Button onClick={() => {
                                                        navigator.clipboard.writeText(row?.merchant_oid);

                                                        toast.success("Fatura kimliği kopyalandı");
                                                    }} type="primary" icon={<CopyFilled />} size="small" className="me-2" />
                                                    <span className=" paragraph-2 ">{row.createdAt && (new Date(parseInt(row.createdAt)).toLocaleDateString())}</span>
                                                </div></span>,
                                                minWidth: "150px",
                                                wrap: true,
                                                sortFunction: (a, b) => a.createdAt - b.createdAt,
                                            },
                                            {
                                                name: <span className="fs-5">Durum</span>,
                                                selector: (row) => <span className=" paragraph-2 "><div className=" d-flex justify-content-center align-items-center text-center px-3 py-2 fw-bold rounded" style={{ color: "#C72328", backgroundColor: "#F8E4E5" }}><p className="pv-archiveText m-0">{row.callbacked ? "Ödendi" : "Ödenmedi"}</p></div></span>,
                                                sortable: true,
                                                minWidth: "125px",
                                                wrap: true,
                                                sortField: "callbacked",
                                                sortFunction: (a, b) => a.callbacked - b.callbacked,
                                            },
                                            {
                                                name: <span className="fs-5">Tutar</span>,
                                                selector: (row) => <span className=" paragraph-2 "><span className=" paragraph-2  ">{(row?.payment_amount / 100)} TL</span></span>,
                                                sortable: true,
                                                minWidth: "100px",
                                                wrap: true,
                                                sortField: "payment_amount",
                                                sortFunction: (a, b) => a.payment_amount - b.payment_amount,
                                            },
                                            {
                                                name: <span className="fs-5">Açıklama</span>,
                                                selector: (row) => <div className=" d-flex flex-column fw-semibold ">
                                                    <span className="paragraph-2 text-nowrap" style={{ fontWeight: "500" }}>{row?.digitalMenu && "Dijital Menü"}</span>
                                                    <span className="paragraph-2 text-nowrap" style={{ fontWeight: "500" }}>{row?.orderSystem && "Adisyon Sistemi"}</span>
                                                </div>,
                                                sortable: true,
                                                grow: 2,
                                                wrap: true,
                                            },
                                        ]}
                                        data={purchases}
                                    />
                                </Col>
                            </Row>

                        </Col>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Subscription;
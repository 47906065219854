import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { toast } from "react-toastify";
import { deletePlaceService } from "../../../services/places";
import { confirm } from "../../../components/confirm";

var formatInt = (value) => {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",") + ".00";
}

export const CategoryColumns = () => {
    return [
        {
            name: <span className="fs-5">Kategori</span>,
            selector: (row) => row.bold ? <b>{row.name}</b> : <span className=" paragraph-2 ">{row.name}</span>,
            sortable: true,
            sortFunction: (a, b) => a.name - b.name,
        },
        {
            name: <span className="fs-5">Miktar</span>,
            selector: (row) => row.bold ? <b>{row.totalOrder != null && row.totalOrder != undefined ? formatInt(row.totalOrder) : 0}</b> : <span className=" paragraph-2 ">{row.totalOrder != null && row.totalOrder != undefined ? formatInt(row.totalOrder) : 0}</span>,
            sortable: true,
            sortFunction: (a, b) => a.totalOrder - b.totalOrder,
        },
        {
            name: <span className="fs-5">Tutar (<span className="fw-light">₺</span>)</span>,
            selector: (row) => row.bold ? <b>{row.totalPayment != null && row.totalPayment != undefined ? formatInt(row.totalPayment) : 0}</b> : <span className=" paragraph-2 ">{row.totalPayment != null && row.totalPayment != undefined ? formatInt(row.totalPayment) : 0}</span>,
            sortable: true,
            sortFunction: (a, b) => a.totalPayment - b.totalPayment,
        },
        {
            name: <span className="fs-5">Oran (%)</span>,
            selector: (row) => row.bold ? <b>{row?.percentage + "%" || "Bulunamadı"}</b> : <span className=" paragraph-2 ">{row?.percentage + "%" || "Bulunamadı"}</span>,
            sortable: true,
            sortFunction: (a, b) => a.percentage - b.percentage,
        },
    ];
};
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row } from 'antd';

const Plans = () => {
    let place = JSON.parse(localStorage.getItem('authUser'))?.place;
    var [orderPlans, setOrderPlans] = useState([
        {
            id: 1,
            name: "Aylık",
            realPrice: 390.00,
            price: 390.00,
            discountedPrice: 550.00,
            orderSystem: true,
            digitalMenu: false,
            selected: false,
            type: "monthly"
        },
        {
            id: 2,
            name: "Yıllık",
            realPrice: 323.00,
            price: 323.00,
            discountedPrice: 458.00,
            orderSystem: true,
            digitalMenu: false,
            discount: 795,
            selected: false,
            type: "yearly",
        },
    ]);

    var [digitalMenuPlans, setDigitalMenuPlans] = useState([
        {
            id: 3,
            name: "Aylık",
            realPrice: 250,
            price: 250,
            orderSystem: false,
            digitalMenu: true,
            selected: false,
            type: "monthly",
            disable: place?.digitalMenuPeriod == "yearly"
        },
        {
            id: 4,
            name: "Yıllık",
            realPrice: 208,
            price: 208,
            discount: 504,
            orderSystem: false,
            digitalMenu: true,
            selected: false,
            type: "yearly",
        },
    ]);

    useEffect(() => {
        orderPlans.map((plan) => {
            if (plan.type == "yearly" && plan.selected) {
                let newPlans = [...digitalMenuPlans];
                newPlans.map((plan_) => {
                    if (plan_.selected && plan_.type == "yearly") {
                        plan_.price = 0;
                    }
                })

                setDigitalMenuPlans(newPlans);
            } else {
                let newPlans = [...digitalMenuPlans];
                newPlans.map((plan_) => {
                    if (plan_.selected && plan_.type == "yearly") {
                        plan_.price = 208;
                    }
                })

                setDigitalMenuPlans(newPlans);
            }
        });
    }, [orderPlans, digitalMenuPlans])

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"} h-100`} style={{ borderRadius: "7px" }}>
                <Row className='bg-white h-100 p-3 rounded'>
                    <Col xs={24} sm={24} md={16} lg={14}>
                        <h2 className=' sipyo-title '>İşletmenize En Uygun Paketi Seçin</h2>

                        <div className="mt-4">
                            <div className=" rounded p-3 sipyo-border ">
                                <div className=' d-flex flex-row py-3 pt-0 '>
                                    <h1 className=' sipyo-alttitle '>Adisyon Sipariş Sistemi</h1>
                                    <span className=" text-center d-flex align-items-center justify-content-center ms-2 rounded px-2 fw-semibold" style={{ backgroundColor: "rgba(199, 35, 40, 0.2)", color: "#C72328" }} >%29 İndirim</span>
                                </div>

                                <div className=' d-flex w-100 overflow-x-auto '>
                                    {orderPlans.filter((plt) => !plt.digitalMenu).map((plan, planIndex) => {
                                        let disable = place?.orderSystemPeriod == "yearly" && plan.type == "monthly";
                                        return (
                                            <div className={` user-select-none d-flex flex-row justify-content-between rounded ${disable ? "opacity-50" : ""} ${planIndex > 0 && "ms-2"}`} style={{ border: plan.selected ? "0.1px solid #C72328" : "0.1px solid #d9d9d9", minWidth: "300px", backgroundColor: plan.selected && "rgba(199, 35, 40, 0.2)", minHeight: "130px", maxHeight: "130px", cursor: disable ? "not-allowed" : "pointer" }} onClick={() => {
                                                if (disable) return;
                                                let newPlans = [...orderPlans];

                                                if (plan.selected) {
                                                    console.log("already selected")
                                                    newPlans.map((plan_) => {
                                                        if (plan.id == plan_.id) {
                                                            plan_.selected = false;
                                                        }
                                                    })

                                                    setOrderPlans(newPlans);
                                                    return;
                                                }

                                                orderPlans.map((plan) => plan.selected = false);
                                                newPlans[planIndex].selected = !newPlans[planIndex].selected;
                                                setOrderPlans(newPlans);
                                            }}>
                                                <div className=' p-2 d-flex flex-column justify-content-between '>
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <h3 className=' ms-2 fw-medium' style={{ fontSize: "16px", color: "#585858" }}>{plan.name}</h3>

                                                        {plan.discount && <div style={{ minHeight: "21px", maxHeight: "21px" }}>
                                                            {plan.discount && <span className=" text-center d-flex align-items-center justify-content-center ms-2 rounded px-2 fw-semibold" style={{ backgroundColor: "rgba(199, 35, 40, 0.2)", color: "#C72328" }} >{plan.discount}₺ Az Ödeyin</span>}
                                                        </div>}
                                                    </div>

                                                    <div className='mt-3 d-flex flex-row align-items-center mt-1 ms-2 '>
                                                        <h1 className=' fs-2 fw-semibold '>{plan.realPrice?.toFixed(2)}₺</h1>
                                                        <h1 className=' fs-2 fw-light ' style={{ color: "#585858" }}> /ay</h1>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <div className='discounted-price' />
                                                        <h1 className='ms-2 fw-semibold ' style={{ fontSize: "17px", color: "#C72328" }}>{plan.discountedPrice?.toFixed(2)}₺</h1>
                                                    </div>

                                                    <div style={{ minHeight: "21px", maxHeight: "21px" }} />
                                                </div>

                                                <div className=' d-flex justify-content-center align-items-center p-3'>
                                                    <Checkbox disabled={disable} checked={plan.selected} style={{ transform: "scale(1.5)" }} />
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className=" rounded p-3 sipyo-border">
                                <div className=' d-flex flex-row py-3 pt-0 '>
                                    <h1 className=' sipyo-alttitle '>Dijital Menü Entegrasyonu</h1>
                                    {/*<span className=" text-center d-flex align-items-center justify-content-center ms-2 rounded px-2 fw-semibold" style={{ backgroundColor: "rgba(199, 35, 40, 0.2)", color: "#C72328" }} >%27 İndirim</span>*/}
                                </div>

                                <div className=' d-flex w-100 overflow-x-auto '>
                                    {digitalMenuPlans.filter((plt) => plt.digitalMenu).map((plan, planIndex) => {
                                        let disable = place?.digitalMenuPeriod == "yearly" && plan.type == "monthly";
                                        return (
                                            <div className={` user-select-none d-flex flex-row justify-content-between rounded ${disable ? "opacity-50" : ""} ${planIndex > 0 && "ms-2"}`} style={{ border: plan.selected ? "0.1px solid #C72328" : "0.1px solid #d9d9d9", minWidth: "300px", backgroundColor: plan.selected && "rgba(199, 35, 40, 0.2)", minHeight: "130px", maxHeight: "130px", cursor: disable ? "not-allowed" : "pointer" }} onClick={() => {
                                                if (disable) return;
                                                let newPlans = [...digitalMenuPlans];

                                                if (plan.selected) {
                                                    console.log("already selected")
                                                    newPlans.map((plan_) => {
                                                        if (plan.id == plan_.id) {
                                                            plan_.selected = false;
                                                        }
                                                    })

                                                    setDigitalMenuPlans(newPlans);
                                                    return;
                                                }

                                                digitalMenuPlans.map((plan) => plan.selected = false);
                                                newPlans.map((plan_) => {
                                                    if (plan.id == plan_.id) {
                                                        plan_.selected = true;
                                                    }
                                                })
                                                setDigitalMenuPlans(newPlans);
                                            }}>
                                                <div className=' p-2 d-flex flex-column justify-content-between '>
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <h3 className=' ms-2 fw-medium' style={{ fontSize: "16px", color: "#585858" }}>{plan.name}</h3>

                                                        {plan.discount && <div style={{ minHeight: "21px", maxHeight: "21px" }}>
                                                            {plan.discount && <span className=" text-center d-flex align-items-center justify-content-center ms-2 rounded px-2 fw-semibold" style={{ backgroundColor: "rgba(199, 35, 40, 0.2)", color: "#C72328" }} >{plan.discount}₺ Az Ödeyin</span>}
                                                        </div>}
                                                    </div>


                                                    <div>
                                                        <div className=' d-flex flex-row align-items-center mt-1 ms-2 '>
                                                            <h1 className=' fs-2 fw-semibold '>{plan.realPrice?.toFixed(2)}₺</h1>
                                                            <h1 className=' fs-2 fw-light ' style={{ color: "#585858" }}> /ay</h1>
                                                        </div>
                                                        <div className=''>
                                                            {
                                                                plan.discountedPrice && (<>
                                                                    <div className='discounted-price' />
                                                                    <h1 className='ms-2 fw-semibold ' style={{ fontSize: "17px", color: "#C72328" }}>{plan.discountedPrice?.toFixed(2)}₺</h1>
                                                                </>)
                                                            }
                                                        </div>

                                                    </div>
                                                    <div style={{ minHeight: "21px", maxHeight: "21px" }} />
                                                </div>

                                                <div className=' d-flex justify-content-center align-items-center p-3'>
                                                    <Checkbox disabled={disable} checked={plan.selected} style={{ transform: "scale(1.5)" }} />
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={10} className='p-3'>
                        <h2 className=' fs-3 fw-semibold '>Sipariş Özeti</h2>
                        <div className=' w-100 rounded mt-2 sipyo-border'>
                            <h2 className='p-3 fs-5 fw-semibold '>Sipariş Toplamı</h2>

                            {[...orderPlans, ...digitalMenuPlans].filter((plan) => plan.selected).map((plan) => (
                                <>
                                    <div className=' p-3 d-flex flex-row justify-content-between '>
                                        <div className='d-flex flex-column'>
                                            <h1 className=' fs-5 fw-medium ' style={{ color: "#c72328" }}>{plan.digitalMenu ? "Dijital Menü Entegrasyonu" : "Adisyon Sipariş Sistemi"}</h1>
                                            <div className='d-flex flex-row'>
                                                {plan.discountedPrice && <div>
                                                    <div className='discounted-price-sm' style={{ backgroundColor: "#969696" }} />
                                                    <h1 className='fs-6 fw-medium ' style={{ color: "#969696" }}>{plan.discountedPrice?.toFixed(2)}₺</h1>
                                                </div>}
                                                <span className={`${plan.discountedPrice && "ms-2"}`}>{plan.realPrice?.toFixed(2)}₺ <span style={{ color: "#5B5B5B" }}>/ ay</span></span>
                                            </div>
                                        </div>
                                        {
                                            plan.price == 0 ? <>
                                                <div>
                                                    <div className='discounted-price-md' style={{ backgroundColor: "#000000" }} />
                                                    <h1 className='fs-5 fw-medium ' style={{ color: "#000000" }}>{(
                                                        plan.realPrice * (plan.type == "monthly" ? 1 : 12)
                                                    )?.toFixed(2)}₺</h1>
                                                </div>
                                            </> : <>
                                                <h1 className=' fs-5 fw-medium '>{(plan.price * (plan.type == "monthly" ? 1 : 12))?.toFixed(2)}₺</h1>
                                            </>
                                        }
                                    </div>
                                </>
                            ))}

                            <div className='w-100 sipyo-border' />

                            <div className=' p-3 d-flex flex-row justify-content-between '>
                                <h1 className=' fs-5 fw-semibold '>Toplam</h1>
                                <h1 className=' fs-5 fw-semibold '>
                                    {(
                                        [...digitalMenuPlans, ...orderPlans].filter((plan) => plan.selected).reduce((total, plan) => total + (plan.price * (plan.type === "monthly" ? 1 : 12)), 0)
                                    ).toFixed(2)}₺
                                </h1>
                            </div>
                            <div className='w-100 d-flex justify-content-center'>
                                <Button className='my-2 px-2' style={{ width: "90%" }} type='primary' onClick={() => {
                                    let selectedPlans = [...digitalMenuPlans, ...orderPlans].filter((plan) => plan.selected);

                                    console.log(selectedPlans)
                                    var digitalMenu = false;
                                    var digitalMenuPeriod;

                                    var orderSystem = false;
                                    var orderSystemPeriod;

                                    selectedPlans.map((plan) => {
                                        if (plan.digitalMenu) {
                                            digitalMenu = true;
                                            digitalMenuPeriod = plan.type;
                                        }

                                        if (plan.orderSystem) {
                                            orderSystem = true;
                                            orderSystemPeriod = plan.type;
                                        }
                                    })

                                    window.location.href =
                                        `/purchase?` +
                                        (digitalMenu ? `digitalMenu=${digitalMenu}&` : '') +
                                        (digitalMenuPeriod ? `digitalMenuPeriod=${digitalMenuPeriod}&` : '') +
                                        (orderSystem ? `orderSystem=${orderSystem}&` : '') +
                                        (orderSystemPeriod ? `orderSystemPeriod=${orderSystemPeriod}&` : '');
                                }}>
                                    Ödemeye Geç
                                </Button>
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        </React.Fragment>
    );
};

export default Plans;
import React from 'react';
import { Result, Button } from 'antd';

const PurchaseFailed = () => {
    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Result
                    status="error"
                    title="Ödeme başarısız"
                    subTitle="Bilinmeyen nedenden dolayı ödeme işlemi gerçekleştirilemedi. Lütfen tekrar deneyin."
                />
            </div>
        </React.Fragment>
    );
};

export default PurchaseFailed;
import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
];

const DoughnutChart = ({ id, data, className, period }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        var chartExist = Chart.getChart(id); // <canvas> id
        if (chartExist != undefined) {
            chartExist.destroy();
        }
        let labels = data?.map((item) => `${item.type}`);
        var datas = data?.map((item) => item.total);

        if (id == "ordersOfTodayByMenuItem") {
            labels = data?.map((item) => `${item.name}`);
            datas = data?.map((item) => item.count);
        }


        var total = 0;
        if (datas?.length > 0) {
            total = datas?.reduce((acc, curr) => acc + curr);

        }
        const segmentSizes = datas?.map((value) =>
            ((value / total) * 100).toFixed(2)
        );

        const ctx = chartRef?.current?.getContext("2d");

        new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: labels,
                datasets: [
                    {
                        data: datas,
                        backgroundColor: [
                            "#c72328",
                            "#e0a429",
                            "#42c837",
                            "#3676c6"
                        ],
                        borderColor: "#fff",
                        borderWidth: 2,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        fontColor: "red",
                    },
                },
                title: {
                    display: false,
                },
                tooltips: {
                    enabled: true,
                },
            },
        });

    }, [data]);

    return (
        <div className={`${className}`}>
            <canvas ref={chartRef} id={id}></canvas>
        </div>
    )
}

export default DoughnutChart;
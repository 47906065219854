export function timeAgo(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);

    if (diffInSeconds < 60) {
        return { time: diffInSeconds, type: "seconds", text: `${diffInSeconds} sn Önce`, warn: false };
    } else if (diffInSeconds < 3600) {
        return { time: Math.floor(diffInSeconds / 60), type: "minutes", text: `${Math.floor(diffInSeconds / 60)} dk Önce`, warn: (Math.floor(diffInSeconds / 60)) >= 30 };
    } else if (diffInSeconds < 86400) {
        return { time: Math.floor(diffInSeconds / 3600), type: "hours", text: `${Math.floor(diffInSeconds / 3600)} saat Önce`, warn: true };
    } else {
        return { time: Math.floor(diffInSeconds / 86400), type: "days", text: `${Math.floor(diffInSeconds / 86400)} gün Önce`, warn: true };
    }
}
import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const payOrderService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{payOrder${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                    ?.replaceAll("{", "")
                    ?.replaceAll("}", "")
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    .slice(1)}
            )`: ""}{
                id
                
            }}
            `,
    }, {
        headers: {

        }
    });

}

export const deletePayOrderService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{deletePayOrder${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                    ?.replaceAll("{", "")
                    ?.replaceAll("}", "")
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    .slice(1)}
            )`: ""}{
                id
                
            }}
            `,
    }, {
        headers: {

        }
    });

}

export const createOrderService = (data, items) => {
    return axios.post("/api/graphql", {
        query: `
        mutation{createOrder${(data && Object?.values(data)?.length > 0) && (items && items?.length > 0) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}

            ${items && items?.length > 0 ? `
            items: ${items && items?.length > 0 ? `[${JSON.stringify(items)
                        ?.replaceAll('{"', "{")
                        ?.replaceAll('":', ":")
                        ?.replaceAll(',"', ",")
                        ?.replaceAll(/[/]/g, ",")
                        .slice(1)
                        }` : []}` : ""}
            )` : ""}{
              id
          }}
            `,
    }, {
        headers: {

        }
    });

}

export const updateOrderService = (data, items) => {
    return axios.post("/api/graphql", {
        query: `
        mutation{updateOrder${(data && Object?.values(data)?.length > 0) && (data.cancel != true ? (items && items?.length > 0) : true) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}

                    ${items && items?.length > 0 ? `
                    items: ${items && items?.length > 0 ? `[${JSON.stringify(items)
                        ?.replaceAll('{"', "{")
                        ?.replaceAll('":', ":")
                        ?.replaceAll(',"', ",")
                        ?.replaceAll(/[/]/g, ",")
                        .slice(1)
                        }` : []}` : ""}
                    )` : ""}{
              id
          }}
            `,
    }, {
        headers: {

        }
    });

}

export const closeOrderService = (data) => {
    return axios.post("/api/graphql", {
        query: `
        mutation{closeOrder${(data && Object?.values(data)?.length > 0) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}
                    )` : ""}{
              id
          }}
            `,
    }, {
        headers: {

        }
    });

}

export const transferOrderService = (data) => {
    return axios.post("/api/graphql", {
        query: `
        mutation{transferOrder${(data && Object?.values(data)?.length > 0) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}
                    )` : ""}{
              id
          }}
            `,
    }, {
        headers: {

        }
    });

}

export const mergeOrderService = (data) => {
    return axios.post("/api/graphql", {
        query: `
        mutation{mergeOrder${(data && Object?.values(data)?.length > 0) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}
                    )` : ""}{
              id
          }}
            `,
    }, {
        headers: {

        }
    });

}

export const getDailyOrderReportsService = (data) => {
    return axios.post("/api/graphql", {
        query: `
        query{getDailyOrderReports${(data && Object?.values(data)?.length > 0) ? `(
            ${data && Object?.values(data)?.length > 0 ? `${JSON.stringify(data)
                    ?.replaceAll('":', ":")
                    ?.replaceAll(',"', ",")
                    ?.replaceAll('{"', "{")
                    .slice(1)
                    .slice(0, -1)}` : ""}
                    )` : ""}{
              id
              createdAt
              end
              items{
                portion
                item {
                    id
                    name
                    price
                    portions {
                        title
                        price
                    }
                }
              }
              table{
                id
                name
              }
              
          }}
            `,
    }, {
        headers: {

        }
    });

}
import React, { useState } from "react";
import { Button, Checkbox, Col, DatePicker, Input, Row } from 'antd';
import { CaretDownOutlined, DownloadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, ButtonGroup, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import { AreaColumns, columns } from './columns/columns'
import dayjs from 'dayjs';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { loadBrandUsersService } from "../../services/brand-user";
import { loadReportsService } from "../../services/report";
import { getDailyOrderReportsService } from "../../services/orders";

const DailyReports = () => {
    document.title = "Günlük Raporlar | Sipyo";

    const [width, setWidth] = useState(window.innerWidth);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ period: "DAILY" });
    const [loading, setLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState();

    const loadReports = () => {
        setLoading(true);

        delete filter.unit;
        getDailyOrderReportsService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                var data = res?.data?.getDailyOrderReports;

                data.map((row) => {
                    row.total = row?.items?.reduce((total, item) => total + (item?.price != null && item?.price != undefined ? item?.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)]?.price : item?.item?.price), 0) || 0;
                });

                /*data.push({
                    id: "Toplam",
                    total: data.reduce((total, row) => total + row?.total, 0),
                    bold: true,
                });*/

                setData(data);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        loadReports(filter);

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        loadReports(filter);

    }, [filter]);

    const checkParameters = (managerParameters, object) => {
        for (var parameter of managerParameters) {
            if (!object?.hasOwnProperty(parameter.value)) {
                toast.error(`Lütfen ${parameter.label} alanını doldurunuz!`);

                return false;
            }
        }

        return true;
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    background: "#3a3a3a",
                    height: width < 580 ? "133px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: width < 580 ? "133px" : "75px" }} className={`${width < 580 ? "py-2" : ""}`}>
                        {
                            width < 580 ? <>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className={`${width <= 991 && "justify-content-center"} w-100 d-flex flex-row `}>
                                        <Button size="large" type={"primary"} className=" mx-1 text-white ">Tüm Adisyonlar</Button>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <DatePicker
                                        className={`me-4 text-center w-100`}
                                        picker={filter.period == "YEARLY" ? "year" : filter.period == "MONTHLY" ? "month" : filter.period == "WEEKLY" ? "week" : "date"}
                                        onChange={(date, dateString) => {
                                            if (filter.period == "YEARLY") {
                                                console.log(date.year);
                                                setFilter({ ...filter, year: date.year() })
                                            } else if (filter.period == "MONTHLY") {
                                                console.log(date.year())
                                                console.log(date.month())

                                                setFilter({ ...filter, year: date.year(), month: date.month() })
                                            } else {
                                                setFilter({ ...filter, year: date.year(), month: date.month(), day: date.date() })
                                            }
                                        }}
                                        style={{ minWidth: "175px" }}
                                        size="large"
                                        placeholder={`${filter.period == "YEARLY" ? "Yıl" : filter.period == "MONTHLY" ? "Ay" : filter.period == "WEEKLY" ? "Hafta" : "Gün"} Seçiniz`}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className={` ${width <= 991 ? "justify-content-center" : "justify-content-end"} w-100 d-flex align-items-center `} style={{ float: "right" }}>
                                        <ButtonGroup className="me-2">
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "DAILY" })} type={filter.period == "DAILY" && "primary"}>
                                                Günlük
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "WEEKLY" })} type={filter.period == "WEEKLY" && "primary"}>
                                                Haftalık
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "MONTHLY" })} type={filter.period == "MONTHLY" && "primary"}>
                                                Aylık
                                            </Button>
                                        </ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                // ! Data to xlsx and download
                                                var resultData = [...data];
                                                resultData = resultData.map((row) => {
                                                    return {
                                                        "Adisyon Numarası": row.id,
                                                        "Açılış Tarihi": dayjs(new Date(parseInt(row?.createdAt))).format("DD.MM.YYYY") || "-",
                                                        "Kapanış Tarihi (₺)": dayjs(new Date(parseInt(row?.end))).format("DD.MM.YYYY") || "-",
                                                        "Satılan Ürün Miktarı": row.items?.length || 0,
                                                        "Masa Adı": row?.table?.name || "-",
                                                        "Durum": row?.end ? "Kapandı" : "Açık",
                                                        "Tutar (₺)": row?.total,
                                                    }
                                                })

                                                console.log(resultData);
                                                var xlsx = require('xlsx');
                                                var wb = xlsx.utils.book_new();
                                                var ws = xlsx.utils.json_to_sheet(resultData);
                                                xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
                                                // Calculate Date by filter
                                                var date = new Date();
                                                if (filter.period == "YEARLY") {
                                                    date = new Date(filter.year, 0, 1);
                                                } else if (filter.period == "MONTHLY") {
                                                    date = new Date(filter.year, filter.month, 1);
                                                } else {
                                                    date = new Date(filter.year, filter.month, filter.day);
                                                }

                                                xlsx.writeFile(wb, `${dayjs(date).format('DD.MM.YYYY')} Raporu.xlsx`);
                                            }}
                                            icon={<DownloadOutlined style={{ fontSize: "22px" }} />}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                            size="large"
                                        >
                                            İndir
                                        </Button>

                                    </div>
                                </Col>
                            </> : <>
                                <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={16} >
                                    <div className={`${width <= 991 && "justify-content-center"} w-100 d-flex flex-row `}>
                                        <Button size="large" type={"primary"} className=" mx-1 text-white ">Tüm Adisyonlar</Button>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={12} xxl={8} >
                                    <div className={` ${width <= 991 ? "justify-content-center" : "justify-content-end"} w-100 d-flex align-items-center `} style={{ float: "right" }}>
                                        <DatePicker
                                            className={`me-4 text-center`}
                                            picker={filter.period == "YEARLY" ? "year" : filter.period == "MONTHLY" ? "month" : filter.period == "WEEKLY" ? "week" : "date"}
                                            onChange={(date, dateString) => {
                                                if (filter.period == "YEARLY") {
                                                    console.log(date.year);
                                                    setFilter({ ...filter, year: date.year() })
                                                } else if (filter.period == "MONTHLY") {
                                                    console.log(date.year())
                                                    console.log(date.month())

                                                    setFilter({ ...filter, year: date.year(), month: date.month() })
                                                } else {
                                                    setFilter({ ...filter, year: date.year(), month: date.month(), day: date.date() })
                                                }
                                            }}
                                            style={{ minWidth: "175px" }}
                                            size="large"
                                            placeholder={`${filter.period == "YEARLY" ? "Yıl" : filter.period == "MONTHLY" ? "Ay" : filter.period == "WEEKLY" ? "Hafta" : "Gün"} Seçiniz`}
                                        />
                                        <ButtonGroup className="me-2">
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "DAILY" })} type={filter.period == "DAILY" && "primary"}>
                                                Günlük
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "WEEKLY" })} type={filter.period == "WEEKLY" && "primary"}>
                                                Haftalık
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "MONTHLY" })} type={filter.period == "MONTHLY" && "primary"}>
                                                Aylık
                                            </Button>
                                        </ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                // ! Data to xlsx and download
                                                var resultData = [...data];
                                                resultData = resultData.map((row) => {
                                                    return {
                                                        "Adisyon Numarası": row.id,
                                                        "Açılış Tarihi": dayjs(new Date(parseInt(row?.createdAt))).format("DD.MM.YYYY") || "-",
                                                        "Kapanış Tarihi (₺)": dayjs(new Date(parseInt(row?.end))).format("DD.MM.YYYY") || "-",
                                                        "Satılan Ürün Miktarı": row.items?.length || 0,
                                                        "Masa Adı": row?.table?.name || "-",
                                                        "Durum": row?.end ? "Kapandı" : "Açık",
                                                        "Tutar (₺)": row?.total,
                                                    }
                                                })

                                                console.log(resultData);
                                                var xlsx = require('xlsx');
                                                var wb = xlsx.utils.book_new();
                                                var ws = xlsx.utils.json_to_sheet(resultData);
                                                xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
                                                // Calculate Date by filter
                                                var date = new Date();
                                                if (filter.period == "YEARLY") {
                                                    date = new Date(filter.year, 0, 1);
                                                } else if (filter.period == "MONTHLY") {
                                                    date = new Date(filter.year, filter.month, 1);
                                                } else {
                                                    date = new Date(filter.year, filter.month, filter.day);
                                                }

                                                xlsx.writeFile(wb, `${dayjs(date).format('DD.MM.YYYY')} Raporu.xlsx`);
                                            }}
                                            icon={<DownloadOutlined style={{ fontSize: "22px" }} />}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                            size="large"
                                        >
                                            İndir
                                        </Button>

                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </Container>

                {
                    loading ? <>
                        <div className=" w-100 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <LoadingOutlined style={{ color: "#c72328", fontSize: "42px" }} />
                        </div>
                    </> :
                        <>
                            <DataTable
                                paginationComponentOptions={{ noRowsPerPage: false }}
                                noHeader
                                //pagination
                                columns={!loading ? columns(setOrderDetails) : []}
                                paginationPerPage={10}
                                className="react-dataTable rounded-none"
                                sortIcon={<CaretDownOutlined />}
                                //paginationDefaultPage={currentPage}
                                //paginationComponent={DatatableCustomPagination}
                                data={data}
                                noDataComponent={<div></div>}
                            />
                            {/*<div style={{ width: "100%" }} id="row-Toplam" role="row" class="sipyo-border-top sc-jsEeTM kUMlDB rdt_TableRow overflow-x-auto overflow-y-auto"><div id="cell-1-Toplam" data-column-id="1" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV ipobkp erkbiK rdt_TableCell" data-tag="allowRowEvents" minwidth="250px"><div data-tag="allowRowEvents"><b>Toplam</b></div></div><div id="cell-2-Toplam" data-column-id="2" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"></div></div><div id="cell-3-Toplam" data-column-id="3" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"></div></div><div id="cell-4-Toplam" data-column-id="4" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"><b></b></div></div><div id="cell-5-Toplam" data-column-id="5" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"><b></b></div></div><div id="cell-6-Toplam" data-column-id="6" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"></div></div><div id="cell-7-Toplam" data-column-id="7" role="cell" class="sc-hLQSwg sc-eDLKkx sc-jTQCzO bdUBmV faZveH erkbiK rdt_TableCell" data-tag="allowRowEvents"><div data-tag="allowRowEvents"><b>{data.reduce((total, row) => total + row?.total, 0)}₺</b></div></div></div>*/}

                            <div style={{ width: "100%" }} id="row-Toplam" role="row" class="sc-jsEeTM itluUR rdt_TableRow">
                                <div id="cell-1-Toplam" data-column-id="1" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf fewBcI cJTPDY rdt_TableCell" data-tag="allowRowEvents"
                                    minwidth="250px">
                                    <div data-tag="allowRowEvents">
                                        <b>
                                            Toplam
                                        </b>
                                    </div>
                                </div>
                                <div id="cell-2-Toplam" data-column-id="2" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">
                                            
                                        </span>
                                    </div>
                                </div>
                                <div id="cell-3-Toplam" data-column-id="3" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">

                                        </span>
                                    </div>
                                </div>
                                <div id="cell-4-Toplam" data-column-id="4" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">
                                        </span>
                                    </div>
                                </div>
                                <div id="cell-5-Toplam" data-column-id="5" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">
                                        </span>
                                    </div>
                                </div>
                                <div id="cell-6-Toplam" data-column-id="6" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">
                                        </span>
                                    </div>
                                </div>
                                <div id="cell-7-Toplam" data-column-id="7" role="cell"
                                    class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                    <div data-tag="allowRowEvents">
                                        <span class=" paragraph-2 ">
                                            {data.reduce((total, row) => total + row?.total, 0)}₺
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </>
                }
            </div>

            <Modal
                isOpen={orderDetails}
                toggle={() => setOrderDetails(false)}
                centered
            >
                <ModalHeader toggle={() => setOrderDetails(false)}>
                    Adisyon Numarası: <span className="text-primary">{orderDetails?.id}</span>
                </ModalHeader>
                <ModalBody className="p-0 m-0">
                    <Row gutter={0}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="p-0 m-0 sipyo-border" style={{ borderLeft: "0px" }}>
                            <div className="order-detail-item sipyo-border-top sipyo-border-bottom" style={{ fontSize: "16px", fontWeight: "600" }}>
                                <span>Sipariş Bilgileri</span>
                                <span />
                            </div>
                            <div className="order-detail-item sipyo-border-top sipyo-border-bottom">
                                <span>Sipariş Tarihi</span>
                                <span>{orderDetails?.createdAt}</span>
                            </div>
                            <div className="order-detail-item sipyo-border-top sipyo-border-bottom">
                                <span>Sipariş Türü</span>
                                <span>Masa</span>
                            </div>
                            <div className="order-detail-item sipyo-border-top sipyo-border-bottom">
                                <span>Sipariş Notu</span>
                                <span></span>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="p-0 m-0 sipyo-border" style={{ borderRight: "0px" }}>
                            <div className="order-detail-item sipyo-border-top sipyo-border-bottom" style={{ fontSize: "16px", fontWeight: "600" }}>
                                <span>Siparişler</span>
                                <span />
                            </div>
                            {
                                orderDetails?.items?.map((item, index) => (
                                    <div className="order-detail-item sipyo-border-top sipyo-border-bottom" key={index}>
                                        <div className="d-flex flex-row">
                                            <span>{item?.item?.name}</span>
                                            {item.portion && <div className='ms-2 px-1 rounded-1 d-flex justify-content-start align-items-center' style={{ background: "#c72328", marginTop: "4px", marginBottom: "4px" }}>
                                                <h4 className='fw-medium text-white' style={{ fontSize: "12px", margin: "1px" }}>{item.portion}</h4>
                                            </div>}
                                        </div>
                                        <span>₺{(item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions[item?.item?.portions?.findIndex((plt) => plt.title == item.portion)].price : item.item.price)?.toFixed(2)}</span>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default DailyReports;
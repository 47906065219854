import React, { useRef, useState } from "react";
import { Button, Checkbox, Col, Divider, Input, Row, Switch, Tooltip } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretUpOutlined, CopyFilled, CopyOutlined, DeleteFilled, DeleteOutlined, MinusCircleOutlined, MoreOutlined, PercentageOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, QuestionCircleFilled, QuestionCircleOutlined, SettingFilled, SettingOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button as ReactStrapButton, Collapse } from "reactstrap";
import DataTable from "react-data-table-component";
import { PlaceColumns } from './columns'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { createPlaceService, loadPlacesService, updatePlaceService } from "../../services/places";
import { createMenuCategoryService, deleteMenuCategoryService, loadMenuCategoriesService, updateMenuCategoryService } from "../../services/menuCategory";
import { uploadFileService } from "../../services/file";
import { createMenuSubCategoryService, deleteMenuSubCategoryService, updateMenuSubCategoryService } from "../../services/menuSubCategory";
import TextArea from "antd/es/input/TextArea";
import { createMenuItemService, deleteMenuItemService, updateBulkPriceService, updateMenuItemService } from "../../services/menuItem";
import { confirm } from "../../components/confirm";
import ReactSelectStyle from "../../style/react-select-style";

const MenuManagement = () => {
    document.title = "Ürün Yönetimi | Sipyo";

    var allergies = [
        { label: "Mısır", value: "corn" },
        { label: "Çikolata", value: "chocolate" },
        { label: "Balık ve Deniz Ürünleri", value: "fishAndSeaProducts" },
        { label: "Süt Ürünleri", value: "milkProducts" },
        { label: "Gluten", value: "gluten" },
        { label: "Kabuklu Kuruyemiş", value: "nuts" },
        { label: "Yumurta", value: "egg" },
        { label: "Yer Fıstığı", value: "peanut" },
        { label: "Kuru Baklagil", value: "legume" }
    ];

    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [findedItems, setFindedItems] = useState(null);
    const [menuCategories, setMenuCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsIndex, setItemsIndex] = useState({});
    const [filter, setFilter] = useState({ place: localStorage.getItem("place") });
    const [categoryModal, setCategoryModal] = useState(false);
    const [subcategoryModal, setSubCategoryModal] = useState(false);
    const [itemModal, setItemModal] = useState(false);
    const [percentage, setUploadPercentage] = useState(0);
    const [portion, setPortion] = useState({});

    const [updateBulkPriceModal, setUpdateBulkPriceModal] = useState(false);
    const [updateBulkPriceMode, setUpdateBulkPriceMode] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const categoryModalImageRef = useRef();
    const subcategoryModalImageRef = useRef();
    const itemModalImageRef = useRef();

    const loadMenuCategories = async (firstLoad, reloadItems) => {
        await loadMenuCategoriesService(filter).then(async (res) => {
            res = res?.data;
            if (!res?.errors) {
                res?.data?.menuCategories?.map((category) => category.isOpen = false);
                if (res?.data?.menuCategories.length > 0) {
                    res.data.menuCategories[0].isOpen = true;
                }

                let subCategories_ = [];

                await res?.data?.menuCategories.forEach(category => {
                    for (var subCategory of category.subCategories) {
                        subCategory.category = category.id;
                    }

                    subCategories_.push(...category.subCategories);
                });

                await subCategories_.map((subcategory) => {
                    subcategory?.items?.map((item, itemIndex) => {
                        if (item.portions) {
                            item.portions.map((portion, portionIndex) => portion.id = portionIndex);
                        }
                    });
                });

                setMenuCategories(res?.data?.menuCategories);
                setSubCategories(subCategories_);
                if (firstLoad) {
                    setItems(res?.data?.menuCategories[0]?.items);
                    setItemsIndex({ categoryIndex: 0 });
                } else if (reloadItems) {
                    console.log(itemsIndex);
                    if (itemsIndex.subcategoryIndex != null && itemsIndex.subcategoryIndex != undefined) {
                        console.log("subCategoryIndex", itemsIndex.subcategoryIndex);
                        setItems(res?.data?.menuCategories[itemsIndex.categoryIndex || 0]?.subCategories[itemsIndex.subcategoryIndex]?.items);
                    } else {
                        setItems(res?.data?.menuCategories[itemsIndex.categoryIndex || 0]?.items);

                    }

                }
            }
        })
    }

    useEffect(() => {
        loadMenuCategories(true);

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const deleteItem = async (item) => {
        if (await confirm("Ürünü silmek istediğinizden emin misiniz?")) {
            deleteMenuItemService({ itemId: itemModal?.id || item.id })
                .then((res) => {
                    if (res.data && !res.data.errors) {
                        toast.success("Ürün Silindi.");

                        setItemModal(null);
                        setPortion(null);
                        loadMenuCategories(false, true);
                    }
                })
        }
    }

    const deleteCategory = async (item) => {
        if (await confirm("Kategoriyi silmek istediğinizden emin misiniz?")) {
            deleteMenuCategoryService({ categoryId: categoryModal?.id || item?.id })
                .then((res) => {
                    if (res.data && !res.data.errors) {
                        toast.success("Kategori Silindi.");

                        setCategoryModal(null);
                        loadMenuCategories();
                    }
                })
        }
    }

    const deleteSubCategory = async (item) => {
        if (await confirm("Alt Kategoriyi silmek istediğinizden emin misiniz?")) {
            deleteMenuSubCategoryService({ subcategoryId: subcategoryModal?.id || item.id })
                .then((res) => {
                    if (res.data && !res.data.errors) {
                        toast.success("Alt Kategori Silindi.");

                        setSubCategoryModal(null);
                        loadMenuCategories();
                    }
                })
        }
    }

    const checkParameters = (managerParameters, object) => {
        for (var parameter of managerParameters) {
            if (!object?.hasOwnProperty(parameter.value)) {
                toast.error(`Lütfen ${parameter.label} alanını doldurunuz!`);

                return false;
            }
        }

        return true;
    }

    const handleSelectAll = () => {
        let newData = [...items];
        for (let item of newData) {
            item.selected = true;
        }
        const newCategories = [...menuCategories];
        newCategories.map((category, categoryIndex) => {
            if (categoryIndex == itemsIndex?.categoryIndex) {
                category.selected = true;

                if (itemsIndex?.subcategoryIndex == null && itemsIndex?.subcategoryIndex == undefined) {
                    category.items.map((item) => item.selected = true);
                    category.subCategories.map((subcategory) => {
                        subcategory.selected = true;
                        subcategory.items.map((item) => item.selected = true);
                    });
                } else {
                    category.subCategories.map((subcategory, subcategoryIndex) => {
                        if (subcategoryIndex == itemsIndex?.subcategoryIndex) {
                            category.items.map((item) => {
                                if (item.subcategory.id == subcategory.id) {
                                    item.selected = true;
                                }
                            });
                            subcategory.selected = true;
                            subcategory.items.map((item) => {
                                item.selected = true;
                            });
                        }
                    });

                }
            }
        });

        setItems(newData);
        setMenuCategories(newCategories);
    }

    const handleUnSelectAll = () => {
        let newData = [...items];
        let newCategories = [...menuCategories];
        newData.map((item) => item.selected = false);

        newCategories.map((category, categoryIndex) => {
            if (itemsIndex?.categoryIndex == categoryIndex && itemsIndex?.subcategoryIndex == null) {
                category.selected = false;
                category.items.map((item) => item.selected = false);
                category.subCategories.map((subcategory) => {
                    subcategory.selected = false;
                    subcategory.items.map((item) => item.selected = false);
                });
            } else {
                category.subCategories.map((subcategory, subcategoryIndex) => {
                    if (itemsIndex?.subcategoryIndex == subcategoryIndex) {
                        category.items.map((item) => {
                            if (item.subcategory.id == subcategory.id) {
                                item.selected = false;
                            }
                        });
                        subcategory.selected = false;
                        subcategory.items.map((item) => {
                            item.selected = false;
                        });
                    }
                });

            }
        });

        setItems(newData);
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    display: width <= 575 ? "flex" : "",
                    flexDirection: width <= 575 ? "row" : "",
                    justifyContent: width <= 575 ? "center" : "",
                    alignItems: width <= 575 ? "center" : "",
                    background: "#3a3a3a",
                    height: width <= 575 ? "175px" : width <= 580 ? "90px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: width <= 575 ? "150px" : width <= 580 ? "90px" : "75px" }} className="mx-0">
                        <Col xs={width <= 575 ? 24 : 12} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ marginTop: width <= 440 ? "10px" : "" }} className="px-0">
                            <Input.Search
                                placeholder="İsim ile ara..."
                                size="large"
                                onSearch={(value) => {
                                    if (!value) {
                                        setFindedItems(null);
                                        return;
                                    }

                                    var newFindedItems = [];

                                    menuCategories.map((category) => {
                                        if (category.name.toLowerCase().includes(value.toLowerCase())) {
                                            newFindedItems.push(category);
                                        }

                                        category.subCategories.map((subcategory) => {
                                            if (subcategory.name.toLowerCase().includes(value.toLowerCase())) {
                                                newFindedItems.push(subcategory);
                                            }

                                            subcategory.items.map((item) => {
                                                if (item.name.toLowerCase().includes(value.toLowerCase())) {
                                                    newFindedItems.push(item);
                                                }
                                            });
                                        });

                                        category.items.map((item) => {
                                            if (item.name.toLowerCase().includes(value.toLowerCase())) {
                                                newFindedItems.push(item);
                                            }
                                        });
                                    });

                                    // ! Filter to unique items by id
                                    newFindedItems = newFindedItems.filter((item, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.id === item.id
                                        ))
                                    );

                                    setFindedItems(newFindedItems);
                                }}
                            />
                        </Col>
                        <Col xs={width <= 575 ? 24 : 12} sm={18} md={3} lg={3} xl={3} xxl={3} className="px-0">
                            <div style={{ display: "flex", flexDirection: width < 400 ? "column" : "row", flexBasis: width <= 767 && "50%", float: width > 767 && "right" }}>
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <Button
                                        onClick={() => {
                                            setUpdateBulkPriceMode(updateBulkPriceMode ? null : {});
                                            // remove other selected items
                                            let newCategories = [...menuCategories];
                                            newCategories.map((category) => category.selected = false);
                                            newCategories.map((category) => category.items.map((item) => item.selected = false));
                                            newCategories.map((category) => category.subCategories.map((subcategory) => subcategory.selected = false));
                                            newCategories.map((category) => category.subCategories.map((subcategory) => subcategory.items.map((item) => item.selected = false)));
                                            setMenuCategories(newCategories);
                                        }}
                                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                        size="large"
                                        className="ms-1"
                                    >
                                        Toplu Güncelleme{updateBulkPriceMode ? "yi İptal Et" : ""}
                                    </Button>
                                </div>
                                {
                                    !updateBulkPriceMode &&
                                    <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: width < 400 && "5px" }}>
                                        {width <= 767 ? <>
                                            <Button
                                                onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                                                icon={<PlusOutlined />}
                                                style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                                size="large"
                                                className="ms-1"
                                            >
                                                Ürün - Kategori
                                            </Button>
                                        </> : <Button
                                            onClick={() => setItemModal({
                                                digitalMenu: true,
                                                allergies: {
                                                    corn: false,
                                                    chocolate: false,
                                                    fishAndSeaProducts: false,
                                                    milkProducts: false,
                                                    gluten: false,
                                                    nuts: false,
                                                    egg: false,
                                                    peanut: false,
                                                    legume: false,
                                                }
                                            })}
                                            icon={<PlusOutlined />}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                            size="large"
                                            className="ms-1"
                                        >
                                            Yeni Ürün Ekle
                                        </Button>}
                                    </div>
                                }
                            </div>

                            <Collapse isOpen={isOpenCollapse} className="w-50 float-end position-relative " style={{ zIndex: 2000 }}>
                                <div className="d-flex justify-content-center align-items-center flex-column ms-1 bg-white rounded" onClick={() => setIsOpenCollapse(false)}>
                                    <Button
                                        onClick={() => setItemModal({
                                            digitalMenu: true,
                                            allergies: {
                                                corn: false,
                                                chocolate: false,
                                                fishAndSeaProducts: false,
                                                milkProducts: false,
                                                gluten: false,
                                                nuts: false,
                                                egg: false,
                                                peanut: false,
                                                legume: false,
                                            }
                                        })}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Ürün Ekle
                                    </Button>
                                    <Button
                                        onClick={() => setCategoryModal({ digitalMenu: true })}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Kategori Ekle
                                    </Button>
                                    <Button
                                        onClick={() => setSubCategoryModal({ digitalMenu: true })}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Alt Kategori Ekle
                                    </Button>
                                </div>
                            </Collapse>

                        </Col>
                    </Row>
                </Container>

                <div className="menuManagement" style={{ minHeight: "100vh" }}>
                    {width <= 767 ? <>
                        <div className="d-flex flex-row w-100 overflow-y-auto align-items-center py-2" style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }}>
                            {
                                menuCategories?.map((category, categoryIndex) => {
                                    return (
                                        <div className="d-flex flex-row align-items-center w-100 sipyo-border rounded p-2 mx-1" style={{ backgroundColor: "#EEEEEE" }}>
                                            <div className="d-flex" onClick={() => setItems(category?.items)}>
                                                <AppstoreAddOutlined className="mx-1" style={{ fontSize: "18px" }} />
                                                <span className="mx-2">{category?.name}</span>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center sipyo-border-left px-2">
                                                <SettingOutlined onClick={() => setCategoryModal(category)} />
                                                <CaretDownOutlined
                                                    style={{ fontSize: "16px" }}
                                                    className={`rotate-icon ${category?.isOpen ? "rotated" : ""}`}
                                                    onClick={() => {
                                                        let newData = [...menuCategories];

                                                        if (newData[categoryIndex].isOpen) {
                                                            newData[categoryIndex].isOpen = false;
                                                            setMenuCategories(newData);
                                                            return;
                                                        }

                                                        newData?.map((category) => category.isOpen = false);
                                                        newData[categoryIndex].isOpen = !newData[categoryIndex].isOpen;
                                                        setMenuCategories(newData);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Collapse isOpen={true}>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    menuCategories.filter((plt) => plt?.isOpen)[0]?.subCategories?.map((subcategory, subcategoryIndex) => {
                                        let categoryIndex = menuCategories.findIndex((plt) => plt.id == subcategory.category);
                                        return (
                                            <div className="d-flex flex-row align-items-center sipyo-border rounded p-2 mx-1" style={{ backgroundColor: itemsIndex?.categoryIndex == categoryIndex && itemsIndex?.subcategoryIndex == subcategoryIndex ? "#ffffff" : "#EEEEEE", whiteSpace: "nowrap", flexWrap: "nowrap" }} onClick={() => {
                                                setItems(subcategory.items);
                                                setItemsIndex({ categoryIndex: categoryIndex, subcategoryIndex: subcategoryIndex });
                                            }}>
                                                <div className="d-flex">
                                                    <span className="mx-2">{subcategory?.name}</span>
                                                </div>
                                                <div className="d-flex flex-column justify-content-center align-items-center sipyo-border-left px-2">
                                                    <SettingOutlined onClick={() => setSubCategoryModal(subcategory)} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Collapse>

                    </> : <div className={`${width >= 937 && "sipyo-border-right"} ${width < 937 && "sipyo-border-bottom"}`} style={{ backgroundColor: "#f4f4f4", boxShadow: "0px 0px 2px rgb(0 0 0 / 13%)" }} >
                        <center>
                            <Button onClick={() => setCategoryModal({ digitalMenu: true })} className="btn-xxxl" icon={<PlusOutlined />} size={""} style={{ height: "auto", whiteSpace: "normal", backgroundColor: "#c72328", color: "white", marginTop: "20px" }}>
                                Yeni Kategori Ekle
                            </Button>
                            <Button onClick={() => setSubCategoryModal({ digitalMenu: true })} className="btn-xxxl" icon={<PlusOutlined />} size={""} style={{ height: "auto", whiteSpace: "normal", backgroundColor: "#666666", color: "white", marginTop: "5px" }}>
                                Yeni Alt Kategori Ekle
                            </Button>
                            <hr className="sipyo-border-bottom" />
                            <div>
                                {
                                    menuCategories?.map((category, categoryIndex) => (
                                        <div>
                                            <div style={{ width: "95%", height: "50px", display: "flex", alignItems: "center", textAlign: "center", backgroundColor: "#e8e8e8", borderRadius: "5px", marginBottom: "10px" }} className={`${itemsIndex?.categoryIndex == categoryIndex && itemsIndex?.subcategoryIndex == null && "sipyo-border"}`}>
                                                <div style={{ cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "row", width: "80%", height: "50px" }} onClick={() => {
                                                    setItems(category.items);
                                                    setItemsIndex({ categoryIndex: categoryIndex });
                                                }}>
                                                    <AppstoreAddOutlined style={{ fontSize: "24px", marginLeft: "12px" }} />
                                                    <h5 style={{ margin: 0, marginLeft: "5px", fontWeight: "500" }}>{category?.name}</h5>
                                                </div>

                                                <div style={{ width: "15%", height: "50px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", fontSize: "22px", marginLeft: "auto", marginRight: "10px" }}>
                                                    <CaretDownOutlined
                                                        style={{ fontSize: "16px" }}
                                                        className={`rotate-icon ${category?.isOpen ? "rotated" : ""}`}
                                                        onClick={() => {
                                                            let newData = [...menuCategories];
                                                            newData[categoryIndex].isOpen = !newData[categoryIndex].isOpen;
                                                            setMenuCategories(newData);
                                                        }}
                                                    />
                                                    {updateBulkPriceMode ? <>
                                                        <Checkbox style={{ transform: "scale(1)" }} onChange={(e) => {
                                                            let newCategories = [...menuCategories];

                                                            for (let item of newCategories[categoryIndex].items) {
                                                                item.selected = e.target.checked;
                                                                var updateCategoryIndex = newCategories.findIndex((plt) => plt.id == item.category.id);

                                                                if (updateCategoryIndex > -1) {
                                                                    var subCategoryIndex = newCategories[updateCategoryIndex].subCategories?.findIndex((plt) => plt.id == item.subcategory.id);
                                                                    if (subCategoryIndex > -1) {
                                                                        newCategories[updateCategoryIndex].subCategories[subCategoryIndex].selected = e.target.checked;
                                                                        var itemIndex = newCategories[updateCategoryIndex]?.subCategories[subCategoryIndex]?.items?.findIndex((plt) => plt.id == item.id);
                                                                        console.log(subCategoryIndex, itemIndex);
                                                                        newCategories[updateCategoryIndex].subCategories[subCategoryIndex].items[itemIndex].selected = e.target.checked;
                                                                    }
                                                                }
                                                            }

                                                            newCategories[categoryIndex].selected = e.target.checked;
                                                            console.log(newCategories[categoryIndex]);
                                                            setMenuCategories(newCategories);
                                                        }} checked={category?.selected} />
                                                    </> : <MoreOutlined onClick={() => setCategoryModal(category)} />}
                                                </div>

                                            </div>
                                            <Collapse isOpen={category?.isOpen}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "95%" }}>
                                                    {
                                                        category?.subCategories?.map((subcategory, subcategoryIndex) => (
                                                            <div className={`${itemsIndex?.categoryIndex == categoryIndex && itemsIndex?.subcategoryIndex == subcategoryIndex && "sipyo-border"}`} style={{ background: itemsIndex?.categoryIndex == categoryIndex && itemsIndex?.subcategoryIndex == subcategoryIndex ? "white" : "", cursor: "pointer", height: "40px", display: "flex", alignItems: "center", marginBottom: "5px", borderRadius: "5px" }} onClick={() => {
                                                                setItems(subcategory.items);
                                                                setItemsIndex({ categoryIndex: categoryIndex, subcategoryIndex: subcategoryIndex });
                                                            }}>
                                                                <span style={{ width: "80%", textAlign: "start", marginLeft: "15px" }}>{subcategory?.name}</span>

                                                                <div style={{ width: "20%", float: "right" }}>
                                                                    {
                                                                        updateBulkPriceMode ? <Checkbox style={{ float: "right", marginRight: "10px" }} onChange={(e) => {
                                                                            let newCategories = [...menuCategories];

                                                                            var updateCategoryIndex = newCategories.findIndex((plt) => plt.id == subcategory.category);
                                                                            var updateSubCategoryIndex = newCategories[updateCategoryIndex].subCategories.findIndex((plt) => plt.id == subcategory.id);

                                                                            newCategories[updateCategoryIndex].subCategories[updateSubCategoryIndex].selected = e.target.checked;

                                                                            for (let item of newCategories[updateCategoryIndex].subCategories[updateSubCategoryIndex].items) {
                                                                                item.selected = e.target.checked;
                                                                            }

                                                                            setMenuCategories(newCategories);
                                                                        }} checked={subcategory?.selected} /> : <MoreOutlined onClick={() => setSubCategoryModal(subcategory)} style={{ fontSize: "20px" }} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Collapse>

                                        </div>
                                    ))
                                }
                            </div>

                        </center>
                    </div>}
                    <div style={{ background: "white" }}>
                        {
                            updateBulkPriceMode && <>
                                <Row>
                                    <Col xs={width <= 490 ? 24 : 8} md={width <= 490 ? 24 : 8}>
                                        <div style={{ display: "flex", flexDirection: width <= 490 ? "column" : "row", width: "100%" }}>
                                            <Button
                                                onClick={() => setUpdateBulkPriceModal(true)}
                                                icon={<SettingOutlined />}
                                                style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", margin: "15px", marginBottom: "0px" }}
                                                size="large"
                                            >
                                                Düzenle
                                            </Button>
                                            <Button
                                                onClick={handleSelectAll}
                                                icon={<SettingOutlined />}
                                                style={{ border: "none", marginTop: width <= 490 ? "10px" : "15px", marginBottom: "0px" }}
                                                size="large"
                                            >
                                                Hepsini Seç
                                            </Button>
                                            <Button
                                                onClick={handleUnSelectAll}
                                                icon={<SettingOutlined />}
                                                style={{ border: "none", marginTop: width <= 490 ? "10px" : "15px", marginBottom: "0px" }}
                                                size="large"
                                            >
                                                Tüm Seçimi Kaldır
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        <div style={{ display: "grid", background: "white", gridTemplateColumns: "50% 50%", gridAutoRows: "100px", }}>
                            {
                                findedItems ? findedItems.map((item, itemIndex) => (
                                    <div className="sipyo-border" style={{ borderRadius: "10px", margin: itemIndex % 2 == 0 ? "10px 0px 10px 10px" : "10px 10px 10px 10px", height: "90px" }}>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center" }} className={`h-100`}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                <h5 className="fs-5" style={{ marginLeft: "10px", marginTop: "15px" }}>{item?.name}</h5>
                                                <h5 style={{ marginLeft: "10px", marginBottom: "25px", alignSelf: "flex-start" }}>{item?.price}TL</h5>

                                            </div>

                                            <div className="" style={{ display: "flex", alignItems: "center", float: "right" }}>
                                                {updateBulkPriceMode ? <>
                                                    <Checkbox style={{ marginRight: "30px", transform: "scale(2)" }} onChange={(e) => {
                                                        let newItems = [...items];
                                                        newItems[itemIndex].selected = e.target.checked;

                                                        let newMenuCategories = [...menuCategories];
                                                        let updateCategoryIndex = newMenuCategories.findIndex((plt) => plt.id == item.category.id);

                                                        if (updateCategoryIndex > -1) {
                                                            let itemIndex_ = newMenuCategories[updateCategoryIndex]?.items?.findIndex((plt) => plt.id == item.id);
                                                            newMenuCategories[updateCategoryIndex].items[itemIndex_].selected = e.target.checked;

                                                            let subCategoryIndex = newMenuCategories[updateCategoryIndex].subCategories?.findIndex((plt) => plt.id == item.subcategory.id);
                                                            if (subCategoryIndex > -1) {
                                                                console.log(updateCategoryIndex, subCategoryIndex, itemIndex);
                                                                console.log(newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items[itemIndex]);
                                                                let subCategoryItemIndex = newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items.findIndex((plt) => plt.id == item.id);
                                                                newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items[subCategoryItemIndex].selected = e.target.checked;
                                                            }
                                                        }

                                                        setItems(newItems);
                                                        setMenuCategories(newMenuCategories)
                                                    }} checked={item?.selected} />
                                                </> : <>
                                                    <DeleteOutlined onClick={() => deleteItem(item)} style={{ fontSize: "26px", color: "#c72328", marginLeft: "15px", marginRight: "15px" }} />
                                                    <CopyOutlined onClick={() => setItemModal({ ...item, id: undefined })} style={{ fontSize: "26px" }} />
                                                    <SettingOutlined onClick={() => {
                                                        item?.portions?.map((plt, portionIndex) => plt.id = portionIndex);
                                                        setItemModal(item)
                                                    }} style={{ fontSize: "26px", marginLeft: "15px", marginRight: "15px" }} />
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    items?.map((item, itemIndex) => (
                                        <div className="sipyo-border" style={{ borderRadius: "10px", margin: itemIndex % 2 == 0 ? "10px 0px 10px 10px" : "10px 10px 10px 10px", height: "90px" }}>
                                            <div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center" }} className={`h-100`}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                    <h5 className="fs-5" style={{ marginLeft: "10px", marginTop: "15px" }}>{item?.name}</h5>
                                                    <h5 style={{ marginLeft: "10px", marginBottom: "25px", alignSelf: "flex-start" }}>{item?.price}TL</h5>

                                                </div>

                                                <div className="" style={{ display: "flex", alignItems: "center", float: "right" }}>
                                                    {updateBulkPriceMode ? <>
                                                        <Checkbox style={{ marginRight: "30px", transform: "scale(2)" }} onChange={(e) => {
                                                            let newItems = [...items];
                                                            newItems[itemIndex].selected = e.target.checked;

                                                            let newMenuCategories = [...menuCategories];
                                                            let updateCategoryIndex = newMenuCategories.findIndex((plt) => plt.id == item.category.id);

                                                            if (updateCategoryIndex > -1) {
                                                                let itemIndex_ = newMenuCategories[updateCategoryIndex]?.items?.findIndex((plt) => plt.id == item.id);
                                                                newMenuCategories[updateCategoryIndex].items[itemIndex_].selected = e.target.checked;

                                                                let subCategoryIndex = newMenuCategories[updateCategoryIndex].subCategories?.findIndex((plt) => plt.id == item.subcategory.id);
                                                                if (subCategoryIndex > -1) {
                                                                    console.log(updateCategoryIndex, subCategoryIndex, itemIndex);
                                                                    console.log(newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items[itemIndex]);
                                                                    let subCategoryItemIndex = newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items.findIndex((plt) => plt.id == item.id);
                                                                    newMenuCategories[updateCategoryIndex].subCategories[subCategoryIndex].items[subCategoryItemIndex].selected = e.target.checked;
                                                                }
                                                            }

                                                            setItems(newItems);
                                                            setMenuCategories(newMenuCategories)
                                                        }} checked={item?.selected} />
                                                    </> : width <= 767 ? <div className="d-flex flex-column">
                                                        {!item.open && <MoreOutlined style={{ fontSize: "26px", marginLeft: "15px", marginRight: "15px" }} onClick={() => {
                                                            let newItems = [...items];
                                                            newItems[itemIndex].open = !newItems[itemIndex].open;
                                                            setItems(newItems);
                                                        }} />}

                                                        {item.open && <div className="d-flex flex-column justify-content-between align-items-center sipyo-border rounded mx-1 py-2">
                                                            <DeleteFilled className="my-1" onClick={() => deleteItem(item)} style={{ fontSize: "14px", color: "#c72328", marginLeft: "15px", marginRight: "15px" }} />
                                                            <CopyFilled className="my-1" onClick={() => setItemModal({ ...item, id: undefined })} style={{ fontSize: "14px" }} />
                                                            <SettingFilled className="my-1" onClick={() => {
                                                                item?.portions?.map((plt, portionIndex) => plt.id = portionIndex);
                                                                setItemModal(item)
                                                            }} style={{ fontSize: "14px", marginLeft: "15px", marginRight: "15px" }} />
                                                        </div>}
                                                    </div> : <>
                                                        <DeleteOutlined onClick={() => deleteItem(item)} style={{ fontSize: "26px", color: "#c72328", marginLeft: "15px", marginRight: "15px" }} />
                                                        <CopyOutlined onClick={() => setItemModal({ ...item, id: undefined })} style={{ fontSize: "26px" }} />
                                                        <SettingOutlined onClick={() => {
                                                            item?.portions?.map((plt, portionIndex) => plt.id = portionIndex);
                                                            setItemModal(item)
                                                        }} style={{ fontSize: "26px", marginLeft: "15px", marginRight: "15px" }} />
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                isOpen={!!categoryModal}
                toggle={() => {
                    setCategoryModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setCategoryModal(false);
                }}>
                    {categoryModal?.id ? "Kategori Güncelle" : "Kategori Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={24} md={24}>
                            <Label>Kategori İsmi</Label>
                            <Input defaultValue={categoryModal?.name} placeholder="Kategori İsmi" onChange={(e) => setCategoryModal({ ...categoryModal, name: e.target.value })} />
                        </Col>


                        <Col xs={24} md={24}>
                            <Label className="mt-2">Kategori Görsel (Opsiyonel)</Label>
                            <div className="d-flex align-items-center flex-column flex-md-row gap-1 w-100 justify-content-start">
                                <img onError={(e) => { e.target.onerror = null; e.target.src = 'https://api.sipyo.com.tr/files/new_file-b1f2b90d-919c-4795-b9af-e838ec8d4410new_file.png' }}
                                    src={`${process.env.REACT_APP_LIVE_URL}${categoryModal?.image}`}
                                    style={{ width: 250 }}
                                    alt=""
                                    onClick={() => categoryModalImageRef?.current?.click()}
                                />
                                <div className="d-flex flex-column gap-1">
                                    <div className="d-flex flex-column">
                                        <span className="fw-bolder">Ayarlar:</span>

                                        <span>
                                            <Switch style={{ background: "#c72328" }} defaultChecked={categoryModal?.digitalMenu} onChange={(value) => { console.log(value); setCategoryModal({ ...categoryModal, digitalMenu: value }) }} /> Dijital Menüde göster
                                            <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip>
                                        </span>
                                        <span style={{ marginTop: "5px" }}>
                                            <Switch style={{ background: "#c72328" }} defaultChecked={categoryModal?.subcategory} onChange={(value) => { console.log(value); setCategoryModal({ ...categoryModal, subcategory: value }) }} /> Alt Kategori
                                            <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip>
                                        </span>
                                    </div>

                                    <label
                                        htmlFor="upload-photo"
                                        className="d-flex align-items-center"
                                        style={{ width: "100%", marginTop: "25px" }}
                                        onClick={() => categoryModalImageRef?.current?.click()}
                                    >
                                        <ReactStrapButton
                                            color="dark"
                                            type="button"
                                            className="btn-icon"
                                        >
                                            Göz At
                                        </ReactStrapButton>
                                        <input
                                            ref={categoryModalImageRef}
                                            type="file"
                                            onChange={async (e) => {
                                                if (e.target.files.length > 0) {
                                                    uploadFileService(e.target.files[0])
                                                        .then((res) => res.json())
                                                        .then((response) => {
                                                            console.log(response);

                                                            setCategoryModal({ ...categoryModal, image: response.path })
                                                        });
                                                }
                                            }}
                                            name="photo"
                                            id="upload-photo"
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Col>



                    </Row>
                </ModalBody>
                <ModalFooter>
                    {categoryModal?.id ? <Button onClick={() => deleteCategory()} style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}>Kategoriyi Sil</Button> : <></>}
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            categoryModal.place = localStorage.getItem("place");
                            delete categoryModal.items;
                            if (categoryModal?.id) {
                                categoryModal.categoryId = categoryModal.id;
                                if (typeof categoryModal.manager == "object") {
                                    categoryModal.manager = categoryModal?.manager?.id;
                                }
                                categoryModal.brand = categoryModal?.brand?.id;

                                if (!categoryModal.digitalMenu) {
                                    categoryModal.digitalMenu = false;
                                }

                                delete categoryModal.id;
                                delete categoryModal.createdAt;
                                delete categoryModal.addedBy;
                                delete categoryModal.subCategories;
                                delete categoryModal.isOpen;

                                updateMenuCategoryService(categoryModal).then((res) => {
                                    if (res.data && !res.data.errors) {

                                        toast.success("Kategori Güncellendi.");

                                        setCategoryModal(null);
                                        loadMenuCategories();
                                    }
                                })
                            } else {
                                if (!checkParameters([{ value: "name", label: "Kategori İsmi" }], categoryModal)) { return; }

                                createMenuCategoryService(categoryModal).then((res) => {
                                    if (res.data && !res.data.errors) {

                                        toast.success("Kategori Oluşturuldu.");

                                        setCategoryModal(null);
                                        loadMenuCategories();
                                    }
                                })
                            }
                        }}
                    >
                        {categoryModal?.id ? "Kategoriyi Güncelle" : "Kategori Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={!!subcategoryModal}
                toggle={() => {
                    setSubCategoryModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setSubCategoryModal(false);
                }}>
                    {subcategoryModal?.id ? "Alt Kategori Güncelle" : "Alt Kategori Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={24} md={24}>
                            <Label>Alt Kategori İsmi</Label>
                            <Input defaultValue={subcategoryModal?.name} placeholder="Alt Kategori İsmi" onChange={(e) => setSubCategoryModal({ ...subcategoryModal, name: e.target.value })} />
                        </Col>
                        <Col xs={12} md={12}>
                            <Label>Üst Kategori</Label>
                            <Select
                                styles={ReactSelectStyle}
                                options={[
                                    ...menuCategories?.map((category) => { return { label: category.name, value: category.id } })
                                ]}
                                defaultValue={{ label: menuCategories?.filter(category => category.id == subcategoryModal?.category)[0]?.name, value: menuCategories?.filter(category => category.id == subcategoryModal?.category)[0]?.id }}
                                onChange={(e) => setSubCategoryModal({ ...subcategoryModal, category: e.value })}
                                placeholder="Üst Kategori"
                            />
                        </Col>


                        <Col xs={24} md={24}>
                            <Label className="mt-2">Alt Kategori Görsel (Opsiyonel)</Label>
                            <div className="d-flex align-items-center flex-column flex-md-row gap-1 w-100 justify-content-start">
                                <img onError={(e) => { e.target.onerror = null; e.target.src = 'https://api.sipyo.com.tr/files/new_file-b1f2b90d-919c-4795-b9af-e838ec8d4410new_file.png' }}
                                    src={`${process.env.REACT_APP_LIVE_URL}${subcategoryModal?.image}`}
                                    style={{ width: 250 }}
                                    alt=""
                                    onClick={() => subcategoryModalImageRef?.current?.click()}
                                />
                                <div className="d-flex flex-column gap-1">
                                    <diMv className="d-flex flex-column">
                                        <span className="fw-bolder">Ayarlar:</span>

                                        <span>
                                            <Switch style={{ background: "#c72328" }} defaultChecked={subcategoryModal?.digitalMenu} onChange={(value) => { console.log(value); setSubCategoryModal({ ...subcategoryModal, digitalMenu: value }) }} /> Dijital Menüde göster
                                            <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip>
                                        </span>
                                    </diMv>

                                    <label
                                        htmlFor="upload-photo"
                                        className="d-flex align-items-center"
                                        style={{ width: "100%", marginTop: "25px" }}
                                        onClick={() => subcategoryModalImageRef?.current?.click()}
                                    >
                                        <ReactStrapButton
                                            color="dark"
                                            type="button"
                                            className="btn-icon"
                                        >
                                            Göz At
                                        </ReactStrapButton>
                                        <input
                                            ref={subcategoryModalImageRef}
                                            type="file"
                                            onChange={async (e) => {
                                                if (e.target.files.length > 0) {
                                                    uploadFileService(e.target.files[0])
                                                        .then((res) => res.json())
                                                        .then((response) => {
                                                            console.log(response);

                                                            setSubCategoryModal({ ...subcategoryModal, image: response.path })
                                                        });
                                                }
                                            }}
                                            name="photo"
                                            id="upload-photo"
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Col>



                    </Row>
                </ModalBody>
                <ModalFooter>
                    {subcategoryModal?.id ? <Button onClick={() => deleteSubCategory()} style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}>Alt Kategoriyi Sil</Button> : <></>}
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            subcategoryModal.place = localStorage.getItem("place");
                            if (subcategoryModal?.id) {
                                subcategoryModal.subcategoryId = subcategoryModal.id;

                                subcategoryModal.brand = subcategoryModal?.brand?.id;

                                delete subcategoryModal.id;
                                delete subcategoryModal.createdAt;
                                delete subcategoryModal.addedBy;
                                delete subcategoryModal.items;
                                delete subcategoryModal.category;

                                updateMenuSubCategoryService(subcategoryModal).then((res) => {
                                    if (res.data && !res.data.errors) {

                                        toast.success("Alt Kategori Güncellendi.");

                                        setSubCategoryModal(null);
                                        loadMenuCategories();
                                    }
                                })
                            } else {
                                if (!checkParameters([{ value: "name", label: "Kategori İsmi" }, { value: "category", label: "Üst Kategori" }], subcategoryModal)) { return; }
                                createMenuSubCategoryService(subcategoryModal).then((res) => {
                                    if (res.data && !res.data.errors) {

                                        toast.success("Alt Kategori Oluşturuldu.");

                                        setSubCategoryModal(null);
                                        loadMenuCategories();
                                    }
                                })
                            }
                        }}
                    >
                        {subcategoryModal?.id ? "Alt Kategoriyi Güncelle" : "Alt Kategori Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                size="xl"
                isOpen={!!itemModal}
                toggle={() => {
                    setItemModal(false);
                    setPortion(null);
                }}
                className="modal-dialog-centered modal-xl"
            >
                <ModalHeader toggle={() => {
                    setItemModal(false);
                    setPortion(null);
                }}>
                    {itemModal?.id ? "Ürün Güncelle" : "Ürün Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={[12, 12]} >
                        <Col xs={24} md={24}>
                            <Label>Ürün İsmi</Label>
                            <Input defaultValue={itemModal?.name} placeholder="Ürün İsmi" onChange={(e) => setItemModal({ ...itemModal, name: e.target.value })} />
                        </Col>

                        <Col xs={width <= 520 ? 24 : 12} md={width <= 520 ? 24 : 12}>
                            <Label>Kategori</Label>
                            <Select
                                styles={ReactSelectStyle}
                                placeholder="Kategori"
                                options={[
                                    ...menuCategories?.map((category) => { return { label: category?.name, value: category?.id } })
                                ]}
                                defaultValue={{ label: itemModal?.category?.name, value: itemModal?.category?.id }}
                                onChange={(e) => setItemModal({ ...itemModal, category: e.value, subcategory: null })}
                            />
                        </Col>

                        <Col xs={width <= 520 ? 24 : 12} md={width <= 520 ? 24 : 12}>
                            <Label>Alt Kategori {!menuCategories.filter((plt) => plt.id == itemModal?.category)[0]?.subcategory ? <Tooltip title={"Kategorinin 'Alt Kategori' özelliği aktif değil!"} ><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip> : <></>}</Label>
                            <Select
                                styles={ReactSelectStyle}
                                isDisabled={!menuCategories.filter((plt) => plt.id == itemModal?.category)[0]?.subcategory}
                                placeholder="Alt Kategori"
                                options={[
                                    ...subCategories?.filter((subCategory) => subCategory?.category == itemModal?.category)?.map((subCategory) => { return { label: subCategory?.name, value: subCategory?.id } })
                                ]}
                                defaultValue={{ label: itemModal?.subcategory?.name, value: itemModal?.subcategory?.id }}
                                onChange={(e) => setItemModal({ ...itemModal, subcategory: e.value })}
                            />
                        </Col>

                        <Col xs={width <= 520 ? 24 : 12} md={width <= 520 ? 24 : 12}>
                            <Label>Ürün Açıklaması</Label>
                            <TextArea
                                onChange={(e) => setItemModal({ ...itemModal, description: e.target.value })}
                                placeholder="Ürün Açıklaması"
                                defaultValue={itemModal?.description}
                            />
                        </Col>

                        <Col xs={width <= 520 ? 24 : 12} md={width <= 520 ? 24 : 12}>
                            <Label className="mt-2">Ürün Görsel (Opsiyonel)</Label>
                            <div className="d-flex align-items-center flex-column flex-md-row gap-1 w-100 justify-content-start">
                                <img
                                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://api.sipyo.com.tr/files/new_file-b1f2b90d-919c-4795-b9af-e838ec8d4410new_file.png' }}
                                    src={`${process.env.REACT_APP_LIVE_URL}${itemModal?.image}`}
                                    style={{ width: 250 }}
                                    alt=""
                                    onClick={() => itemModalImageRef?.current?.click()}
                                />
                                <div className="d-flex flex-column gap-1">
                                    <label
                                        htmlFor="upload-photo"
                                        className="d-flex align-items-center"
                                        style={{ width: "100%", marginTop: "25px" }}
                                        onClick={() => itemModalImageRef?.current?.click()}
                                    >
                                        <ReactStrapButton
                                            color="dark"
                                            type="button"
                                            className="btn-icon"
                                        >
                                            Göz At
                                        </ReactStrapButton>
                                        <input
                                            ref={itemModalImageRef}
                                            type="file"
                                            onChange={async (e) => {
                                                if (e.target.files.length > 0) {
                                                    uploadFileService(e.target.files[0])
                                                        .then((res) => res.json())
                                                        .then((response) => {
                                                            console.log(response);

                                                            setItemModal({ ...itemModal, image: response.path })
                                                        });
                                                }

                                            }}
                                            name="photo"
                                            id="upload-photo"
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Col>

                        <Col xs={width <= 991 ? 8 : 4} md={width <= 991 ? 8 : 4}>
                            <Label>Fiyat</Label>
                            <Input
                                type="number"
                                placeholder="Fiyat"
                                defaultValue={itemModal?.price}
                                onChange={(e) => setItemModal({ ...itemModal, price: Number(e.target.value) })}
                            />
                        </Col>

                        <Col xs={width <= 991 ? 8 : 4} md={width <= 991 ? 8 : 4}>
                            <Label>KDV</Label>
                            <Input
                                type="number"
                                placeholder="KDV"
                                defaultValue={itemModal?.tax}
                                onChange={(e) => setItemModal({ ...itemModal, tax: Number(e.target.value) })}
                                suffix={<PercentageOutlined />}
                            />
                        </Col>

                        <Col xs={width <= 991 ? 8 : 4} md={width <= 991 ? 8 : 4}>
                            <Label>Ürün Kodu</Label>
                            <Input
                                placeholder="Ürün Kodu"
                                defaultValue={itemModal?.code}
                                onChange={(e) => setItemModal({ ...itemModal, code: e.target.value })}
                            />
                        </Col>

                        <Col xs={12} md={12} />

                        {
                            itemModal?.portion ? <>
                                <Col xs={24} md={24}>
                                    <hr />

                                    <Label><b>Porsiyon Ekle</b></Label>
                                    <Row gutter={[32, 4]}>
                                        <Col xs={width < 992 ? 24 : 12} md={width < 992 ? 24 : 12}>
                                            <Row gutter={[32, 16]}>
                                                <Col xs={24} md={24}>
                                                    <Label>
                                                        Birim Adı
                                                    </Label>
                                                    <Input
                                                        value={portion?.title}
                                                        onChange={(e) => setPortion({ ...portion, title: e.target.value })}
                                                        placeholder="Tam, Yarım, XL"
                                                    />
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <Label>
                                                        Fiyat
                                                    </Label>
                                                    <Input
                                                        value={Number(portion?.price)}
                                                        type="number"
                                                        placeholder="Fiyat"
                                                        suffix={"₺"}
                                                        onChange={(e) => setPortion({ ...portion, price: Number(e.target.value) })}
                                                    />
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
                                                        <span><Checkbox checked={portion?.defaultPortion} onChange={(e) => setPortion({ ...portion, defaultPortion: e.target.checked })} /> Varsayılan Porsiyon <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ fontSize: "14px" }} /></Tooltip></span>
                                                        <span><Tooltip title={!itemModal?.category?.digitalMenu ? "Bu özelliği kullanabilmek için Menü'de Göster seçeneğini aktifleştirmeniz gereklidir." : null}><Checkbox disabled={!itemModal?.category?.digitalMenu} checked={!itemModal?.category?.digitalMenu ? false : portion?.listPortion} onChange={(e) => setPortion({ ...portion, listPortion: e.target.checked })} /></Tooltip> Menüde Ayrı Listele <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ fontSize: "14px" }} /></Tooltip></span>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    {
                                                        portion?.listPortion ? <div>
                                                            <Label>
                                                                Menüde Görünecek Porsiyon İsmi
                                                            </Label>
                                                            <Input
                                                                value={portion?.menuTitle}
                                                                placeholder="Menüde Görünecek Porsiyon İsmi"
                                                                onChange={(e) => setPortion({ ...portion, menuTitle: e.target.value })}
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                        {portion?.id !== null && portion?.id !== undefined && <Button onClick={() => setPortion(null)} danger style={{ marginRight: "10px" }}>İptal</Button>}
                                                        <Button onClick={() => {
                                                            setItemModal((prevItem) => {
                                                                if (!prevItem?.portions) {
                                                                    prevItem.portions = [];
                                                                }

                                                                if (portion?.id !== null && portion?.id !== undefined) {
                                                                    prevItem.portions[portion.id] = portion;

                                                                    return { ...prevItem }
                                                                } else {
                                                                    return { ...prevItem, portions: [...prevItem.portions, portion] }
                                                                }
                                                            });
                                                            setPortion(null);
                                                        }}>Porsiyon {portion?.id !== null && portion?.id !== undefined ? "Düzenle" : "Ekle"}</Button>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs={width < 992 ? 24 : 12} md={width < 992 ? 24 : 12}>
                                            <div className={`${itemModal?.portions?.length > 0 && "sipyo-border"}`}>
                                                {
                                                    itemModal?.portions?.map((portion_, portionIndex) => (
                                                        <div>
                                                            {portionIndex == 0 ? <hr /> : <></>}
                                                            <div style={{ display: "grid", gridTemplateColumns: width < 480 ? "30% 70%" : width < 992 ? "65% 35%" : width < 1200 ? "55% 45%" : "70% 30%" }}>
                                                                <div style={{ marginLeft: "10px" }}>
                                                                    <h5>{portion_?.title}</h5>
                                                                    <h5>{portion_?.price}₺</h5>
                                                                </div>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <Button style={{}} onClick={() => {
                                                                        setItemModal(prevState => {
                                                                            const updatedPortions = [...prevState.portions];
                                                                            updatedPortions.splice(portionIndex, 1);

                                                                            return { ...prevState, portions: updatedPortions };
                                                                        });

                                                                    }} danger >Porsiyonu Sil</Button>
                                                                    <SettingOutlined onClick={() => setPortion({ ...portion_, id: portionIndex })} style={{ fontSize: "28px", marginLeft: "10px" }} />
                                                                </div>

                                                            </div>
                                                            <hr />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                            </> : <></>
                        }

                        {
                            itemModal?.allergy ? <>
                                <Col xs={24} md={24}>
                                    <Label><b>Alerjen Madde Ekle</b></Label>
                                    <Row>
                                        <Col xs={24} sm={24} md={12}>
                                            <Row gutter={[12, 12]}>
                                                {
                                                    allergies.map((allergy, allergyIndex) => (
                                                        <Col xs={8} md={8}>
                                                            <div class="d-flex justify-content-center rounded p-2 user-select-none " style={{
                                                                border: "0.1px solid",
                                                                borderColor: itemModal?.allergies[allergy.value] ? "#c72328" : "#d9d9d9",
                                                                background: itemModal?.allergies[allergy.value] ? "#C7141433" : "#F2F2F2",
                                                                color: itemModal?.allergies[allergy.value] ? "#c72328" : "#565656",
                                                                cursor: "pointer"
                                                            }} onClick={() => {
                                                                let newItemModal = { ...itemModal };
                                                                newItemModal.allergies[allergy.value] = !newItemModal.allergies[allergy.value];
                                                                setItemModal(newItemModal);
                                                            }}>
                                                                {allergy.label}
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </> : <></>
                        }

                        <Col xs={24} md={24}>
                            <hr />
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <Label><b>Ayarlar</b></Label>

                                <span style={{ marginBottom: "10px" }} ><Switch defaultChecked={itemModal?.digitalMenu} onChange={(value) => setItemModal({ ...itemModal, digitalMenu: value })} /> Dijital Menüde Göster <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ fontSize: "12px" }} /></Tooltip></span>
                                <span style={{ marginBottom: "10px" }} ><Switch defaultChecked={itemModal?.portion} onChange={(value) => setItemModal({ ...itemModal, portion: value })} /> Porsiyon Bilgisi Sorulsun <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ fontSize: "12px" }} /></Tooltip></span>
                                <span style={{ marginBottom: "10px" }} ><Switch defaultChecked={itemModal?.allergy} onChange={(value) => setItemModal({ ...itemModal, allergy: value })} /> Alerjen Madde İçerir <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ fontSize: "12px" }} /></Tooltip></span>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {itemModal?.id ? <>
                        <Button style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }} onClick={() => deleteItem()}>
                            Ürünü Sil
                        </Button>
                    </> : <></>}
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            if (menuCategories.filter((plt) => plt?.id == itemModal?.category)[0]?.subcategory && !itemModal?.subcategory) {
                                toast.error("Lütfen bir alt kategori seçiniz!");
                                return;
                            }

                            itemModal.place = localStorage.getItem("place");
                            itemModal?.portions?.forEach(portion => {
                                delete portion.id;

                                if (!portion?.defaultPortion) {
                                    portion.defaultPortion = false;
                                }
                            })

                            if (itemModal?.id) {
                                itemModal.itemId = itemModal?.id;
                                if (typeof itemModal.manager == "object") {
                                    itemModal.manager = itemModal?.manager?.id;
                                }

                                if (typeof itemModal?.category == "object") {
                                    itemModal.category = itemModal?.category?.id;
                                }

                                if (typeof itemModal.subcategory == "object") {
                                    itemModal.subcategory = itemModal?.subcategory?.id;
                                }
                                itemModal.brand = itemModal?.brand?.id;

                                delete itemModal.id;
                                delete itemModal.createdAt;
                                delete itemModal.selected;
                                delete itemModal.open;

                                updateMenuItemService(itemModal).then((res) => {
                                    if (res.data && !res.data.errors) {
                                        delete itemModal.itemId;

                                        toast.success("Ürün Güncellendi.");

                                        setItemModal(null);
                                        setPortion(null);
                                        loadMenuCategories(false, true);
                                    }
                                })
                            } else {
                                delete itemModal.createdAt;
                                if (typeof itemModal?.category == "object") { itemModal.category = itemModal?.category?.id }
                                if (typeof itemModal?.subcategory == "object") { itemModal.subcategory = itemModal?.subcategory?.id }

                                if (!checkParameters([{ value: "name", label: "Ürün İsmi" }, { value: "category", label: "Kategori" }, { value: "price", label: "Fiyat" }], itemModal)) { return; }

                                createMenuItemService(itemModal).then((res) => {
                                    if (res.data && !res.data.errors) {

                                        toast.success("Ürün Oluşturuldu.");

                                        setItemModal(null);
                                        setPortion(null);
                                        loadMenuCategories(false, true);
                                    }
                                })
                            }
                        }}
                    >
                        {itemModal?.id ? "Ürünü Güncelle" : "Ürün Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={!!updateBulkPriceModal}
                toggle={() => {
                    setUpdateBulkPriceModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setUpdateBulkPriceModal(false);
                }}>
                    Toplu Fiyat Güncelle
                </ModalHeader>
                <ModalBody>
                    <Row gutter={[12, 12]}>
                        <Col xs={width <= 450 ? 24 : 12} md={width <= 450 ? 24 : 12}>
                            <Label>Şu Kategoriye Taşı</Label>
                            <Select
                                styles={ReactSelectStyle}
                                placeholder="Kategori"
                                options={[
                                    ...menuCategories?.map((category) => { return { label: category?.name, value: category?.id } })
                                ]}
                                onChange={(e) => setUpdateBulkPriceModal({ ...updateBulkPriceModal, category: e.value })}
                            />
                        </Col>
                        <Col xs={width <= 450 ? 24 : 12} md={width <= 450 ? 24 : 12}>
                            <Label>Şu Alt Kategoriye Taşı  {!menuCategories.filter((plt) => plt.id == updateBulkPriceModal?.category)[0]?.subcategory ? <Tooltip title={"Kategorinin 'Alt Kategori' özelliği aktif değil!"} ><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip> : <></>}</Label>
                            <Select
                                styles={ReactSelectStyle}
                                placeholder="Alt Kategori"
                                isDisabled={!menuCategories.filter((plt) => plt.id == updateBulkPriceModal?.category)[0]?.subcategory}
                                options={[
                                    ...subCategories?.filter((subCategory) => subCategory?.category == updateBulkPriceModal?.category)?.map((subCategory) => { return { label: subCategory?.name, value: subCategory?.id } })
                                ]}
                                onChange={(e) => setUpdateBulkPriceModal({ ...updateBulkPriceModal, subcategory: e.value })}
                            />
                        </Col>
                        <Col xs={width <= 450 ? 24 : 12} md={width <= 450 ? 24 : 12}>
                            <Label>Fiyatı Güncelle</Label>
                            <Select
                                styles={ReactSelectStyle}
                                placeholder="Aksiyon Seçiniz"
                                options={[
                                    { label: "% Arttır", value: "increase_percentage" },
                                    { label: "% Düşür", value: "decrease_percentage" },
                                    { label: "+ Arttır", value: "increase_amount" },
                                    { label: "- Düşür", value: "decrease_amount" },
                                ]}
                                onChange={(e) => setUpdateBulkPriceModal({ ...updateBulkPriceModal, type: e.value })}
                            />
                        </Col>
                        <Col xs={width <= 450 ? 24 : 12} md={width <= 450 ? 24 : 12}>
                            <Label className="opacity-0">Fiyatı Güncelle</Label>
                            <Input
                                size="large"
                                placeholder="Tutar"
                                type="number"
                                onChange={(e) => setUpdateBulkPriceModal({ ...updateBulkPriceModal, amount: Number(e.target.value) })}
                                prefix={updateBulkPriceModal.type ? updateBulkPriceModal?.type?.startsWith("increase_") ? <PlusCircleOutlined style={{}} /> : <MinusCircleOutlined style={{}} /> : null}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <Label>KDV Güncelle</Label>
                        </Col>
                        <Col xs={width <= 450 ? 24 : 12} md={width <= 450 ? 24 : 12}>
                            <Input
                                size="large"
                                placeholder="KDV"
                                type="number"
                                onChange={(e) => setUpdateBulkPriceModal({ ...updateBulkPriceModal, tax: Number(e.target.value) })}
                                suffix={<PercentageOutlined />}
                            />
                        </Col>

                        <Col xs={24} md={24} style={{ marginTop: "5px" }}><hr /></Col>

                        <Col xs={24} md={24}>
                            <Label>Ayalar</Label>
                        </Col>
                        <Col xs={24} md={24}>
                            <span>
                                <Switch defaultChecked={updateBulkPriceModal?.digitalMenu} onChange={(value) => { console.log(value); setUpdateBulkPriceModal({ ...updateBulkPriceModal, digitalMenu: value }) }} /> Dijital Menüde göster
                                <Tooltip title={"Bilgi"}><QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "16px" }} /></Tooltip>
                            </span>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { setUpdateBulkPriceMode(false); setUpdateBulkPriceModal(false) }} style={{ backgroundColor: "#fff", borderColor: "#c72328", color: "#000" }}>İptal</Button>
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            var data = { ...updateBulkPriceModal };
                            var menuItems = [];

                            for (let category of menuCategories) {
                                for (let item of category.items) {
                                    if (item.selected) {
                                        menuItems?.push(item.id);
                                    }
                                }
                            }
                            console.log("menuItems", menuItems);
                            if (menuItems.length == 0) { toast.error("Lütfen en az bir ürün seçiniz."); return; }

                            updateBulkPriceService(data, menuItems).then((res) => {
                                if (res.data && !res.data.errors) {

                                    toast.success("Ürünler güncellendi.");

                                    setUpdateBulkPriceModal(false);
                                    setUpdateBulkPriceMode(false);
                                    loadMenuCategories(false, true);
                                }
                            })

                        }}
                    >
                        Güncelle
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default MenuManagement;

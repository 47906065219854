
import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Alert,
  CardBody,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { 
  Row,
  Col,
  Input,
  Button,
} from "antd";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import { createSelector } from 'reselect';
import { editBrandUserService } from "../../services/brand-user";
import { toast } from "react-toastify";

const UserProfile = () => {
  document.title = "Profil | Sipyo";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [phoneNumber, setphonenumber] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  const userprofilepage = createSelector(
    (state ) => state.profile,
    (state) => ({
        error: state.error,
        success: state.success,
    })
  );
// Inside your component
const { error, success } = useSelector(userprofilepage);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.fullname);
        setemail(obj.email);
        setphonenumber(obj.phoneNumber);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: name || "",
      email: email || "",
      phoneNumber: phoneNumber || "",
      password: "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Lütfen Adınızı giriniz!"),
      email: Yup.string().required("Lütfen E-Postanızı giriniz!"),
      phoneNumber: Yup.string().required("Lütfen Telefon Numaranızı giriniz!"),
      password: Yup.string(),
    }),
    onSubmit: (values) => {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      values.brandUserId = obj.id;
      delete values.idx;
      console.log(values);

      //dispatch(editProfile(values));
      editBrandUserService(values).then(() => {
        toast.success("Kullanıcı bilgileri başarıyla güncellendi!");

        let newAuthUser = {
          ...obj,
          fullname: values.fullname,
          email: values.email,
          phoneNumber: values.phoneNumber,
        };

        localStorage.setItem("authUser", JSON.stringify(newAuthUser));
      })
    },
  });

  return (
    <React.Fragment>
        <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`}>
          <Container fluid>
            <Breadcrumb title="Sipyo" breadcrumbItem="Profil" />

            <Row gutter={[24,24]}>
              <Col lg={24}>
                {error && error ? (
                  <Alert color="danger">
                    <div>{error}</div>
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success">
                    <div>{success}</div>
                  </Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      {/*<div className="ms-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>*/}
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{name}</h5>
                          <p className="mb-1">{email}</p>
                          <p className="mb-0">Id no: #{idx}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row gutter={[24,24]}>
                    <Col sm={24} lg={12}>
                      <div className="form-group">
                        <Label className="form-label">Kullanıcı Adı</Label>
                        <Input
                          name="fullname"
                          className=""
                          size="large"
                          placeholder="Kullanıcı Adı"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fullname || ""}
                          invalid={
                            validation.touched.fullname &&
                            validation.errors.fullname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.fullname &&
                        validation.errors.fullname ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.fullname}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={24} lg={12}>
                      <div className="form-group">
                        <Label className="form-label">E-Posta</Label>
                        <Input
                          name="email"
                          className=""
                          size="large"
                          placeholder="E-Posta"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                            validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                        validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={24} lg={12}>
                      <div className="form-group">
                        <Label className="form-label">Telefon Numarası</Label>
                        <Input
                          name="phoneNumber"
                          className=""
                          size="large"
                          placeholder="Telefon Numarası"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNumber || ""}
                          invalid={
                            validation.touched.phoneNumber &&
                            validation.errors.phoneNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.phoneNumber}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={24} lg={12}>
                      <div className="form-group">
                        <Label className="form-label">Yeni Şifre</Label>
                        <Input.Password
                          name="password"
                          className=""
                          size="large"
                          placeholder="Yeni Şifre"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.password}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center text-center mt-4">
                    <Button htmlType="submit" type="primary" size="large">
                      Kullanıcı Bilgilerini Güncelle
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);

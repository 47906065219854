import axios from "axios";
import { toast } from "react-toastify";
const LIVE_URL = process.env.REACT_APP_LIVE_URL;

const apiAxios = axios.create({
  baseURL: LIVE_URL,
});

apiAxios.interceptors.response.use(
  (response) => {
    if (response.data.errors) {
      if(response?.data?.errors[0]?.message?.search("Lütfen Yenileyiniz!") > -1) {
        toast.error("Üyeliğiniz sona ermiştir. Lütfen üyeliğinizi yenileyin.");
        
        setTimeout(() => {
          window.location = "/subscription-info";
        }, 2000)
        return;
      }

      toast.error(response?.data?.errors[0]?.message)
    }

    if (Object.keys(response?.data?.data).find((plt) => plt == "verifyToken") && response?.data?.data?.verifyToken?.verified !== true) {
      
      toast.error("Lütfen tekrar giriş yapınız!");

      setTimeout(() => {
        window.location = "/login";
      }, 2000)
    }

    return response
  },
  (error) => {
    if (error?.response?.data?.errors) {
      if (Array.isArray(error?.response?.data?.error))
        toast.error(error.response.data.error?.join(",\n"));
      else toast.error(error.response.data.errors[0]);
    } else toast.error("Something went wrong.");
    let unauthorized =
      error.response &&
      error.response.status &&
      (error.response.status == 401 || error.response.status == 403);
    if (unauthorized && !window.location.pathname.includes("/auth"))
      setTimeout(() => {
        localStorage.setItem("jwt", undefined);
        window.location.replace(window.location.origin + "/login")
      }, 1000);
    return Promise.reject(error);
  }
);

apiAxios.interceptors.request.use((req) => {
  const user = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : {};
  if (user.token) req.headers.Authorization = `Bearer ${user.token}`;
  return req;
});

export default apiAxios;
var place = JSON.parse(localStorage.getItem('authUser'))?.place;

let SidebarData = [
    /*{
        label: "Genel",
        isMainMenu: true,
    },*/
    {
        label: "Ana Sayfa",
        icon: require('../../assets/images/Sidebar/dashboard.svg').default,
        url: "/dashboard",
        issubMenubadge: true,
    },
];

if (place?.expireOrderSystem) {
    SidebarData.push({
        label: "Siparişler",
        icon: require('../../assets/images/Sidebar/orders.svg').default,
        url: "/orders",
        issubMenubadge: true,
    });
}

if (place?.expireDigitalMenu) {
    SidebarData.push({
        label: "Dijital Menü",
        icon: require('../../assets/images/Sidebar/digitalmenu.svg').default,
        url: "/digital-menu-settings",
        issubMenubadge: true,
    });
}


if (place?.expireDigitalMenu || place?.expireOrderSystem) {
    SidebarData.push({
        label: "Yapılandırma",
        icon: require('../../assets/images/Sidebar/configuration.svg').default,
        subItem: [],
    },);

    let configurationIndex = SidebarData.findIndex(x => x.label === "Yapılandırma");
    
    SidebarData[configurationIndex].subItem.push({
        sublabel: "Ürün Yönetimi",
        //icon: "mdi mdi-home-variant-outline",
        link: "/menu-management",
        issubMenubadge: true,
    });
    
    SidebarData[configurationIndex].subItem.push({
        sublabel: "Masa Yönetimi",
        link: "/table-management",
    });
}

if (place?.expireDigitalMenu) { // ! Geçmiş kontrolü yap, eğer geçmişte bile adisyonu varsa göster
    SidebarData.push({
        label: "Raporlar",
        icon: require('../../assets/images/Sidebar/reports.svg').default,
        subItem: [
            {
                sublabel: "Günlük Raporlar",
                link: "/daily-reports",
                issubMenubadge: true,
            },
            {
                sublabel: "Raporlar",
                link: "/reports",
                issubMenubadge: true,
            }
        ],
    });
}

SidebarData.push({
    label: "Stok Takip",
    icon: require('../../assets/images/Sidebar/stocktrack.svg').default,
    issubMenubadge: true,
    bgcolorhex: "#c72328",
    badgeValue: "Yakında"
});

SidebarData.push({
    label: "Ayarlar",
    icon: require('../../assets/images/Sidebar/settings.svg').default,
    subItem: [
        {
            sublabel: "Kullanıcı Yönetimi",
            //icon: "mdi mdi-home-variant-outline",
            link: "/user-management",
            issubMenubadge: true,
        },
        {
            sublabel: "Abonelik Bilgileri",
            //icon: "mdi mdi-home-variant-outline",
            link: "/subscription-info",
            issubMenubadge: true,
        },
        {
            sublabel: "Ayarlar",
            //icon: "mdi mdi-home-variant-outline",
            link: "/settings",
            issubMenubadge: true,
        }
    ],
},);

//expireDigitalMenu
//expireOrderSystem

export default SidebarData;
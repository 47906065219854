import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadReportsService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{getSaleReports${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                name
                portion
                totalItem
                totalPayment
                percentage

                code
                price
                category
                menuItemCountInOrders

                orderTotalPrice
                totalOrder
            }}
            `,
    }, {
        headers: {
            
        }
    });

}

import React, { useRef, useState } from "react";
import { Button, Checkbox, Col, Divider, Input, Row, Switch, Tooltip } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretUpOutlined, CopyFilled, CopyOutlined, DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PercentageOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, QuestionCircleFilled, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button as ReactStrapButton, Collapse } from "reactstrap";
import DataTable from "react-data-table-component";
import { PlaceColumns } from './columns'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { createPlaceService, loadPlaceDetailsService, updatePlaceService } from "../../services/places";
import { createMenuCategoryService, deleteMenuCategoryService, loadMenuCategoriesService, updateMenuCategoryService } from "../../services/menuCategory";
import { uploadFileService } from "../../services/file";
import { createMenuSubCategoryService, deleteMenuSubCategoryService, updateMenuSubCategoryService } from "../../services/menuSubCategory";
import TextArea from "antd/es/input/TextArea";
import { createMenuItemService, deleteMenuItemService, updateBulkPriceService, updateMenuItemService } from "../../services/menuItem";
import { confirm } from "../../components/confirm";
import ReactSelectStyle from "../../style/react-select-style";

import { loadTabsService } from "../../services/tables";
import QRCodesPDFFile from "../QRCodePDF";

// ! Custom Icons
import TemplateIcon from "../../assets/images/DigitalMenuSettings/template-icon.svg";
import DownloadIcon from "../../assets/images/DigitalMenuSettings/download-icon.svg";
import QRCodeImage from "../../assets/images/DigitalMenuSettings/sipyo-qr.png";

// ! Templates
import VintageTemplate1 from "../../assets/images/DigitalMenuSettings/template/1.png";
import VintageTemplate2 from "../../assets/images/DigitalMenuSettings/template/2.png";
import VintageTemplate3 from "../../assets/images/DigitalMenuSettings/template/3.png";
import VintageTemplate4 from "../../assets/images/DigitalMenuSettings/template/4.png";
import VintageTemplate5 from "../../assets/images/DigitalMenuSettings/template/5.png";

import { PDFDownloadLink, PDFViewer, Document, Page, Text, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
        fontFamily: "Gilroy",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "center",
        fontFamily: "Gilroy",

    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
        width: 300,
        height: 300
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
        fontFamily: "Gilroy",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        fontFamily: "Gilroy",
    },
});

const DigitalMenuSettings = () => {
    document.title = "Dijital Menü Ayarları | Sipyo";

    const vintageTemplates = [VintageTemplate1, VintageTemplate2, VintageTemplate3, VintageTemplate4, VintageTemplate5];

    const [data, setData] = useState({ digitalMenu: false, logo: "", themeColor: "#C72328" });
    const [tabs, setTabs] = useState(null);
    const logoImageRef = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [downloadModal, setDownloadModal] = useState(false);

    useEffect(() => {
        loadTables();
        loadPlace();

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const loadTables = async () => {
        let place = localStorage.getItem("place");
        await loadTabsService({ place: place })
            .then((data) => {
                data = data.data.data.areas;
                console.log(data);
                setTabs(data);
            })
            .catch((e) => { 
                console.log(e);
            })
    }

    const loadPlace = async () => {
        let place = localStorage.getItem("place");
        await loadPlaceDetailsService({ id: place })
            .then((data) => {
                data = data.data.data.places[0];
                console.log(data);
                setData(data);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    display: width <= 575 ? "flex" : "",
                    flexDirection: width <= 575 ? "row" : "",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#3a3a3a",
                    height: width <= 575 ? "175px" : width <= 580 ? "90px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} align="middle" style={{ height: "90px" }} className="w-100">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ height: width <= 575 ? "" : "55px" }}>
                            <div style={{ display: "flex", flexDirection: width <= 575 ? "column" : "row", float: width <= 575 ? "" : "right" }}>
                                <Button
                                    onClick={() => {
                                        window.open(`https://menu.sipyo.com.tr/${localStorage.getItem("place")}`, "_blank");
                                    }}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                    size="large"
                                    icon={<EyeOutlined style={{ fontSize: "27px" }} />}
                                    className=" d-flex justify-content-center align-items-center "
                                >
                                    Menüyü Önizle
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row style={{ /*display: "grid", gridTemplateColumns: "40% 60%"*/ backgroundColor: "#ffffff", margin: 0 }} gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                        <div className=" p-3 w-100 h-100 ">
                            <h1 className=" sipyo-title ">Ayarlar</h1>

                            <div className=" d-flex flex-row align-items-center " style={{ marginTop: "47px" }}>
                                <Tooltip title={"Dijital menünüzü görünür yapabilir ve pasif duruma getirebilirsiniz."} className="me-2">
                                    <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                </Tooltip>

                                <h2 className=" fs-5 ">Dijital Menü Durumu: </h2>
                                <div className="rounded d-flex justify-content-center align-items-center mx-3" style={{ minWidth: "100px", height: "26px", backgroundColor: "#44C723" }}>
                                    <span className=" fw-semibold text-white " style={{ fontSize: "19px" }}>
                                        Aktif
                                    </span>
                                </div>
                                <Switch checked={data.digitalMenu} onChange={(checked) => setData({ ...data, digitalMenu: checked })} />
                            </div>

                            <div className=" d-flex flex-row align-items-start " style={{ marginTop: "47px" }}>
                                <Tooltip title={"Dijital menünüzün üst kısmında işletmenizin logosu görünür."} className="me-2">
                                    <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                </Tooltip>

                                <h2 className=" fs-5 ">Logo: </h2>

                                <div className=" d-flex flex-column justify-content-center align-items-center ">
                                    <div className=" mx-2 d-flex justify-content-center align-items-center rounded " style={{ backgroundColor: "#D9D9D9", minWidth: "220px", minHeight: "80px" }}>
                                        <img src={data.logo ? `https://api.sipyo.com.tr${data.logo}` : DownloadIcon} style={{ maxHeight: "80px" }} />
                                    </div>
                                    <h2 className=" fw-medium mt-3 " style={{ fontSize: "17px", color: "#9C9C9C" }}>Önerilen Boyut: 800x550px</h2>
                                    <Button type="primary" className="mt-3" onClick={() => logoImageRef?.current?.click()}>Logo Yükle</Button>

                                    <input
                                        ref={logoImageRef}
                                        type="file"
                                        onChange={async (e) => {
                                            if (e.target.files.length > 0) {
                                                uploadFileService(e.target.files[0])
                                                    .then((res) => res.json())
                                                    .then((response) => {
                                                        console.log(response);
                                                        var newData = { ...data, logo: response.path };
                                                        console.log(newData)
                                                        setData(newData)
                                                    });
                                            }
                                        }}
                                        name="photo"
                                        id="upload-photo"
                                        style={{ display: "none" }}
                                    />

                                </div>
                            </div>

                            <div className=" d-flex flex-row align-items-center " style={{ marginTop: "47px" }}>
                                <Tooltip title={"Butonlar, kategoriler, fiyatlar ve tema rengini değiştirir."} className="me-2">
                                    <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                </Tooltip>

                                <h2 className=" fs-5 ">Tema Rengi: </h2>
                                <div className=" d-flex flex-row w-100 " >
                                    <Input size="large" type="text" onChange={(e) => { setData({ ...data, themeColor: e.target.value }) }} value={data.themeColor} style={{ width: "100px", borderRight: "0px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", minHeight: "33px", maxHeight: "33px", height: "33px", borderColor: "transparent", boxShadow: "none" }} />
                                    <Input size="large" type="color" onChange={(e) => { setData({ ...data, themeColor: e.target.value }) }} value={data.themeColor} style={{ width: "50px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", borderLeft: "0px", minHeight: "33px", maxHeight: "33px", height: "33px", borderColor: "transparent", boxShadow: "none" }} />

                                </div>
                            </div>

                            <div style={{ marginTop: "47px" }}>
                                <div className=" d-flex flex-row my-2 align-items-center ">
                                    <Tooltip title={"Dijital menünüze \"GARSON ÇAĞIR\" butonu ekler. Bu buton, tüm garsonlara bildirim gönderir."} className="me-2">
                                        <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                    </Tooltip>

                                    <h2 className=" fs-5 ">Garson Çağır: </h2>
                                    <Switch className="mx-3" defaultChecked disabled />
                                    <div className="rounded d-flex justify-content-center align-items-center my-2" style={{ minWidth: "100px", height: "26px", backgroundColor: "#C72328" }}>
                                        <span className=" fw-medium text-white " style={{ fontSize: "18px" }}>
                                            Yakında
                                        </span>
                                    </div>
                                </div>

                                <div className=" d-flex flex-row my-2 align-items-center ">
                                    <Tooltip title={"Dijital menü üzerinden sipariş alınabilir."} className="me-2">
                                        <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                    </Tooltip>

                                    <h2 className=" fs-5 ">Sipariş Girişi: </h2>
                                    <Switch className="mx-3" defaultChecked disabled />
                                    <div className="rounded d-flex justify-content-center align-items-center my-2" style={{ minWidth: "100px", height: "26px", backgroundColor: "#C72328" }}>
                                        <span className=" fw-medium text-white " style={{ fontSize: "18px" }}>
                                            Yakında
                                        </span>
                                    </div>
                                </div>

                                <div className=" d-flex flex-row my-2 align-items-center ">
                                    <Tooltip title={"Dijital menü üzerinden oluşturulan siparişler yazar kasadan adisyon olarak otomatik çıkar."} className="me-2">
                                        <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                    </Tooltip>

                                    <h2 className=" fs-5 ">Sipariş Otomatik Çıksın: </h2>
                                    <Switch className="mx-3" defaultChecked disabled />
                                    <div className="rounded d-flex justify-content-center align-items-center my-2" style={{ minWidth: "100px", height: "26px", backgroundColor: "#C72328" }}>
                                        <span className=" fw-medium text-white " style={{ fontSize: "18px" }}>
                                            Yakında
                                        </span>
                                    </div>
                                </div>

                                <div className=" d-flex flex-row my-2 align-items-center ">
                                    <Tooltip title={"Dijital menü üzerinden alınan siparişler otomatik onaylanır."} className="me-2">
                                        <QuestionCircleOutlined style={{ color: "#969696", fontSize: "19px" }} />
                                    </Tooltip>

                                    <h2 className=" fs-5 ">Sipariş Otomatik Onaylansın: </h2>
                                    <Switch className="mx-3" defaultChecked disabled />
                                    <div className="rounded d-flex justify-content-center align-items-center my-2" style={{ minWidth: "100px", height: "26px", backgroundColor: "#C72328" }}>
                                        <span className=" fw-medium text-white " style={{ fontSize: "18px" }}>
                                            Yakında
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Button style={{ marginTop: "47px" }} type="primary" onClick={() => {
                                let place = localStorage.getItem("place");

                                delete data.id;
                                updatePlaceService({ placeId: place, ...data })
                                    .then((res) => {
                                        let newAuth = localStorage.getItem("authUser");
                                        newAuth = JSON.parse(newAuth);
                                        toast.success("Ayarlar başarıyla güncellendi.");
                                        newAuth.place = { ...newAuth.place, ...data };
                                        localStorage.setItem("authUser", JSON.stringify(newAuth));
                                    })
                                    .catch((err) => {
                                        toast.error("Ayarlar güncellenirken bir hata oluştu.");
                                    });

                            }}>
                                Kaydet
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                        <Col className=" p-3 w-100 h-100 sipyo-border-left ">
                            <div className=" mt-2 mb-4 d-flex flex-row align-items-center text-center  ">
                                <h1 className="me-2 sipyo-title">QR Kod Üret</h1>
                                <Tooltip title={"Masalarınıza QR Kod üretir."} className="me-2">
                                    <QuestionCircleOutlined style={{ fontSize: "24px", color: "#969696" }} />
                                </Tooltip>
                            </div>

                            {/*<PDFDownloadLink document={<QRCodesPDFFile tables={[
                                {
                                    "id": "6611a4518f01aca3df51bfc2",
                                    "name": "Masa 1",
                                    "queue": 2,
                                    "area": {
                                        "id": "660415ba56424552aafaa05c"
                                    },
                                    "order": null
                                },
                                {
                                    "id": "6611a4518f01aca3df51bfc4",
                                    "name": "Masa 2",
                                    "queue": 2,
                                    "area": {
                                        "id": "660415ba56424552aafaa05c"
                                    },
                                    "order": null
                                }
                            ]} />} filename="FORM">
                                {({ loading }) => (<Button type="primary" disabled={loading}>{loading ? "Yükleniyor..." : "İndir"}</Button>)}
                            </PDFDownloadLink>*/}

                            <Row gutter={[24, 24]}>
                                {
                                    tabs && tabs.length > 0 && tabs.map((tab, index) => {
                                        return (
                                            <>
                                                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8} key={index}>
                                                    <div style={{
                                                        
                                                    }} className=" sipyo-border rounded d-flex flex-column align-items-center ">
                                                        <h2 className=" my-2 mt-4 text-2 ">{tab?.name}</h2>
                                                        <h2 className=" my-2 fs-5" style={{ color: "#9C9C9C" }}>{tab?.tables?.length} Adet Masa</h2>

                                                        <Button size="large" type="primary" className="mb-4 mt-2" style={{ minWidth: "130px" }} onClick={() => {
                                                            setDownloadModal(tab);
                                                        }}>
                                                            İndir
                                                        </Button>

                                                    </div>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>

                            <Modal isOpen={downloadModal} toggle={() => setDownloadModal(!downloadModal)} size="lg">
                                <ModalHeader className="sipyo-title">
                                    QR Kod Oluştur
                                </ModalHeader>
                                <ModalBody>
                                    <Row gutter={[24, 24]}>
                                        <h2 className=" ms-3 sipyo-alttitle">Standart</h2>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <img
                                                src={QRCodeImage}
                                                style={{ width: "128px", height: "128px" }}
                                                className=" border border-black p-2"
                                                onClick={() => setDownloadModal({ ...downloadModal, template: "no-template" })}
                                            />
                                        </Col>

                                        <h2 className=" ms-3 sipyo-alttitle">Vintage</h2>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <div className=" d-flex flex-row justify-content-between ">
                                                {
                                                    vintageTemplates.map((templateAsset, index) => (
                                                        <>
                                                            <img
                                                                src={templateAsset}
                                                                style={{ maxHeight: "186px", border: "2px solid", borderColor: downloadModal?.template == index ? "#C72328" : "black" }}
                                                                className={`rounded p-1`}
                                                                onClick={() => setDownloadModal({ ...downloadModal, template: index, backgroundImage: templateAsset })}
                                                            />
                                                        </>
                                                    ))
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button size="large" style={{ minWidth: "150px" }} onClick={() => {
                                        setDownloadModal(false);
                                    }}>
                                        İptal
                                    </Button>
                                    <PDFDownloadLink document={<QRCodesPDFFile tab={downloadModal} tables={downloadModal.tables} placeId={data.id} />} filename={`qrlistesi.pdf`}>
                                        {({ loading }) => (
                                            <Button onClick={() => { window.location.reload(); }} size="large" style={{ minWidth: "150px" }} type="primary" disabled={loading}>{loading ? "Loading..." : "İndir"}</Button>
                                        )}
                                    </PDFDownloadLink>
                                </ModalFooter>
                            </Modal>

                        </Col>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default DigitalMenuSettings;
import { Button } from "antd";
import React, { useState } from "react";
const TrialAlert = () => {
    let place = JSON.parse(localStorage.getItem("authUser"))?.place;
    let leftDays = Math.floor((new Date(parseInt(place.expireOrderSystem)) - new Date()) / (1000 * 60 * 60 * 24));
    let trial = place.trial;
    
    return (
        <React.Fragment>
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ maxHeight: "50px", padding: "0px" }}>
                <div className={`w-100 py-1 px-2 alert alert-dismissible fade show mb-0 d-flex flex-row justify-content-center align-items-center`} role="alert" style={{ borderRadius: "0px", background: leftDays <= 5 ? "#FFC8C4" : "#FFF2C4" }}>
                    <i className="mdi mdi-alert-circle alert-icon me-2" style={{ color: leftDays <= 5 ? "#C72328" : "#FF9A24", fontSize: "24px" } }/>
                    <div className="d-flex flex-row align-items-center">
                        <span>
                            {
                                leftDays < 0 ?
                                    `${trial ? "Deneme sürümünüz" : "Aboneliğiniz"} ${-leftDays} gün önce sona erdi.`
                                :
                                    `${trial ? "Deneme sürümünüzün" : "Aboneliğinizin"} sona ermesine {leftDays} gün kaldı.`
                            }
                        </span>
                        <Button onClick={() => window.location = "/plans"} type="primary" className="ms-2 px-4" size="large"> Yükselt </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TrialAlert;
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { toast } from "react-toastify";
import { deletePlaceService } from "../../../services/places";
import { confirm } from "../../../components/confirm";

var formatInt = (value) => {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
}

export const ItemColumns = () => {
    return [
        {
            name: <span className="fs-5">Ürün Kodu</span>,
            selector: (row) => row?.code || "-",
            sortable: true,
            sortFunction: (a, b) => a.code - b.code,
        },
        {
            name: <span className="fs-5">Ürün Adı</span>,
            selector: (row) => row.bold ? <b>{row.name}{row?.portion && (row?.portion !== row?.name) && <span>({row?.portion})</span>}</b> : <span className=" paragraph-2 ">{row.name}{row?.portion && (row?.portion !== row?.name) && <span>({row?.portion})</span>}</span>,
            sortable: true,
            sortFunction: (a, b) => a.name - b.name,
        },
        {
            name: <span className="fs-5">Miktar</span>,
            selector: (row) => row.bold ? <b>{row.menuItemCountInOrders}</b> : <span className=" paragraph-2 ">{row.menuItemCountInOrders}</span>,
            sortable: true,
            sortFunction: (a, b) => a.menuItemCountInOrders - b.menuItemCountInOrders,
        },
        {
            name: <span className="fs-5">Kategori</span>,
            selector: (row) => row.bold ? <b>{row.category != null && row.category != undefined ? formatInt(row.category) : "-"}</b> : <span className=" paragraph-2 ">{row.category != null && row.category != undefined ? formatInt(row.category) : "-"}</span>,
            sortable: true,
            sortFunction: (a, b) => a.category - b.category,
        },
        {
            name: <span className="fs-5">Birim Fiyatı (<span className="fw-light">₺</span>)</span>,
            selector: (row) => row.bold ? <b>{row.price != null && row.price != undefined ? formatInt(row.price?.toFixed(2)) : 0}</b> : <span className=" paragraph-2 ">{row.price != null && row.price != undefined ? formatInt(row.price?.toFixed(2)) : 0}</span>,
            sortable: true,
            sortFunction: (a, b) => a.price - b.price,
        },
        {
            name: <span className="fs-5">Tutar (<span className="fw-light">₺</span>)</span>,
            selector: (row) => row.bold ? <b>{row.totalPayment}</b> : <span className=" paragraph-2 ">{row.totalPayment}</span>,
            sortable: true,
            sortFunction: (a, b) => (formatInt((a.totalPayment * a.totalPayment))) - (formatInt((b.totalPayment * b.totalPayment))),
        },
        {
            name: <span className="fs-5">Oran (%)</span>,
            selector: (row) => row.bold ? <b>{row?.percentage + "%" || "Bulunamadı"}</b> : <span className=" paragraph-2 ">{row?.percentage + "%" || "Bulunamadı"}</span>,
            sortable: true,
            sortFunction: (a, b) => a.percentage - b.percentage,
        },
    ];
};
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CardBody, Card, Alert, Container, Form, FormFeedback, Label } from "reactstrap";
import { Row, Col, Button, Input } from "antd";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { createSelector } from 'reselect';
import AuthenticationHeader from "./AuthenticationHeader";
import { forgotPasswordService } from "../../services/auth";
import { toast } from "react-toastify";

const ForgotPassword = props => {
  document.title = "Şifremi Unuttum | Sipyo";

  const dispatch = useDispatch();
  var [height, setHeight] = React.useState(window.innerHeight);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Lütfen E-Postanızı giriniz"),
    }),
    onSubmit: async (values) => {
      console.log(values);

      const data = await forgotPasswordService(values.email);
      console.log(data);

      if (!data.data.errors) {
        toast.success("E-Posta başarıyla gönderildi!");

        validation.resetForm();
        window.location.href = "/login";
      }
    }
  });

  const loginpage = createSelector(
    (state) => state.login,
    (state) => ({
      error: state.error,
    })
  );
  // Inside your component
  const { error } = useSelector(loginpage);

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.className = "bg-e9ecef";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <AuthenticationHeader />

      <div style={{ height: height - 100 }}>
        <Row style={{ height: height - 100 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bg-white d-flex justify-content-center align-items-center" style={{ zIndex: "10" }}>
            <Container className="text-center">
              <h1 className="fw-bold fs-2">Şifremi Unuttum!</h1>
              <h1 className="fw-medium fs-5 my-2" style={{ color: "#6D6D6D" }}>Şifreni mi unuttun? Şimdi şifre sıfırlama linki alın!</h1>
              <div className="p-4">
                <div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                    <Row>
                      <Col xs={24} sm={24} md={24}>
                        <div className="mb-4">
                          <Input
                            style={{ height: "50px" }}
                            name="email"
                            className=""
                            placeholder="E-Posta"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                          ) : null}
                        </div>
                        <div className="d-grid mt-4">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            style={{ fontWeight: "550" }}
                          >
                            Şire Sıfırlama Linki Gönder
                          </Button>
                        </div>
                        <div className="mt-4 text-center">
                        </div>

                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

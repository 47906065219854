import { io } from "socket.io-client";

class SocketService {
    constructor() {
        if (!SocketService.instance) {
            const user = JSON.parse(localStorage.getItem("authUser"));
            const token = user ? user.token : null;
            this._socket = io(process.env.REACT_APP_LIVE_URL, {
                autoConnect: true,
                query: {
                    Authorization: token
                },
                transports: ['websocket']

            });
            SocketService.instance = this;
        }

        return SocketService.instance;
    }

    connect(url) {
        console.log("connection...")
        const user = JSON.parse(localStorage.getItem("authUser"));
        const token = user ? user.token : null;

        if (!token) {
            console.error('No token found. Please ensure "authUser" is set in localStorage and has a "token" property.');
            return;
        }

        if (!url) {
            console.error('No URL provided. Please ensure you are passing a valid URL to the connect function.');
            return;
        }

        try {
            this._socket = io(process.env.REACT_APP_LIVE_URL, {
                autoConnect: true,
                query: {
                    Authorization: token
                },
                transports: ['websocket']

            });

            this._socket.on('connect', () => {
                console.log('Successfully connected to the socket server');
                return true;
            });

            this._socket.on('connect_error', (error) => {
                console.log('Connection Error:', error);
            });

            this._socket.on('connect_timeout', () => {
                console.log('Connection Timeout');
            });

        } catch (error) {
            console.error('Failed to establish socket connection:', error);
            return;
        }
    }

    disconnect() {
        if (this._socket) {
            this._socket.disconnect();
        }
    }

    on(event, callback) {
        if (this._socket) {
            this._socket.on(event, callback);
        }
    }

    emit(event, data) {
        if (this._socket) {
            this._socket.emit(event, data);
        }
    }
}

const instance = new SocketService();

export default instance;
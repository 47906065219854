import React, { useEffect } from "react";
import Routes from "./Routes/index";
import SocketService from './services/SocketService';

// ! Toast Messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { verifyTokenService } from "./services/auth";
import { ConfigProvider } from "antd";
import locale from 'antd/locale/tr_TR';
import dayjs from 'dayjs';

import 'dayjs/locale/tr';

dayjs.locale('tr');


// Activating fake backend
fakeBackend();

// Firebase
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)


function App() {
  useEffect(() => {
    if (
      window.location.pathname !== "/login" 
      && window.location.pathname !== "/forgot-password" 
      && !window.location.pathname?.startsWith("/reset-password")
      && window.location.pathname !== "/register"
      && window.location.pathname !== "/plans"
      && window.location.pathname !== "/purchase"
      && window.location.pathname !== "/purchase/success"
      && window.location.pathname !== "/purchase/failed"
    ) {
      verifyTokenService()
    }

    connectSocket();
  }, [])

  const connectSocket = async () => {
    try {
      console.log(await SocketService.connect(process.env.REACT_APP_LIVE_URL));
    } catch (error) {
      console.error('Error connecting socket:', error);
    }
  }

  return (
    <React.Fragment>
      <ConfigProvider
        locale={locale}
        wave={{ disabled: true, showEffect: false }}
        theme={{
          token: {
            colorPrimary: '#c72328'
          },
        }}
      >
        <ToastContainer limit={1} />

        <Routes />

      </ConfigProvider>
    </React.Fragment>
  );
}

export default App;

import React, { useRef, useState } from "react";
import { Button, Checkbox, Col, Input, QRCode, Row, Switch, Tooltip } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretUpOutlined, CopyFilled, CopyOutlined, DeleteOutlined, MinusOutlined, MoreOutlined, PercentageOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, QrcodeOutlined, QuestionCircleFilled, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button as ReactStrapButton, Collapse } from "reactstrap";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { QRCode as QRCodeLogo } from 'react-qrcode-logo';
import { confirm } from "../../components/confirm";
import { createAreaService, deleteAreaService, loadAreasService, updateAreaService } from "../../services/areas";
import { createMultiTablesService, createTableService, deleteTableService, deleteTablesService, updateTableService } from "../../services/tables";
import ReactSelectStyle from "../../style/react-select-style";
import DeleteFilledSrc from '../../assets/images/TableManagment/DeleteFilled.svg';

const DeleteFilled = ({ width }) => (<img src={DeleteFilledSrc} style={{ width: width }} />);

const TableManagement = () => {
    document.title = "Masa Yönetimi | Sipyo";

    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [findedTables, setFindedTables] = useState(null);
    const [selectMode, setSelectMode] = useState(false);
    const [areas, setAreas] = useState([]);
    const [tables, setTables] = useState([]);
    const [tablesIndex, setTablesIndex] = useState({});
    const [filter, setFilter] = useState({ place: localStorage.getItem("place") });
    const [areaModal, setAreaModal] = useState(false);
    const [tableModal, setTableModal] = useState(false);
    const [multiTablesModal, setMultiTablesModal] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const loadAreas = (firstLoad, reloadDatas) => {
        loadAreasService(filter).then((res) => {
            res = res?.data;
            if (!res?.errors) {
                setAreas(res?.data?.areas);
                if (firstLoad) {
                    setTables(res?.data?.areas[0]?.tables);
                    setTablesIndex({ areaIndex: 0 });
                } else if (reloadDatas) {
                    console.log("setting index:", tablesIndex.areaIndex || 0);
                    setTables(res?.data?.areas[tablesIndex.areaIndex || 0]?.tables);

                }
            }
        })
    }

    useEffect(() => {
        loadAreas(true);

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const deleteTable = async (item) => {
        if (await confirm("Masayı silmek istediğinizden emin misiniz?")) {
            deleteTableService({ tableId: tableModal?.id || item.id })
                .then((res) => {
                    if (!res.data.errors) {
                        toast.success("Masa Silindi.");

                        setTableModal(null);
                        loadAreas(false, true);
                    }
                })
        }
    }

    const deleteTables = async (tables) => {
        deleteTablesService({ tables: tables })
            .then((res) => {
                if (!res.data.errors) {
                    toast.success("Masalar Silindi.");

                    setTableModal(null);
                    loadAreas(false, true);
                }
            })
    }

    const deleteArea = async (item) => {
        if (await confirm("Bölgeyi silmek istediğinizden emin misiniz?")) {
            deleteAreaService({ areaId: areaModal?.id || item?.id })
                .then((res) => {
                    if (!res.data.errors) {
                        toast.success("Bölge Silindi.");

                        setAreaModal(null);
                        loadAreas();
                    }
                })
        }
    }

    const checkParameters = (managerParameters, object) => {
        for (var parameter of managerParameters) {
            if (!object?.hasOwnProperty(parameter.value)) {
                toast.error(`Lütfen ${parameter.label} alanını doldurunuz!`);

                return false;
            }
        }

        return true;
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    display: width <= 412 ? "flex" : "",
                    flexDirection: width <= 412 ? "row" : "",
                    alignItems: width <= 412 ? "center" : "",
                    background: "#3a3a3a",
                    height: width < 400 ? "125" : width <= 575 ? "115px" : width <= 580 ? "90px" : width <= 767 ? "110px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }} className={`${width <= 575 && "py-2"}`} >
                    <Row gutter={16} justify="space-between" align="middle" style={{ minHeight: "75px" }} className="h-100">
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className={`${width <= 567 ? "mt-1" : ""}`}>
                            <Input.Search
                                placeholder="İsim ile ara..."
                                size="large"
                                onSearch={(value) => {
                                    if (!value || value == "") return setFindedTables(null);

                                    let findedTables = [];
                                    areas.map((area) => {
                                        area.tables.map((table) => {
                                            if (table.name.toLowerCase().includes(value.toLowerCase())) {
                                                findedTables.push(table);
                                            }
                                        })
                                    })

                                    console.log(findedTables);

                                    setFindedTables(findedTables);
                                }}
                                onChange={(e) => {
                                    var value = e.target.value;
                                    if (!value || value == "") return setFindedTables(null);

                                    let findedTables = [];
                                    areas.map((area) => {
                                        area.tables.map((table) => {
                                            if (table.name.toLowerCase().includes(value.toLowerCase())) {
                                                findedTables.push(table);
                                            }
                                        })
                                    })

                                    console.log(findedTables);

                                    setFindedTables(findedTables);
                                }}
                            />
                        </Col>
                        <Col xs={0} sm={0} md={6} lg={6} xl={8} xxl={11} />
                        {width <= 764 && <div style={{ minHeight: "10px", minWidth: "100%" }} />}
                        <Col xs={24} sm={24} md={6} lg={7} xl={5} xxl={4}>
                            <div style={{ display: "flex", flexDirection: width < 400 ? "column" : "row", flexBasis: width <= 767 && "50%", float: width > 766 && "right" }} className={`${width <= 575 ? "mt-1" : ""}`}>
                                {
                                    width <= 767 ? <>
                                        <Button
                                            onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                            size="large"
                                            className="ms-1"
                                        >
                                            Masa - Bölge Ekle
                                        </Button>
                                        <Button
                                            onClick={() => setMultiTablesModal({})}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%", marginTop: width <= 400 && "10px" }}
                                            size="large"
                                            className="ms-1"
                                        >
                                            Toplu Güncelleme
                                        </Button>
                                    </> :
                                        <>
                                            <Button
                                                className="me-2"
                                                onClick={() => setSelectMode(true)}
                                                icon={<DeleteOutlined />}
                                                style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%", marginTop: width <= 575 ? "10px" : "0px", minWidth: "170px" }}
                                                size="large"
                                            >
                                                Toplu Masa Sil
                                            </Button>
                                            <Button
                                                className="me-2"
                                                onClick={() => setMultiTablesModal({})}
                                                icon={<PlusOutlined />}
                                                style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%", marginTop: width <= 575 ? "10px" : "0px", minWidth: "170px" }}
                                                size="large"
                                            >
                                                {width <= 767 ? "Toplu Güncelleme" : "Toplu Masa Ekle"}
                                            </Button>
                                            <Button
                                                onClick={() => setTableModal({})}
                                                icon={<PlusOutlined />}
                                                style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%", marginTop: width <= 575 ? "10px" : "0px" }}
                                                size="large"
                                            >
                                                Yeni Masa Ekle
                                            </Button>
                                        </>}
                            </div>

                            <Collapse isOpen={isOpenCollapse} className="w-50 float-start position-relative " style={{ zIndex: 2000 }}>
                                <div className="d-flex justify-content-center align-items-center flex-column ms-1 bg-white rounded" onClick={() => setIsOpenCollapse(false)}>
                                    <Button
                                        onClick={() => setTableModal({})}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Masa Ekle
                                    </Button>
                                    <Button
                                        onClick={() => setAreaModal({})}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Bölge Ekle
                                    </Button>
                                    <Button
                                        onClick={() => setSelectMode(true)}
                                        icon={<PlusOutlined />}
                                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#000000", width: "100%" }}
                                        size="large"
                                    >
                                        Toplu Masa Ekle
                                    </Button>
                                </div>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>

                <div className="menuManagement" style={{ minHeight: "100vh" }}>
                    <div style={{ backgroundColor: "#f4f4f4", boxShadow: "0px 0px 2px rgb(0 0 0 / 13%)" }} className={`${width >= 937 && "sipyo-border-right"} ${width < 937 && "sipyo-border-bottom"}`} >
                        <center>
                            {width > 767 && <>
                                <Button onClick={() => setAreaModal({})} className="btn-xxxl" icon={<PlusOutlined />} size={""} style={{ height: "auto", whiteSpace: "normal", backgroundColor: "#c72328", color: "white", marginTop: "20px" }}>
                                    Yeni Bölge Ekle
                                </Button>
                                <hr className="sipyo-border-bottom" />
                            </>}
                            {
                                width <= 767 ? <>
                                    <div className="d-flex flex-row align-items-center w-100 overflow-y-auto">
                                        {
                                            areas?.map((area, areaIndex) => (
                                                <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "row", backgroundColor: "#e8e8e8", borderRadius: "5px", marginBottom: "10px", flexWrap: "nowrap", whiteSpace: "nowrap" }} className={`${tablesIndex?.areaIndex == areaIndex && "sipyo-border"} rounded mx-1 mt-2`} onClick={() => {
                                                    setTables(area.tables);
                                                    setTablesIndex({ areaIndex: areaIndex });
                                                }}>
                                                    <div style={{ cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "row", width: "80%", height: "50px" }}>
                                                        <AppstoreAddOutlined style={{ fontSize: "24px", marginLeft: "12px" }} />
                                                        <h5 style={{ margin: 0, marginLeft: "5px", fontWeight: "550" }} className="fs-5">{area?.name}</h5>
                                                    </div>

                                                    <div style={{ width: "15%", height: "50px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", fontSize: "22px", marginLeft: "5px", marginRight: "5px" }}>
                                                        {selectMode ?
                                                            <Checkbox style={{ marginRight: "5px" }} onChange={(e) => {
                                                                let newAreas = [...areas];
                                                                newAreas[areaIndex].selected = e.target.checked;
                                                                newAreas[areaIndex].tables.map((table) => table.selected = e.target.checked);
                                                                setAreas(newAreas);
                                                            }} checked={area.selected} className="me-2" />
                                                            : <MoreOutlined className="me-2" onClick={() => setAreaModal(area)} />}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </> : <>
                                    <div>
                                        {
                                            areas?.map((area, areaIndex) => (
                                                <div>
                                                    <div style={{ width: "95%", height: "50px", display: "flex", alignItems: "center", textAlign: "center", backgroundColor: "#e8e8e8", borderRadius: "5px", marginBottom: "10px" }} className={`${tablesIndex?.areaIndex == areaIndex && "sipyo-border"}`}>
                                                        <div style={{ cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "row", width: "80%", height: "50px" }} onClick={() => {
                                                            setTables(area.tables);
                                                            setTablesIndex({ areaIndex: areaIndex });
                                                        }}>
                                                            <AppstoreAddOutlined style={{ fontSize: "24px", marginLeft: "12px" }} />
                                                            <h5 style={{ margin: 0, marginLeft: "5px", fontWeight: "550" }} className="fs-5">{area?.name}</h5>
                                                        </div>

                                                        <div style={{ width: "15%", height: "50px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", fontSize: "22px", marginLeft: "auto", marginRight: "10px" }}>
                                                            {selectMode ?
                                                                <Checkbox style={{ marginRight: "5px" }} onChange={(e) => {
                                                                    let newAreas = [...areas];
                                                                    newAreas[areaIndex].selected = e.target.checked;
                                                                    newAreas[areaIndex].tables.map((table) => table.selected = e.target.checked);
                                                                    setAreas(newAreas);
                                                                }} checked={area.selected} />
                                                                : <MoreOutlined onClick={() => setAreaModal(area)} />}
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }

                        </center>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: width < 300 ? "100%" : width <= 990 ? "50% 50%" : width >= 1600 ? "25% 25% 25% 25%" : "33.3% 33.3% 33.33%", gridAutoRows: "160px", background: "white", }}>
                        {
                            findedTables ? findedTables.map((table, tableIndex) => (
                                <div className="sipyo-border" style={{ cursor: "pointer", borderRadius: "10px", margin: "10px", height: "150px" }} onClick={() => {
                                    if (selectMode) {
                                        let newTables = [...tables];
                                        newTables[tableIndex].selected = !newTables[tableIndex].selected;
                                        setTables(newTables);
                                    } else {
                                        setTableModal(table);
                                    }
                                }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "5px", marginTop: "5px" }}>
                                        {selectMode ? <Checkbox style={{ marginRight: "5px" }} onChange={(e) => {
                                            let newTables = [...tables];
                                            newTables[tableIndex].selected = e.target.checked;
                                            setTables(newTables);
                                        }} checked={table.selected} /> : <>
                                            <SettingOutlined onClick={() => {
                                                setTableModal(table)
                                            }} style={{ fontSize: "26px" }} />
                                        </>}
                                    </div>

                                    <div style={{ height: "90px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        <h5 className="fs-5">{table?.name}</h5>
                                        {/*table?.name?.search(table?.queue) < 0 && <h5 style={{ fontSize: "12px", color: "#c72328" }}>(Masa {table?.queue})</h5>*/}
                                    </div>
                                </div>
                            )) :
                                tables?.map((table, tableIndex) => (
                                    <div className="sipyo-border" style={{ cursor: "pointer", borderRadius: "10px", margin: "10px", height: "150px" }} onClick={() => {
                                        if (selectMode) {
                                            let newTables = [...tables];
                                            newTables[tableIndex].selected = !newTables[tableIndex].selected;
                                            setTables(newTables);
                                        } else {
                                            setTableModal(table);
                                        }
                                    }}>
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "5px", marginTop: "5px" }}>
                                            {selectMode ? <Checkbox style={{ marginRight: "5px" }} onChange={(e) => {
                                                let newTables = [...tables];
                                                newTables[tableIndex].selected = e.target.checked;
                                                setTables(newTables);
                                            }} checked={table.selected} /> : <>
                                                <SettingOutlined onClick={() => {
                                                    setTableModal(table)
                                                }} style={{ fontSize: "26px" }} />
                                            </>}
                                        </div>

                                        <div style={{ height: "90px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                            <h5 className="fs-5">{table?.name}</h5>
                                            {/*table?.name?.search(table?.queue) < 0 && <h5 style={{ fontSize: "12px", color: "#c72328" }}>(Masa {table?.queue})</h5>*/}
                                        </div>
                                    </div>

                                ))
                        }
                    </div>

                </div>

                {
                    selectMode && (width <= 767 ? <>
                        <div className='bg-white w-100 '>
                            <div className=" position-fixed bottom-0 w-100 bg-white sipyo-border" style={{ left: 0, zIndex: 99999 }}>
                                <div className="d-flex justify-content-center align-items-center" style={{ flexBasis: "50%" }}>
                                    <Button className="my-2 mx-1" size="large" onClick={() => {
                                        setSelectMode(false);
                                    }}>İptal</Button>
                                    <Button className="my-2 mx-1" size="large" type="primary" onClick={async () => {
                                            var tablesToDelete = tables.filter(table => table.selected).map((table) => table.id);
                                            if (tablesToDelete.length == 0) {
                                                toast.error("Lütfen silmek istediğiniz masaları seçiniz.");
                                                return;
                                            }

                                            if (await confirm("Seçili masaları silmek istediğinizden emin misiniz?")) {
                                                deleteTables(tablesToDelete);
                                                console.log(tablesToDelete);
                                                setSelectMode(false);
                                            }
                                        }} icon={<DeleteFilled width={16} />}>Sil</Button>
                                </div>
                            </div>
                        </div>
                    </> : <>
                        <div className='d-flex justify-content-center '>
                            <div className=' position-fixed ' style={{ bottom: "100px", zIndex: 3 }}>
                                <Row gutter={[24, 24]}>
                                    <Col sm={24} md={12} className='d-flex justify-content-center'>
                                        <Button className=' rounded-5 shadowPulse ' style={{ height: "50px", width: "120px" }} onClick={() => {
                                            setSelectMode(false);
                                        }}>
                                            İptal
                                        </Button>
                                    </Col>
                                    <Col sm={24} md={12} className='d-flex justify-content-center'>
                                        <Button type='primary' className=' rounded-5 shadowPulse ' style={{ height: "50px", width: "120px" }} onClick={async () => {
                                            var tablesToDelete = tables.filter(table => table.selected).map((table) => table.id);
                                            if (tablesToDelete.length == 0) {
                                                toast.error("Lütfen silmek istediğiniz masaları seçiniz.");
                                                return;
                                            }

                                            if (await confirm("Seçili masaları silmek istediğinizden emin misiniz?")) {
                                                deleteTables(tablesToDelete);
                                                console.log(tablesToDelete);
                                                setSelectMode(false);
                                            }
                                        }} icon={<DeleteFilled width={16} />}>
                                            Sil
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>)
                }
            </div>

            <Modal
                isOpen={!!areaModal}
                toggle={() => {
                    setAreaModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setAreaModal(false);
                }}>
                    {areaModal?.id ? "Bölge Güncelle" : "Bölge Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={24} md={24}>
                            <Label>Bölge İsmi</Label>
                            <Input defaultValue={areaModal?.name} placeholder="Bölge İsmi" onChange={(e) => setAreaModal({ ...areaModal, name: e.target.value })} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {areaModal?.id ? <Button onClick={() => deleteArea()} style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}>Bölgeyi Sil</Button> : <></>}
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            areaModal.place = localStorage.getItem("place");
                            if (areaModal?.id) {
                                areaModal.areaId = areaModal.id;

                                delete areaModal.id;
                                delete areaModal.createdAt;
                                delete areaModal.addedBy;

                                updateAreaService(areaModal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Bölge Güncellendi.");

                                        setAreaModal(null);
                                        loadAreas();
                                    }
                                })
                            } else {
                                if (!checkParameters([{ value: "name", label: "Bölge İsmi" }], areaModal)) { return; }

                                createAreaService(areaModal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Bölge Oluşturuldu.");

                                        setAreaModal(null);
                                        loadAreas();
                                    }
                                })
                            }
                        }}
                    >
                        {areaModal?.id ? "Bölgeyi Güncelle" : "Bölge Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={!!tableModal}
                toggle={() => {
                    setTableModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setTableModal(false);
                }}>
                    {tableModal?.id ? "Masa Güncelle" : "Masa Ekle"}
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={tableModal?.id ? 24 : 12} md={tableModal?.id ? 24 : 12}>
                            <Label>Masa Adı</Label>
                            <Input size="large" defaultValue={tableModal?.name} placeholder="Masa Adı" onChange={(e) => setTableModal({ ...tableModal, name: e.target.value })} />
                        </Col>
                        {
                            !tableModal?.id ? <>
                                <Col xs={12} md={12}>
                                    <Label>Bölge</Label>
                                    <Select
                                        styles={ReactSelectStyle}
                                        options={[
                                            ...areas?.map((area) => { return { label: area.name, value: area.id } })
                                        ]}
                                        defaultValue={{ label: areas?.filter(area => area.id == tableModal?.area)[0]?.name, value: areas?.filter(area => area.id == tableModal?.area)[0]?.id }}
                                        onChange={(e) => setTableModal({ ...tableModal, area: e.value })}
                                        placeholder="Bölge"
                                    />
                                </Col>
                            </> : <></>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {tableModal?.id ? <Button onClick={() => deleteTable()} style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}>Masayı Sil</Button> : <></>}
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            tableModal.place = localStorage.getItem("place");
                            if (tableModal?.id) {
                                tableModal.tableId = tableModal.id;

                                delete tableModal.id;
                                delete tableModal.createdAt;
                                delete tableModal.addedBy;

                                updateTableService(tableModal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Masa Güncellendi.");

                                        setTableModal(null);
                                        loadAreas(false, true);
                                    }
                                })
                            } else {
                                if (!checkParameters([{ value: "name", label: "Masa İsmi" }], tableModal)) { return; }

                                createTableService(tableModal).then((res) => {
                                    if (!res.data.errors) {
                                        console.log(res.data.data);

                                        toast.success("Masa Oluşturuldu.");

                                        setTableModal(null);
                                        loadAreas(false, true);
                                    }
                                })
                            }
                        }}
                    >
                        {tableModal?.id ? "Masayı Güncelle" : "Masa Ekle"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={!!multiTablesModal}
                toggle={() => {
                    setMultiTablesModal(false);
                }}
                className="modal-dialog-centered modal-lg"
            >
                <ModalHeader toggle={() => {
                    setMultiTablesModal(false);
                }}>
                    Toplu Masa Ekle
                </ModalHeader>
                <ModalBody>
                    <Row gutter={12}>
                        <Col xs={12} sm={12} md={12}>
                            <Label>Masa Adedi</Label>
                            <Select
                                options={[
                                    ...Array(20).fill(0).map((_, index) => { return { label: index + 1, value: index + 1 } })
                                ]}
                                onChange={(e) => { setMultiTablesModal({ ...multiTablesModal, count: e.value }) }}
                                placeholder="Masa Adedi"
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12}>
                            <Label>Bölge</Label>
                            <Select
                                options={[
                                    ...areas.map((area) => { return { label: area.name, value: area.id } })
                                ]}
                                onChange={(e) => { setMultiTablesModal({ ...multiTablesModal, area: e.value }) }}
                                placeholder="Bölge"
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                        onClick={() => {
                            multiTablesModal.place = localStorage.getItem("place");

                            createMultiTablesService(multiTablesModal).then((res) => {
                                if (!res.data.errors) {
                                    console.log(res.data.data);

                                    toast.success("Masa Oluşturuldu.");

                                    setMultiTablesModal(null);
                                    loadAreas(false, true);
                                }
                            })
                        }}
                    >
                        Masa Ekle
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default TableManagement;

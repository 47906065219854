import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { deleteBrandUserService } from "../../../services/brand-user";
import { toast } from "react-toastify";
import { confirm } from "../../../components/confirm";

export const BrandUserColumns = (setModal, loadBrandUsers, filter) => {
    return [
        {
            name: <span className="fs-5">Ad - Soyad</span>,
            sortable: true,
            sortField: "fullname",
            minWidth: "300px",
            selector: (row) => <span className=" paragraph-2 ">{row.fullname}</span>,
        },
        {
            name: <span className="fs-5">E-Posta</span>,
            sortable: true,
            sortField: "email",
            minWidth: "300px",
            selector: (row) => <span className=" paragraph-2 ">{row.email}</span>,
        },
        {
            name: <span className="fs-5">Oluşturma Tarihi</span>,
            sortable: true,
            sortField: "date",
            minWidth: "200px",
            selector: (row) => <span className=" paragraph-2 ">{new Date(parseInt(row.createdAt)).toLocaleDateString()}</span>,
        },
        {
            name: <span className="fs-5">Aksiyonlar</span>,
            sortable: true,
            sortField: "name",
            minWidth: "300px",
            sortable: false,
            selector: (row) => (
                <div className="d-flex flex-row justify-content-between">
                    <Button size="large" type="primary" onClick={() => setModal(row)} color="#c72328" icon={<EditFilled color="#c72328" />} style={{ marginLeft: "5px" }}>Düzenle</Button>
                    <Button size="large" onClick={async () => {
                        if (await confirm("Kullanıcıyı silmek istediğine emin misin?")){
                            deleteBrandUserService({ brandUserId: row.id }).then((res) => {
                                if (!res.data.errors) {
                                    console.log(res.data.data);

                                    toast.warn("Kullanıcı Silindi.");

                                    setModal(null);
                                    loadBrandUsers(filter);
                                }
                            })
                        }
                    }} color="#c72328" icon={<DeleteFilled color="#c72328" />} style={{ marginLeft: "5px", backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }} />
                </div>
            ),
        },
    ];
};
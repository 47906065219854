import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import Admins from "../Pages/Admins";
import Roles from "../Pages/Roles";
import BrandUsers from "../Pages/BrandUsers";
import Places from "../Pages/Places";
import MenuManagement from "../Pages/MenuManagement";
import TableManagement from "../Pages/TableManagement";
import OrdersPage from "../Pages/Orders";
import DigitalMenuSettings from "../Pages/DigitalMenuSettings";
import Reports from "../Pages/Reports";
import Settings from "../Pages/Settings";
import Purchase from "../Pages/Purchase/Purchase";
import PurchaseSuccess from "../Pages/Purchase/PurchaseSuccess";
import PurchaseFailed from "../Pages/Purchase/PurchaseFailed";
import Plans from "../Pages/Plans";
import Subscription from "../Pages/Subscription";
import DailyReports from "../Pages/DailyReports";
import ForgotPassword from "../Pages/Authentication/ForgotPassword.js";
import ResetPassword from "../Pages/Authentication/ResetPassword.js";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  
  // ! Admins
  { path: "/admins", component: <Admins /> },

  // ! Brand Users
  { path: "/brand-users", component: <BrandUsers /> },

  // ! Places
  { path: "/places", component: <Places /> },

  // ! Menu Management
  { path: "/menu-management", component: <MenuManagement /> },
  
  // ! Table Management
  { path: "/table-management", component: <TableManagement /> },
  
  // ! Orders
  { path: "/orders", component: <OrdersPage /> },
  
  // ! Digital Menu Settings
  { path: "/digital-menu-settings", component: <DigitalMenuSettings /> },
  
  // ! Reports
  { path: "/reports", component: <Reports /> },
  
  // ! Daily Reports
  { path: "/daily-reports", component: <DailyReports /> },
  
  // ! User Management
  { path: "/user-management", component: <BrandUsers /> },
  
  // ! Subscription
  { path: "/subscription-info", component: <Subscription /> },
  
  // ! Settings
  { path: "/settings", component: <Settings /> },

  // ! Roles
  { path: "/roles", component: <Roles /> },

  // ! Plans
  { path: "/plans", component: <Plans /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // ! Purchase
  { path: "/purchase", component: <Purchase /> },
  
  // ! Purchase Success
  { path: "/purchase/success", component: <PurchaseSuccess /> },
  
  // ! Purchase Failed
  { path: "/purchase/failed", component: <PurchaseFailed /> },
  
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password/:resetToken", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },

];

export { authProtectedRoutes, publicRoutes };

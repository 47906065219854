import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import logosm from "../../assets/images/logo-sm.png";

import { Row, Col, CardBody, Card, Alert, Container, Form, FormFeedback, Label, FormGroup } from "reactstrap";
import { Button, Checkbox, Input } from "antd";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { registerUser } from "../../store/actions";

import { createSelector } from 'reselect';
import AuthenticationHeader from "./AuthenticationHeader";

const Register = props => {
    document.title = "Kayıt Yap | Sipyo";

    const dispatch = useDispatch();
    var [height, setHeight] = React.useState(window.innerHeight);
    var [customError, setCustomError] = React.useState(null);
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [policyCheck, setPolicyCheck] = React.useState(true);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            password: '',
            passwordAgain: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Lütfen İşletme Adını giriniz"),
            email: Yup.string().required("Lütfen E-Postanızı giriniz"),
            phoneNumber: Yup.string().required("Lütfen Telefon numaranızı giriniz"),
            password: Yup.string().required("Lütfen Şifrenizi giriniz"),
            passwordAgain: Yup.string().required("Lütfen Şifrenizi tekrar giriniz"),
        }),
        onSubmit: (values) => {
            console.log(values);

            if (values.password !== values.passwordAgain) {
                validation.setErrors({ password: "Şifreler uyuşmuyor", passwordAgain: "Şifreler uyuşmuyor" });
                setCustomError("Şifreler uyuşmuyor");
                return;
            }

            if (!policyCheck) {
                setCustomError("Lütfen şartlar ve koşulları kabul ediniz");
                return;
            }

            values = {
                name: values.name,
                manager: {
                    fullname: values.fullname,
                    email: values.email,
                    phoneNumber: values.phoneNumber.toString(),
                    password: values.password,
                }
            };

            var response = registerUser(values, props.router.navigate);

            console.log("response", response)
            dispatch(response);
        }
    });

    const registerpage = createSelector(
        (state) => state.account,
        (state) => ({
            user: state.user,
            registrationError: state.registrationError,
        })
    );

    // Inside your component
    const { user, registrationError } = useSelector(registerpage);

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.body.className = "bg-e9ecef";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <AuthenticationHeader />

            <div style={{ height: height - 100 }}>
                <Row style={{ height: height - 100 }}>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} className="bg-white d-flex justify-content-center align-items-center" style={{ zIndex: "10" }}>
                        <Container className="text-center">
                            <h1 className="fw-bold fs-2">Kayıt Ol!</h1>
                            <h1 className="fw-medium fs-5 my-2" style={{ color: "#6D6D6D" }}>
                                <span>15 Gün boyunca </span>
                                <span
                                    className="fw-semibold" style={{ color: "#6D6D6D" }}>
                                    <span className="fw-semibold" style={{ color: "#6D6D6D" }}>
                                        ücretsiz deneyin!
                                    </span>
                                </span>
                            </h1>
                            <div className="p-4">
                                <div>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            setCustomError(null);
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {registrationError || customError ? <Alert color="danger"><div>{registrationError || customError}</div></Alert> : null}
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <Input
                                                        style={{ height: "50px" }}
                                                        name="name"
                                                        className="m-1"
                                                        placeholder="İşletme Adı"
                                                        type="name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name && validation.errors.name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.name}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Input
                                                        style={{ height: "50px" }}
                                                        name="fullname"
                                                        className="m-1"
                                                        placeholder="İsim Soyisim"
                                                        type="fullname"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.fullname || ""}
                                                        invalid={
                                                            validation.touched.fullname && validation.errors.fullname ? true : false
                                                        }
                                                    />
                                                    {validation.touched.fullname && validation.errors.fullname ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.fullname}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Input
                                                        style={{ height: "50px" }}
                                                        name="email"
                                                        className="m-1"
                                                        placeholder="E-Posta"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Input
                                                        style={{ height: "50px" }}
                                                        name="phoneNumber"
                                                        className="m-1"
                                                        placeholder="Telefon Numarası"
                                                        type="number"
                                                        maxLength={10}
                                                        prefix={<span className=" fw-bold ">+90</span>}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phoneNumber || ""}
                                                        invalid={
                                                            validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                                                        }
                                                    />
                                                    {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.phoneNumber}</div></FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-4">
                                                    <Row gutter={[24, 24]}>
                                                        <Col md={6}>
                                                            <Input.Password
                                                                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                                                                className="m-1"
                                                                style={{ height: "50px" }}
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type="password"
                                                                placeholder="Şifre"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <Input.Password
                                                                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                                                                className="m-1"
                                                                style={{ height: "50px" }}
                                                                name="passwordAgain"
                                                                value={validation.values.passwordAgain || ""}
                                                                type="password"
                                                                placeholder="Şifre Tekrar"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.passwordAgain && validation.errors.passwordAgain ? true : false
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div> {validation.errors.password} </div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                {/*<span className=" user-select-none fw-semibold d-flex align-items-center justify-content-start " style={{ fontSize: "12px", marginLeft: "5px" }}>
                                                    <input type="checkbox" onChange={(e) => setPolicyCheck(e.target.checked)} checked={policyCheck} />
                                                    <label className="ms-2 text-start" style={{ marginBottom: "0px", marginLeft: "0.5rem" }}>
                                                        "Kayıt Ol" butonuna tıklayarak, <a href="https://sipyo.com.tr/user-agreement" style={{ color: "#c72328" }}>Şartlar ve koşullarını</a> kabul ediyorum.
                                                    </label>
                                                </span>*/}
                                                <span className=" user-select-none fw-semibold d-flex align-items-center justify-content-start " style={{ fontSize: "12px", marginLeft: "5px" }}>
                                                    <label className="ms-2 text-start" style={{ marginBottom: "0px", marginLeft: "0.5rem" }}>
                                                        "Kayıt Ol" butonuna tıklayarak, <a href="https://sipyo.com.tr/user-agreement" style={{ color: "#c72328" }}>Şartlar ve koşullarını</a> kabul edersiniz.
                                                    </label>
                                                </span>
                                                <div className="d-grid mt-4">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        size="large"
                                                        className=""
                                                        style={{ fontWeight: "550" }}
                                                    >
                                                        Kayıt Ol
                                                    </Button>
                                                </div>
                                                <div className="d-flex flex-row justify-content-center mt-4 text-center fw-semibold">
                                                    <p className="mb-0">Zaten bir hesabınız var mı?</p>
                                                    <Link className="ms-1" to="/login" style={{ color: "#c72328" }}>Giriş Yap</Link>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Register);

Register.propTypes = {
    history: PropTypes.object,
};

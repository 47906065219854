import React, { useRef, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Divider, Input, Row, Switch, Tooltip } from 'antd';
import { AppstoreAddOutlined, CaretDownOutlined, CaretUpOutlined, CopyFilled, CopyOutlined, DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PercentageOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, QuestionCircleFilled, QuestionCircleOutlined, SaveFilled, SettingOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button as ReactStrapButton, Collapse } from "reactstrap";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { loadPlacesService, updatePlaceService } from "../../services/places";
import dayjs from 'dayjs'

const Settings = () => {
    document.title = "Dijital Menü Ayarları | Sipyo";

    const [data, setData] = useState({ digitalMenu: false, logo: "", themeColor: "#C72328" });
    const [place, setPlace] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        let newAuth = localStorage.getItem("authUser");
        newAuth = JSON.parse(newAuth);

        loadPlace();

        setData({ digitalMenu: newAuth.place.digitalMenu, logo: newAuth.place.logo, themeColor: newAuth.place.themeColor });

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const loadPlace = async () => {
        let place = localStorage.getItem("place");
        await loadPlacesService({ id: place })
            .then((data) => {
                data = data.data.data.places;
                setPlace({
                    ...data[0],
                    openingTime: dayjs(data[0].openingTime, "HH:mm"),
                    closingTime: dayjs(data[0].closingTime, "HH:mm")
                });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    display: width <= 575 ? "flex" : "",
                    flexDirection: width <= 575 ? "row" : "",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#3a3a3a",
                    height: width <= 575 ? "175px" : width <= 580 ? "90px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} align="middle" style={{ height: "90px" }} className="w-100">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ height: width <= 575 ? "" : "55px" }}>
                            <div style={{ display: "flex", flexDirection: width <= 575 ? "column" : "row", float: width <= 575 ? "" : "right" }}>
                                <Button
                                    onClick={() => {
                                        var placeData = {
                                            placeId: place.id,
                                            name: place.name,
                                            openingTime: dayjs(place.openingTime).format("HH:mm"),
                                            closingTime: dayjs(place.closingTime).format("HH:mm"),
                                            country: place.country,
                                            city: place.city,
                                            district: place.district,
                                            neighborhood: place.neighborhood,
                                            street: place.street,
                                            address: place.address,
                                            cash: place.cash,
                                            creditCard: place.creditCard,
                                            setCard: place.setCard,
                                            metropol: place.metropol,
                                        }

                                        updatePlaceService(placeData)
                                            .then((res) => {
                                                let newAuth = localStorage.getItem("authUser");
                                                newAuth = JSON.parse(newAuth);
                                                toast.success("Ayarlar başarıyla güncellendi.");
                                                newAuth.place = { ...newAuth.place, ...data };
                                                localStorage.setItem("authUser", JSON.stringify(newAuth));
                                            })
                                            .catch((err) => {
                                                toast.error("Ayarlar güncellenirken bir hata oluştu.");
                                            });

                                    }}
                                    style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff", width: "100%" }}
                                    size="large"
                                    icon={<SaveFilled style={{ fontSize: "27px" }} />}
                                    className=" d-flex justify-content-center align-items-center "
                                >
                                    Kaydet
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row style={{ /*display: "grid", gridTemplateColumns: "40% 60%"*/ backgroundColor: "#f8f4f4", marginLeft: "0px", marginRight: "0px", minHeight: "100vh" }} gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                        <div className=" p-3 w-100 h-100 ">
                            <h1 className=" sipyo-title ">Ayarlar</h1>

                            <Row gutter={[24, 24]} className="p-2">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <Label className=" fs-5 ">
                                        İşletme Adı
                                    </Label>
                                    <Input value={place?.name} onChange={(e) => setPlace({ ...place, name: e.target.value })} />

                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className=" d-flex flex-column ">
                                        <Label className=" fs-5 ">
                                            Gün Başlangıç
                                        </Label>
                                        <DatePicker format={"HH:mm"} value={place?.openingTime} picker={"time"} onChange={(date, dateString) => { /*console.log(`${date.hour()}:${date.minute()}`);*/ setPlace({ ...place, openingTime: date }) }} />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className=" d-flex flex-column ">
                                        <Label className=" fs-5 ">
                                            Gün Bitiş
                                        </Label>
                                        <DatePicker format={"HH:mm"} value={place?.closingTime} picker={"time"} onChange={(date, dateString) => { /*console.log(`${date.hour()}:${date.minute()}`);*/ setPlace({ ...place, closingTime: date }) }} />
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        Ülke
                                    </Label>
                                    <Input placeholder="Ülke" value={place?.country} onChange={(e) => setPlace({ ...place, country: e.target.value })} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        Şehir
                                    </Label>
                                    <Input placeholder="Şehir" value={place?.city} onChange={(e) => setPlace({ ...place, city: e.target.value })} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        İlçe
                                    </Label>
                                    <Input placeholder="İlçe" value={place?.district} onChange={(e) => setPlace({ ...place, district: e.target.value })} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        Mahalle
                                    </Label>
                                    <Input placeholder="Mahalle" value={place?.neighborhood} onChange={(e) => setPlace({ ...place, neighborhood: e.target.value })} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        Sokak Adı
                                    </Label>
                                    <Input placeholder="Sokak Adı" value={place?.street} onChange={(e) => setPlace({ ...place, street: e.target.value })} />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Label className=" fs-5 ">
                                        Adres
                                    </Label>
                                    <Input placeholder="Adres" value={place?.address} onChange={(e) => setPlace({ ...place, address: e.target.value })} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                        <Col className=" p-3 w-100 h-100 sipyo-border-left ">
                            <div className=" mt-2 mb-4 d-flex flex-row align-items-center text-center  ">
                                <h1 className="me-2 sipyo-title ">Ödeme Tipleri</h1>
                            </div>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className=" d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 fs-5 ">Nakit</h1>
                                        <Switch checked={place?.cash} onChange={(checked) => setPlace({ ...place, cash: checked })} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className=" d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 fs-5 ">Kredi/Banka Kartı</h1>
                                        <Switch checked={place?.creditCard} onChange={(checked) => setPlace({ ...place, creditCard: checked })} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className=" d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 fs-5 ">SetCard</h1>
                                        <Switch checked={place?.setCard} onChange={(checked) => setPlace({ ...place, setCard: checked })} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div className=" d-flex flex-row justify-content-between align-items-center ">
                                        <h1 className="me-2 fs-5 ">Metropol</h1>
                                        <Switch checked={place?.metropol} onChange={(checked) => setPlace({ ...place, metropol: checked })} />
                                    </div>
                                </Col>
                            </Row>

                        </Col>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Settings;
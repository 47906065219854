import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import DeleteIcon from '../assets/images/MenuManagment/delete_icon.svg';

const Numpad = ({ onChange, inputValue, setInputValue, className, disabled, autoFocus, payment }) => {
    //let [inputValue, setInputValue] = useState(value || '');
    let totalValue = (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ? (parseFloat(0).toFixed(2)) : (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2);
    totalValue = parseFloat(totalValue);

    const handleNumberClick = (number) => {
        if (disabled) { return; };

        if (number === 'delete') {
            setInputValue(prevValue => prevValue.toString().slice(0, -1));
        } else if (number == "decimal") {
            setInputValue(prevValue => prevValue ? prevValue : 0 + ".");
        } else {
            let total = (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ? (parseFloat(0).toFixed(2)) : (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2);
            total = parseFloat(total);

            if (inputValue.toString() == total.toString()) {
                setInputValue(number);
                return;
            }

            setInputValue(prevValue => { if (prevValue) { return prevValue + number } else { return number } });
        }
    };

    const handleCancelClick = () => {
        setInputValue('');
    };

    const handleApproveClick = () => {
        inputValue = parseFloat(inputValue);
        onChange(inputValue || 0);
        setInputValue('');
    };

    const handleChange = (e) => {
        let total = (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ? (parseFloat(0).toFixed(2)) : (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2);
        total = parseFloat(total);

        let value = e.target.value;

        // Virgülü noktaya çevir
        value = value.replace(/,/g, '.');

        // Sadece alfanümerik karakterlere ve noktaya izin ver
        value = value.replace(/[^\w\s.]/gi, '');

        // İlk noktadan sonrasını sil
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Noktadan sonra en fazla iki karaktere izin ver
        const [integerPart, decimalPart] = value.split('.');
        if (decimalPart && decimalPart.length > 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
        }

        console.log(inputValue.toString(), total.toString())
        if (inputValue.toString() == total.toString()) {
            const lastNumber = value[value.length - 1];
            console.log(lastNumber)
            setInputValue(lastNumber);
            return;
        }

        setInputValue(value);
    };

    useEffect(() => {
        if (inputValue == NaN) {
            setInputValue(totalValue);
        }
    }, [inputValue]);

    return (
        <div className={`${className} ${disabled && "opacity-75 cursor-not-allowed"}`}>
            {/*<div className='w-100 d-flex justify-content-between px-2' style={{ background: "#c72328", minHeight: "30px" }}>
                <CloseOutlined style={{ fontSize: "18px" }} className="text-white" onClick={handleCancelClick} />
                <CheckOutlined style={{ fontSize: "18px" }} className="text-white" onClick={handleApproveClick} />
            </div>*/}
            <div>
                <Input autoFocus={autoFocus} className='rounded-0' style={{ borderColor: "#eff2f7" }} type="text" value={inputValue || totalValue} onChange={handleChange} />

            </div>
            <div className='d-flex flex-wrap justify-content-center' style={{ minHeight: "200px" }}>
                <div className='row w-100'>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('1')}>1</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('2')}>2</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('3')}>3</button>
                    </div>
                </div>
                <div className='row w-100'>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('4')}>4</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('5')}>5</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('6')}>6</button>
                    </div>
                </div>
                <div className='row w-100'>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('7')}>7</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('8')}>8</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('9')}>9</button>
                    </div>
                </div>
                <div className='row w-100'>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick("decimal")}>,</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick('0')}>0</button>
                    </div>
                    <div className='col w-100 p-0'>
                        <button style={{ cursor: disabled && "not-allowed", fontSize: "19px", background: "#c72328", minHeight: "70px" }} className=' fw-bolder rounded shadow-none h-100 border w-100 btn btn-primary btn-block' onClick={() => handleNumberClick("delete")}><img src={DeleteIcon} style={{ width: "19px", height: "19px" }} /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Numpad;
import React, { useEffect } from "react";
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer';
import MyCustomFont from '../fonts/Gilroy-Medium.ttf';

import VintageTemplate1 from "../assets/images/DigitalMenuSettings/template/1/1.png";
import VintageTemplate2 from "../assets/images/DigitalMenuSettings/template/2/2.png";
import VintageTemplate3 from "../assets/images/DigitalMenuSettings/template/3/3.png";
import VintageTemplate4 from "../assets/images/DigitalMenuSettings/template/4/4.png";
import VintageTemplate5 from "../assets/images/DigitalMenuSettings/template/5/5.png";

Font.register({
  family: 'Gilroy',
  src: MyCustomFont
})

const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
    zIndex: -1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Gilroy",
    fontWeight: "bold",
  },
  text: {
    marginTop: 53,
    fontSize: 42,

  },
  image: {
    marginTop: 135,
    marginHorizontal: 100,
    width: 240,
    height: 240
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "Gilroy",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Gilroy",
  },
});

const QRCodesPDFFile = ({ placeId, tab, tables }) => {
  const vintageTemplates = [ VintageTemplate1, VintageTemplate2, VintageTemplate3, VintageTemplate4, VintageTemplate5 ];
  const textColors = ["#ffffff", "#000000", "#000000", "#ffffff", "#ffffff"];

  const getAsset = (templateIndex) => {
    return vintageTemplates[templateIndex];
  }

  return (
    <Document>
      {
        tables?.map((table, tableIndex) => {
          return (
            <Page key={tableIndex} style={{
              ...styles.body,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} size="LETTER" >
              {(tab?.template != null && tab?.template != undefined) && tab?.template !== "no-template" && <Image src={getAsset(tab?.template)} style={styles.pageBackground} />}
              <Image style={styles.image} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://menu.sipyo.com.tr/${placeId}?table=${table.id}`} />
              <Text style={{...styles.text, color: textColors[tab?.template]}}>
                {table.name}
              </Text>
            </Page>
          )
        })
      }

    </Document>
  );
};

export default QRCodesPDFFile;

import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loadPurchasesService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            query{purchases${form && Object?.values(form)?.length > 0 ? `(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            )`:""}{
                id
                callbacked
                payment_amount
                digitalMenu
                orderSystem
                merchant_oid
                createdAt
            }}
            `,
    }, {
        headers: {
            
        }
    });

}
import React, { useEffect } from "react";

import { Button, Col, Row } from "antd";
import { FallOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Label } from "reactstrap";
import { getStatistics } from "../../services/dashboard";
import LineChart from "./LineChart";
import ButtonGroup from "antd/es/button/button-group";
import DoughnutChart from "./DoughnutChart";

const hexToRgbA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Dashboard = () => {
  document.title = "Ana Sayfa | Sipyo";
  const [statistics, setStatistics] = React.useState({
    totalPayment: 0,
    totalClosedOrder: 0,
    totalWaitingOrder: 0,
    totalExpense: 0
  });
  const [period, setPeriod] = React.useState("day");

  useEffect(() => {
    loadStatistics();
  }, [])

  const loadStatistics = () => {
    getStatistics().then((response) => {
      response = response.data.data.getStatistics;
      setStatistics(response);
    })
  }

  return (
    <React.Fragment>
      <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`}>
        <Container fluid={true}>
          {/*<Breadcrumbs title="Sipyo" breadcrumbItem="Ana Sayfa" />*/}

          <Row gutter={[24, 24]}>
            <Col xl={12} sm={12} md={12} lg={12} xxl={6}>
              <PanelChart title={"Toplam Kazanç"} value={`₺${statistics?.totalPayment || 0}`} altTitle={"Gün Sonu Raporu"} color={"#23C72F"} icon={"₺"} />
            </Col>
            <Col xl={12} sm={12} md={12} lg={12} xxl={6}>
              <PanelChart title={"Kapanan Masa"} value={`${statistics?.totalClosedOrder || 0}`} altTitle={"Gün Sonu Raporu"} color={"#2378C7"} icon={<UserOutlined style={{ width: "31px", height: "31px", fontSize: "31px" }} />} />
            </Col>
            <Col xl={12} sm={12} md={12} lg={12} xxl={6}>
              <PanelChart title={"Toplam Gider"} value={`${0}`} altTitle={"Gün Sonu Raporu"} color={"#cc2727"} icon={<FallOutlined style={{ width: "31px", height: "31px", fontSize: "31px" }} />} />
            </Col>
            <Col xl={12} sm={12} md={12} lg={12} xxl={6}>
              <PanelChart title={"Bekleyen Sipariş Sayısı"} value={`${statistics?.totalWaitingOrder || 0}`} altTitle={"Gün Sonu Raporu"} color={"#E5A325"} icon={<HomeOutlined style={{ width: "31px", height: "31px", fontSize: "31px" }} />} />
            </Col>

            <Col xl={24} sm={24} md={24} lg={24}>
              <div className=" shadow-lg  bg-white">
                <div className="d-flex justify-content-between p-2">
                  <Label className=" sipyo-subtitle p-2" style={{ fontSize: "19.5px" }}>Bugünün Satışı</Label>

                  <div>
                    {/*<ButtonGroup>
                      <Button type={period == "day" ? "primary" : "default"} onClick={() => setPeriod("day")} >Gün</Button>
                      <Button type={period == "week" ? "primary" : "default"} onClick={() => setPeriod("week")} >Hafta</Button>
                      <Button type={period == "month" ? "primary" : "default"} onClick={() => setPeriod("month")} >Ay</Button>
                    </ButtonGroup>*/}
                  </div>
                </div>

                {/*<LineChart className="p-1" period={period} data={period == "month" ? statistics?.ordersOfMonth : period == "week" ? statistics?.ordersOfWeek : statistics?.ordersOfDays} />*/}
                <LineChart style={{ height: "450px" }} className="p-1" period="hour" data={statistics?.ordersOfToday} />
              </div>
            </Col>
            <Col xl={8} sm={8} md={8} lg={8}>
              <div className=" shadow-lg  bg-white">
                <div className="d-flex justify-content-between p-2">
                  <Label className="sipyo-subtitle">Bugün Yapılan Ödemeler</Label>
                </div>

                <div className="h-100 d-flex justify-content-center align-items-center pb-4">
                  <DoughnutChart id="paymentsOfToday" data={statistics?.paymentsOfToday} />
                </div>
              </div>
            </Col>
            <Col xl={8} sm={8} md={8} lg={8}>
              <div className=" shadow-lg  bg-white">
                <div className="d-flex justify-content-between p-2">
                  <Label className="sipyo-subtitle">Bugün Sipariş Edilen Ürünler</Label>
                </div>

                <div className="h-100 d-flex justify-content-center align-items-center pb-4">
                  <DoughnutChart id="ordersOfTodayByMenuItem" data={statistics?.ordersOfTodayByMenuItem} />
                </div>
              </div>
            </Col>
          </Row>

          <div style={{ minHeight: "70px" }} />
        </Container>
      </div>
    </React.Fragment>
  );
};

const PanelChart = ({ title, value, altTitle, color, icon }) => {
  return (
    <div className=" bg-white rounded shadow-lg " >
      <div className="">
        <div className="d-flex">
          <div className=" d-flex flex-column  p-2 m-4" style={{ flex: 0.85, borderLeft: "3px solid", borderColor: color }}>
            <span className=" paragraf-2 ">{title}</span>
            <span className=" fw-bold " style={{ fontSize: "40px" }}>{value}</span>
          </div>
          <div className="d-flex justify-content-center align-items-center p-2" style={{ flex: 0.15, margin: "13.5px" }}>
            <div className="me-2 rounded-1 d-flex align-items-center justify-content-center " style={{
              width: "52px",
              height: "52px",
              fontWeight: "400",
              fontSize: "31px",
              color: color,
              backgroundColor: hexToRgbA(color, 0.22)
            }}>
              {icon}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between ">
          <div></div>
          <div className="pb-2" style={{ marginRight: "21.5px" }}>
            <span className="user-select-none text-muted" style={{ cursor: "pointer" }} onClick={() => window.location = '/daily-reports'}>{altTitle}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;

import axios from "../lib/axios";
const user = JSON.parse(localStorage.getItem("authUser"));

export const loginPortalService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{loginPortal(
                ${JSON.stringify(form)
                ?.replaceAll("{", "")
                ?.replaceAll("}", "")
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                .slice(1)}
            ){
                id
                uuid
                token
                fullname
                email
                phoneNumber
                place {
                    id
                    name
                    address
                    trial
                    digitalMenu
                    expireDigitalMenu
                    digitalMenuPeriod
                    expireOrderSystem
                    orderSystemPeriod
                    logo
                    themeColor
                }
            }}
            `,
    });

}

export const registerService = (form) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{registerPortal
                ${form && Object?.values(form)?.length > 0 ? `(${JSON.stringify(form)
                ?.replaceAll('":', ":")
                ?.replaceAll(',"', ",")
                ?.replaceAll('{"', "{")
                .slice(1)
                .slice(0, -1)})` : ""}
            {
                id
                uuid
                token
                fullname
                email
                phoneNumber
                place {
                    id
                    name
                    address
                    trial
                    digitalMenu
                    expireDigitalMenu
                    digitalMenuPeriod
                    expireOrderSystem
                    orderSystemPeriod
                    logo
                    themeColor 
                }
            }}
            `,
    });

}

export const verifyTokenService = () => {
    return axios.post("/api/graphql", {
        query: `
            mutation{verifyToken{
                verified
            }}
            `,
    }, {
        headers: {

        }
    });

}

export const forgotPasswordService = (email) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{forgotPassword(email:"${email}"){
                message
                success
            }}
            `,
    }, {
        headers: {

        }
    });

}

export const resetPasswordService = (resetToken, password) => {
    return axios.post("/api/graphql", {
        query: `
            mutation{resetPassword(resetToken:"${resetToken}", password:"${password}"){
                message
                success
            }}
            `,
    }, {
        headers: {

        }
    });

}
import dayjs from 'dayjs';

var formatInt = (value, toFixed) => {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
}

export const columns = (setOrderDetails) => {
    return [
        {
            name: <span className="fs-5">Adisyon Numarası</span>,
            selector: (row) => row.bold ? <b onClick={() => { setOrderDetails(row); }}>{row.id}</b> : <span className=" paragraph-2 " style={{ color: "#c72328" }} onClick={() => { setOrderDetails(row); }}>{row.id}</span>,
            sortable: true,
            sortFunction: (a, b) => a.id - b.id,
            minWidth: "250px"
        },
        {
            name: <span className="fs-5">Açılış Tarihi</span>,
            selector: (row) => row?.createdAt && (row.bold ? <b>{dayjs(new Date(parseInt(row?.createdAt))).format("DD.MM.YYYY")} <br /> <span>{dayjs(new Date(parseInt(row?.createdAt))).format("HH:mm")}</span> </b> : <span className=" paragraph-2 ">{dayjs(new Date(parseInt(row?.createdAt))).format("DD.MM.YYYY")} <br /> <span>{dayjs(new Date(parseInt(row?.createdAt))).format("HH:mm")}</span> </span>),
            sortable: true,
            sortFunction: (a, b) => a.createdAt - b.createdAt,
        },
        {
            name: <span className="fs-5">Kapanış Tarihi</span>,
            selector: (row) => row?.end && (row.bold ? <b>{dayjs(new Date(parseInt(row?.end))).format("DD.MM.YYYY")} <br /> <span>{dayjs(new Date(parseInt(row?.end))).format("HH:mm")}</span> </b> : <span className=" paragraph-2 ">{dayjs(new Date(parseInt(row?.end))).format("DD.MM.YYYY")} <br /> <span>{dayjs(new Date(parseInt(row?.end))).format("HH:mm")}</span> </span>),
            sortable: true,
            sortFunction: (a, b) => a.end - b.end,
        },
        {
            name: <span className="fs-5">Satılan Ürün Miktarı</span>,
            selector: (row) => row.bold ? <b>{row.items?.length || 0}</b> : <span className=" paragraph-2 ">{row.items?.length || 0}</span>,
            sortable: true,
            sortFunction: (a, b) => a.items?.length - b.items?.length,
        },
        {
            name: <span className="fs-5">Masa Adı</span>,
            selector: (row) => row.bold ? <b>{row?.table?.name}</b> : <span className=" paragraph-2 ">{row?.table?.name}</span>,
            sortable: true,
            sortFunction: (a, b) => a?.table?.name - b?.table?.name,
        },
        {
            name: <span className="fs-5">Durum</span>,
            selector: (row) => row.id !== "Toplam" && (row.bold ? <b>{row?.end ? "Kapandı" : "Açık"}</b> : <span className=" paragraph-2 ">{row?.end ? "Kapandı" : "Açık"}</span>),
        },
        {
            name: <span className="fs-5">Tutar (₺)</span>,
            selector: (row) => row.bold ? <b>{row?.total?.toFixed(2) || 0}₺</b> : <span className=" paragraph-2 ">{row?.total?.toFixed(2) || 0}₺</span>,
            sortable: true,
            sortFunction: (a, b) => a?.total || 0 - b?.total || 0,
        },
    ];
};
import React, { useEffect, useRef, useState } from 'react';
import NumPadLib from 'react-numpad';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, Label } from 'reactstrap';
import { InputNumber, SpinButton, Row, Col, Button, Checkbox, Spin, Input, Radio } from 'antd';
import { PlusOutlined, MinusOutlined, MoreOutlined, DeleteOutlined, LeftOutlined, CreditCardOutlined, SettingOutlined, SettingFilled, ExclamationCircleOutlined, CheckCircleOutlined, RollbackOutlined, ExportOutlined, MergeFilled, MergeOutlined, CreditCardFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { loadAreasService } from '../../services/areas';
import SocketService from '../../services/SocketService';
import { timeAgo } from '../../lib/dateCalculator';
import { closeOrderService, createOrderService, deletePayOrderService, mergeOrderService, payOrderService, transferOrderService, updateOrderService } from '../../services/orders';
import { loadMenuCategoriesService } from '../../services/menuCategory';
import Numpad from '../../lib/Numpad';
import { confirm } from "../../components/confirm";
import ButtonGroup from 'antd/es/button/button-group';
import { loadPlacesService } from '../../services/places';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

const OrdersPage = () => {
    document.title = "Siparişler | Sipyo";

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [findedItems, setFindedItems] = useState(null);
    const [fastPay, setFastPay] = useState(false);
    const [payment, setPayment] = useState(false);
    const [selectTableMode, setSelectTableMode] = useState(false);
    const [numpadValue, setNumpadValue] = useState(undefined);
    const [tableOrder, setTableOrder] = useState(false);
    const [tableSettings, setTableSettings] = useState(false);
    const [itemSettings, setItemSettings] = useState(false);
    const [menuCategories, setMenuCategories] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabsLoading, setTabsLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [place, setPlace] = useState(null);

    const scrollRef = useRef(null);

    useEffect(() => {
        console.log("work")
        const handleScroll = (event) => {
            if (scrollRef.current && scrollRef.current.contains(event.target)) {
                scrollRef.current.scrollLeft += event.deltaY;
                event.preventDefault();
            }
        };

        const scrollElement = scrollRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('wheel', handleScroll);
        }

        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('wheel', handleScroll);
            }
        };
    }, [currentTime]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (tabsLoading) {
            loadSocket();
        }
    }, [tabsLoading])

    useEffect(() => {
        loadPlace();
        if (SocketService._socket?.connected) {
            loadTabs();
            loadMenuCategories();
        }
    }, [SocketService._socket?.connected])

    const loadTabs = async () => {
        try {
            await loadAreasService({ place: localStorage.getItem("place") })
                .then((response) => {
                    console.log('response:', response.data.data.areas);
                    if (response.data.data.areas.length > 0) {
                        for (let tab of response.data.data.areas) {
                            tab.active = false;
                        }

                        response.data.data.areas[0].active = true;
                    }

                    for (let tab of response.data.data.areas) {
                        for (let table of tab.tables) {
                            table.area = tab.id;
                        }
                    }

                    for (let tab of response.data.data.areas) {
                        for (var table of tab.tables) {
                            if (table.order) {
                                for (let item of table.order.items) {
                                    item.area = tab.id;
                                    item.table = table.id;
                                }
                            }
                        }
                    }

                    setTabs(response.data.data.areas);
                    setTabsLoading(true);
                });
        } catch (error) {
            console.error('Error loading tabs:', error);
        }
    };

    const loadMenuCategories = async () => {
        await loadMenuCategoriesService().then(async (res) => {
            res = res?.data;
            if (!res?.errors) {
                res?.data?.menuCategories?.map((category) => category.active = false);
                if (res?.data?.menuCategories.length > 0) {
                    res.data.menuCategories[0].active = true;
                }
                setMenuCategories(res?.data?.menuCategories);
            }
        })
    }

    const loadSocket = async () => {
        if (SocketService?._socket?.connected) {
            listenOrder();
        } else {
            if (SocketService._socket == null) {
                console.log("soket bulunamadı!");
                let intervalId = setInterval(() => {
                    if (SocketService._socket) {
                        SocketService._socket.on('connect', () => {
                            console.log('Connected to the socket server');
                        });
                        clearInterval(intervalId);
                    } else {
                        console.log('Connecting to the socket server...');
                    }
                }, 500);
            } else {
                SocketService?._socket?.on('connect', () => {
                    listenOrder();
                })
            }
        }
    }

    const listenOrder = async () => {
        SocketService._socket?.removeAllListeners(localStorage.getItem('place'));
        /*if (SocketService._socket?.listeners(localStorage.getItem('place')).length > 1) {
        } else if (SocketService._socket?.listeners(localStorage.getItem('place')).length > 0) {
            return;
        }*/


        SocketService.on(localStorage.getItem('place'), (data) => {
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }

            console.log("data:", data);
            if (data.type == "createOrder") {
                console.log("CreateOrder", data);
                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);

                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);

                console.log(tabIndex, tableIndex)
                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masasında 1 Yeni Sipariş!`);

                    // ! Calculate quantity of items
                    data.items.forEach(item => {
                        item.quantity = data.items.filter(i => i?.id?.toString() == item?.id?.toString()).length;
                        console.log("update order item", item)
                    });

                    // ! Add quantity of items to order
                    newTabs[tabIndex].tables[tableIndex].order = data;

                    // ! Set order created time
                    newTabs[tabIndex].tables[tableIndex].order.createdAt = new Date().getTime();

                    console.log("calling");
                    setTabs_(newTabs);
                    setTableOrder_(newTabs[tabIndex].tables[tableIndex]);
                    setPayment_(newTabs);
                }
            } else if (data.type == "payOrder" && data.paymentType == "normal") {
                console.log("NormalPayOrder", data);

                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);

                if (tabIndex > -1 && tableIndex > -1) {
                    if (!newTabs[tabIndex].tables[tableIndex].order?.payments) {
                        if (newTabs[tabIndex].tables[tableIndex].order) {
                            newTabs[tabIndex].tables[tableIndex].order.payments = [];
                        }
                    }
                    console.log("before", newTabs[tabIndex].tables[tableIndex]?.order?.payments);
                    newTabs[tabIndex].tables[tableIndex]?.order?.payments?.push(data.payment);
                    console.log("after", newTabs[tabIndex].tables[tableIndex]?.order?.payments);

                    if (data.paid) {
                        toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Kapandı!`);
                        setNumpadValue(null);
                        setPayment(false);
                        newTabs[tabIndex].tables[tableIndex].order = null;
                    }

                    console.log("calling");
                    setTabs_(newTabs);
                    setPayment_(newTabs);
                }
            } else if (data.type == "payOrder") {
                console.log("PayOrder", data);

                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);

                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Kapandı!`);

                    newTabs[tabIndex].tables[tableIndex].order = null;
                    console.log("calling");
                    setTabs_(newTabs);
                }
            } else if (data.type == "deletePayOrder") {
                console.log("DeletePayOrder", data);

                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);

                if (tabIndex > -1 && tableIndex > -1) {
                    newTabs[tabIndex].tables[tableIndex].order.payments = newTabs[tabIndex].tables[tableIndex].order.payments.filter(payment => payment.id !== data.payment.id);

                    console.log("calling");
                    setTabs_(newTabs);
                    setPayment_(newTabs);
                }
            } else if (data.type == "updateOrder") {
                var newData = { ...data };
                delete newData.type;

                // ! Set order created time
                newData.createdAt = new Date(newData.createdAt?.toString()).getTime()?.toString();

                console.log("UpdateOrder", newData);
                // ! Set updated order
                const newTabs = [...tabs];

                // ! Find index of tab and table
                const tabIndex = newTabs.findIndex(tab => tab?.id === newData?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === newData?.table?.id);

                console.log(tabIndex, tableIndex);

                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Düzenlendi!`);

                    newTabs[tabIndex].tables[tableIndex].order = newData;

                    setPayment_(newTabs);
                    setTableOrder_(newTabs[tabIndex].tables[tableIndex]);


                    console.log("calling");
                    setTabs_(newTabs);
                }

            } else if (data.type == "cancelOrder") {
                console.log("CancelOrder", data);
                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);
                console.log("tabIndex", tabIndex, "tableIndex", tableIndex);
                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası İptal Edildi!`);

                    newTabs[tabIndex].tables[tableIndex].order = null;
                    console.log("calling");
                    setTabs_(newTabs);
                    setTableSettings(false);
                }
            } else if (data.type == "closeOrder") {
                if (data.paid) {
                    const newTabs = [...tabs];
                    const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                    const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);

                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Kapandı!`);
                    newTabs[tabIndex].tables[tableIndex].order = null;
                    setTabs_(newTabs);
                    setTableOrder_(newTabs[tabIndex].tables[tableIndex]);
                    setNumpadValue(null);
                    setPayment(false);
                }
            } else if (data.type == "transferOrder") {
                // ! Transfered order to another table
                console.log("TransferOrder", data);
                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);
                const oldTabIndex = newTabs.findIndex(tab => tab?.id === data?.oldTableArea);
                const oldTableIndex = newTabs[oldTabIndex]?.tables.findIndex(table => table?.id === data?.oldTable);

                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Taşındı!`);

                    data.createdAt = new Date(data.createdAt).getTime();
                    delete data.oldTable;
                    delete data.type;

                    if (oldTabIndex > -1 && oldTableIndex > -1) {
                        newTabs[oldTabIndex].tables[oldTableIndex].order = null;
                    }

                    newTabs[tabIndex].tables[tableIndex].order = data;
                    setTabs_(newTabs);
                    setTableOrder_(newTabs[tabIndex].tables[tableIndex]);
                    setPayment_(newTabs);
                } else {
                    console.log(tabIndex, tableIndex);
                    toast.warn(`Masa Transfer Edildi!`);
                }

            } else if (data.type == "mergeTables") {
                console.log("MergeTables", data);

                const newTabs = [...tabs];
                const tabIndex = newTabs.findIndex(tab => tab?.id === data?.table?.area);
                const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === data?.table?.id);
                const fromTabIndex = newTabs.findIndex(tab => tab?.id === data?.fromTableArea);
                const fromTableIndex = newTabs[fromTabIndex]?.tables.findIndex(table => table?.id === data?.fromTable);

                if (tabIndex > -1 && tableIndex > -1) {
                    toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Birleştirildi!`);

                    data.createdAt = new Date(data.createdAt).getTime();
                    delete data.fromTable;
                    delete data.type;

                    if (fromTabIndex > -1 && fromTableIndex > -1) {
                        newTabs[fromTabIndex].tables[fromTableIndex].order = null;
                    }

                    newTabs[tabIndex].tables[tableIndex].order = data;
                    setTabs_(newTabs);
                    setTableOrder_(newTabs[tabIndex].tables[tableIndex]);
                    setPayment_(newTabs);
                } else {
                    console.log(tabIndex, tableIndex);
                    toast.warn(`Masalar Birleştirildi!`);
                }
            }
        });
    }

    const loadPlace = async () => {
        let place = localStorage.getItem("place");
        await loadPlacesService({ id: place })
            .then((data) => {
                data = data.data.data.places;
                setPlace(data[0]);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const setPayment_ = (newTabs, tabIndex, tableIndex_) => {
        console.log("checking payment")
        if (payment) {
            setPayment((prevItem) => {
                var tabIndex_ = tabIndex ? tabIndex : newTabs.findIndex(tab => tab?.id === prevItem?.area);

                if (tabIndex_ > -1) {
                    var tableIndex = tableIndex_ ? tableIndex_ : newTabs[tabIndex_].tables.findIndex(table => {
                        return table?.id === prevItem?.id;
                    });

                    if (tableIndex > -1) {
                        var table = newTabs[tabIndex_]?.tables[tableIndex];

                        return { ...table, selectMode: prevItem?.selectMode };
                    } else {
                        return prevItem;
                    }
                } else {
                    return prevItem;
                }
            })
        }
    }

    const setTableOrder_ = (newData) => {
        setTableOrder((prevItem) => {
            if (prevItem && prevItem.id === newData.id) {
                return newData;
            } else {
                return prevItem;
            }
        })
    }

    const setTabs_ = (newData) => {
        setTabs((prevItem) => {
            if (prevItem) {
                return newData;
            } else {
                return prevItem;
            }
        })
    }

    const fastPayHandler = async (paymentType) => {
        var serviceData = { ...fastPay };
        serviceData.type = "fast";
        serviceData.paymentType = paymentType;
        serviceData.price = serviceData?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0);
        serviceData.order = serviceData.order?.id;
        serviceData.place = localStorage.getItem("place");
        delete serviceData.id;
        delete serviceData.name;
        delete serviceData.queue;
        delete serviceData.area;
        delete serviceData.selectMode;

        console.log(serviceData);
        payOrderService(serviceData)
            .then((response) => {
                console.log('fastpay response:', response.data.data);
                if (response?.data?.data?.payOrder) {
                    toast.success('Ödeme başarılı!');
                    setFastPay(false);
                    loadTabs();

                }
            });
    }

    const payHandler = async (paymentType, price) => {
        if (!price) {
            //toast.warn('Ödeme tutarı giriniz!');
            //return;

            let totalValue = (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ? (parseFloat(0).toFixed(2)) : (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2);
            totalValue = parseFloat(totalValue);
            
            price = totalValue;
        }

        if ((payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0))) <= 0) {
            toast.error('Bu sipariş için ödemenin tamamı alındı!');
            return;
        }

        var newTabs = [...tabs];
        var newTableOrder = { ...tableOrder };
        var newPayment = { ...payment };

        console.log(newTabs, newPayment)
        var tabIndex = newTabs?.findIndex((table) => table.id == newPayment.area);
        var tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === newPayment?.id);
        newTabs[tableIndex]?.tables[tableIndex]?.order?.items?.forEach((item) => {
            if (item.selected) {
                item.paid = true;
                item.selected = false;
            }
        });

        newPayment?.order?.items?.forEach((item) => {
            if (item.selected) {
                item.paid = true;
                item.selected = false;
            }
        });

        newTableOrder?.order?.items?.forEach((item) => {
            if (item.selected) {
                item.paid = true;
                item.selected = false;
            }
        });

        setTableOrder(newTableOrder)
        setPayment(newPayment);

        var serviceData = { ...payment };
        serviceData.paymentType = paymentType || "cash";
        serviceData.type = "normal";
        serviceData.price = price;
        serviceData.order = serviceData.order?.id;
        serviceData.place = localStorage.getItem('place');
        delete serviceData.id;
        delete serviceData.name;
        delete serviceData.queue;
        delete serviceData.payments;
        delete serviceData.area;
        delete serviceData.selectMode;

        console.log(serviceData);
        payOrderService(serviceData)
        .then((response) => {
            console.log('payment response:', response.data.data);
            if (response?.data?.data?.payOrder) {
                toast.success('Ödeme başarılı!');
                setNumpadValue("");
                //setNumpadValue(payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0));
                //setPayment(false);

                }
            });
    }

    const deletePayHandler = async (paymentId) => {
        // ! Delete payment from order
        deletePayOrderService({ payment: paymentId })
            .then(() => {
                toast.success('Ödeme silindi!');
            })
    }

    const transferOrder = async () => {
        var newTable = selectTableMode?.newTableId;

        if (!newTable) {
            toast.error('Lütfen bir masa seçiniz!');
            return;
        }

        await transferOrderService({ table: newTable, order: selectTableMode?.order?.id });
        setSelectTableMode(false);
    }

    const mergeOrder = async () => {
        var newTable = selectTableMode?.newTableId;

        if (!newTable) {
            toast.error('Lütfen bir masa seçiniz!');
            return;
        }

        await mergeOrderService({ table: newTable, fromTable: selectTableMode?.id });
        setSelectTableMode(false);
    }

    const closeOrder = async () => {
        if ((payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0)) > 0) {
            toast.error('Siparişi kapatmak için toplam ödemeyi tahsil etmeniz gerekmektedir.');
            return;
        }
        closeOrderService({ order: payment?.order?.id });

        setNumpadValue(null);
        setPayment(false);
        setTableOrder(false);
    }

    const paidOrder = () => {
        const newTabs = [...tabs];
        const tabIndex = newTabs.findIndex(tab => tab?.id === payment?.order?.table?.area);
        const tableIndex = newTabs[tabIndex]?.tables.findIndex(table => table?.id === payment?.order?.table?.id);

        if (tabIndex > -1 && tableIndex > -1) {
            toast.warn(`${newTabs[tabIndex].name} bölgesinde ${newTabs[tabIndex]?.tables[tableIndex]?.name} Masası Kapandı!`);

            newTabs[tabIndex].tables[tableIndex].order = null;
            setTabs(newTabs);
        }
    }

    const addItem = (item) => {
        var tableOrder_ = { ...tableOrder };
        if (!tableOrder_.order) {
            tableOrder_.order = { items: [] };
        }
        tableOrder_.order.items.push({ item: item, portion: item.portions.find((plt) => plt.defaultPortion)?.title });

        tableOrder_.order.items.forEach((item) => {
            item.quantity = tableOrder_.order.items.filter(i => i?.item?.id?.toString() == item?.item?.id?.toString() && i?.portion == item?.portion).length;
        })

        setTableOrder(tableOrder_);
    }

    const removeItem = (item) => {
        let newTableOrder = { ...tableOrder };
        console.log("items", newTableOrder.order.items);
        console.log("item", item);
        //let items = [...tableOrder.order.items];
        var indexToRemove;

        if (newTableOrder.order.items.filter((plt) => plt?.item?.id === item?.id || item?.item?.id).length == 1) {
            deleteItem(item);
            return;
        }

        if (item.itemIndex != null && item.itemIndex != undefined) {
            indexToRemove = item.itemIndex;
        } else {
            indexToRemove = newTableOrder.order.items.findIndex(plt => plt?.item?.id === item?.id || item?.item?.id);
        }

        if (indexToRemove > -1) {
            newTableOrder.order.items.splice(indexToRemove, 1);
        }

        /*for (let item of items) {
            item.quantity = items?.filter(i => i?.item?.id?.toString() == item?.item.id?.toString() && i?.portion == item?.portion).length;
        }*/

        console.log("new items", newTableOrder.order.items);

        setTableOrder(newTableOrder);
    }

    const deleteItem = (item) => {
        let items = [...tableOrder.order.items];

        items = items.filter(plt => plt.item.id !== item?.id || item?.item?.id);

        setTableOrder({ ...tableOrder, order: { ...tableOrder.order, items: items } });
    }

    const selectItem = (item, itemIndex) => {
        let newTableOrder = { ...tableOrder };
        newTableOrder.order.items[itemIndex].selected = !item.selected;
        setTableOrder(newTableOrder);
    }

    const unselectAllItems = () => {
        let newTableOrder = { ...tableOrder };
        newTableOrder.order.items.forEach(item => item.selected = false);
        setTableOrder(newTableOrder);
    }

    const removeItems = () => {
        let newTableOrder = { ...tableOrder };
        newTableOrder.order.items.map((item, itemIndex) => {
            if (item.selected) {
                delete newTableOrder.order.items[itemIndex];
            }
        });

        setTableOrder(newTableOrder);
    }

    const createOrder = () => {
        var form = {};
        if (!tableOrder.order || tableOrder?.order?.items?.length < 0) {
            toast.error('Sipariş oluşturulamadı! Lütfen ürün ekleyin.');
            return;
        }

        form.items = tableOrder.order.items.map((item) => ({ item: item.item.id, portion: item.portion }));
        form.table = tableOrder.id;
        form.place = localStorage.getItem('place');
        const items = form.items;
        delete form.items;

        console.log(form);

        createOrderService(form, items)
            .then((data) => {
                console.log('createOrderService:', data);
                if (!data?.data?.errors) {
                    toast.success('Sipariş başarıyla oluşturuldu!');
                    setTableOrder(false);
                }
            })
    }

    const updateOrder = () => {
        var form = {};

        form.items = tableOrder.order.items.map((item) => ({ item: item.item.id, portion: item.portion, price: item.price }));
        form.orderId = tableOrder.order.id;
        const items = form.items;
        delete form.items;

        for (let item of items) {
            if (!item.portion) {
                delete item.portion
            }
        }

        console.log("form", form);
        console.log("items", items);

        updateOrderService(form, items)
            .then((data) => {
                console.log('updateOrderService:', data);
                if (!data?.data?.errors) {
                    toast.success('Sipariş başarıyla Güncellendi!');
                    setTableOrder(false);
                    loadTabs();
                }
            })
    }

    const cancelOrder = async () => {
        if (await confirm("Masayı iptal etmek istediğinize emin misiniz?")) {
            var form = {};

            form.orderId = tableSettings?.order?.id;
            form.cancel = true;

            console.log(form);

            updateOrderService(form, [])
                .then((data) => {
                    console.log('updateOrderService:', data);
                    if (!data?.data?.errors) {
                        setTableOrder(false);
                    }
                })
        }
    }

    useEffect(() => {
        let total = (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ? (parseFloat(0).toFixed(2)) : (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2);
        total = parseFloat(total);

        if (!numpadValue) {
            setNumpadValue(total);
        }

        if (numpadValue == NaN) {
            setNumpadValue(total);
        }
    }, [numpadValue])

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"} no-scrollbar`}>
                {/*Loading*/}

                {/*<div className=' d-flex align-items-center mb-2'>
                    {SocketService._socket?.connected ? <CheckCircleOutlined style={{ fontSize: "24px", color: "green" }} /> : <ExclamationCircleOutlined style={{ fontSize: "24px", color: "red" }} />}
                    <h3 className='ms-1'>Socket</h3>

                    <h1 className='ms-2 fw-bold '>{SocketService._socket?.listeners(localStorage.getItem('place')).length} Bağlantı</h1>
                </div>*/}

                <Container fluid={true} style={{
                    display: width <= 412 ? "flex" : "",
                    flexDirection: width <= 412 ? "row" : "",
                    alignItems: width <= 412 ? "center" : "",
                    background: "#3a3a3a",
                    height: width <= 412 ? "135px" : width > 412 && width < 576 ? "95px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }} className="d-flex align-items-center w-100">
                    <div ref={scrollRef} className=' d-flex flex-row w-100 overflow-y-auto '>
                        {tabs.map((tab, index) => (
                            <Button key={index} onClick={() => {
                                let newData = [...tabs];
                                newData.map((item) => item.active = false);
                                newData[index].active = true;
                                setTabs(newData);
                                setTabIndex(index);
                            }} size='large' className='rounded text-white border-0' style={{ backgroundColor: tab.active ? "#c72328" : "#3a3a3a" }}>
                                {tab.name}
                            </Button>
                        ))}
                    </div>
                </Container>

                <div className='tables' style={{ minHeight: "100vh", background: "#f8f4f4", boxShadow: "0px 0px 2px rgb(0 0 0 / 13%)" }}>
                    <div className={` d-flex justify-content-center align-items-center flex-column h-100 mt-5 mb-5 ${SocketService._socket?.connected && tabsLoading ? "d-none" : ""}`}>
                        <Spin spinning={!(SocketService._socket?.connected && tabsLoading)} tip={SocketService._socket.connected ? "Yükleniyor..." : "Sunucuya bağlanılıyor..."} />
                        <h3 className='mt-2'>{SocketService._socket.connected ? "Yükleniyor..." : "Sunucuya bağlanılıyor..."}</h3>
                    </div>
                    {
                        tabs.map((tab, index) => (
                            <div key={index} className={index === tabIndex ? '' : 'd-none'}>
                                <div className='w-100 d-grid' style={{
                                    gridTemplateColumns: width <= 412 ? "100%" : width <= 767 ? "50% 50%" : width <= 100 ? "33.3% 33.3% 33.3%" : width <= 1700 ? "33.3% 33.3% 33.3%" : "20% 20% 20% 20% 20%"
                                }}>
                                    {tab?.tables?.map((table, tableIndex) => (
                                        <div>
                                            <div onClick={() => {
                                                if ((selectTableMode.mergeMode ? (table.order && selectTableMode.id !== table.id) : !table.order) && selectTableMode) {
                                                    console.log("select or merge")
                                                    var newTabs = [...tabs];
                                                    var newSelectTable = { ...selectTableMode };
                                                    newSelectTable.newTableId = table.id;

                                                    newTabs[index].tables?.map((table) => table.selectMode = false);
                                                    newTabs[index].tables[tableIndex].selectMode = !newTabs[index].tables[tableIndex].selectMode;

                                                    setTabs(newTabs);
                                                    setSelectTableMode(newSelectTable);
                                                } else if (selectTableMode) {
                                                    return;
                                                } else if (table.order == null) {
                                                    setTableOrder(table)
                                                }
                                            }} className={`rounded pointer-event sipyo-border`} role={"button"} style={{ position: "relative", background: !table.order ? "#fff" : timeAgo(new Date(parseInt(table?.order?.createdAt)), currentTime).warn ? "#C33737" : "#27AE60", marginLeft: "15px", marginRight: "15px", marginTop: "15px", height: "150px" }}>
                                                {
                                                    (selectTableMode.mergeMode ? (table.order && selectTableMode.id !== table.id) : !table.order) && selectTableMode && (
                                                        <div className='shadowPulse-radio' style={{ position: "absolute", top: "-15px", right: "-15px", borderRadius: "100%", zIndex: 1 }}>
                                                            <input onChange={(e) => {
                                                                var newTabs = [...tabs];
                                                                var newSelectTable = { ...selectTableMode };
                                                                newSelectTable.newTableId = table.id;

                                                                newTabs[index].tables?.map((table) => table.selectMode = false);
                                                                newTabs[index].tables[tableIndex].selectMode = e.target.checked;

                                                                setTabs(newTabs);
                                                                setSelectTableMode(newSelectTable);
                                                            }} type='radio' checked={table?.selectMode} name='selectTable' value={table.id} className='medium-radio rounded-radio' />
                                                        </div>
                                                    )
                                                }

                                                {
                                                    width <= 767 ? <>
                                                        <div className='d-flex flex-column'>
                                                            <div className='d-flex flex-row me-1 mt-1'>
                                                                <div style={{ minWidth: table.order ? "70%" : "81%", minHeight: "24px" }} onClick={() => { if (selectTableMode) { return; } else { setTableOrder(table) } }} />
                                                                {table?.order ? <div className=' d-flex flex-row justify-content-between align-items-end rounded bg-white float-end ' style={{ fontSize: "32px", color: "#565656" }}>
                                                                    <div className='d-flex justify-content-center align-items-center p-1' style={{ fontSize: "16px" }} onClick={() => setFastPay(table)}><CreditCardFilled /></div>
                                                                    <div className='d-flex justify-content-center align-items-center p-1 sipyo-border-left' style={{ fontSize: "16px" }} onClick={() => setTableSettings(table)}><SettingFilled /></div>
                                                                </div> : <div className=' d-flex flex-row justify-content-between align-items-end rounded bg-white float-end ' style={{ fontSize: "32px", color: "#565656" }}>
                                                                    <div className='d-flex justify-content-center align-items-center p-1' style={{ fontSize: "16px" }} />
                                                                    <div className='d-flex justify-content-center align-items-center p-1' style={{ fontSize: "16px" }}><SettingFilled /></div>
                                                                </div>}
                                                            </div>
                                                            <div className={`d-flex flex-column justify-content-center align-items-center ${table.order ? "mt-3" : "mt-4-5"}`} style={{ height: "100%" }} onClick={() => { if (selectTableMode) { return; } else { setTableOrder(table) } }}>
                                                                <h5 className={`fs-5 ${table.order ? "text-white" : "text-black"}`}>{table?.name}</h5>
                                                                {table.order && <h5 className={`fs-5 fw-light ${table.order ? "text-white" : "text-black"}`}>{table?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2)}₺</h5>}
                                                                {table?.order && <h5 className={`fs-6 fw-light ${table.order ? "text-white" : "text-black"}`}>{table.order ? timeAgo(new Date(parseInt(table?.order?.createdAt)), currentTime).text : ""}</h5>}
                                                            </div>
                                                        </div>
                                                    </> : <Row className='h-100'>
                                                        <Col xs={16} sm={18} md={20} onClick={() => { if (selectTableMode) { return; } else { setTableOrder(table) } }}>
                                                            <div className='p-2 h-100 bg-transparent'>
                                                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                                                    <h5 className={`fs-5 ${table.order ? "text-white" : "text-black"}`}>{table?.name}</h5>
                                                                    {table.order ? <h5 className={`fs-6 fw-light ${table.order ? "text-white" : "text-black"}`}>{timeAgo(new Date(parseInt(table?.order?.createdAt)), currentTime).text}</h5> : <></>}
                                                                </div>
                                                                {
                                                                    table.order ? <>
                                                                        <div style={{ marginTop: "65px" }}>
                                                                            {/*JSON.stringify(table.order.items)*/}
                                                                            <h5 className={`fs-6 fw-light ${table.order ? "text-white" : "text-black"}`}>{table?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2)}₺</h5>
                                                                        </div>
                                                                    </> : <></>
                                                                }
                                                            </div>
                                                        </Col>
                                                        {
                                                            table.order ?
                                                                <>
                                                                    <Col xs={8} sm={6} md={4} style={{ height: "103%" }}>
                                                                        <div className=' h-100 bg-transparent d-grid' style={{ gridTemplateRows: "50% 46.5%" }}>
                                                                            <div onClick={() => setFastPay(table)} className='d-flex justify-content-center align-items-center' style={{ background: "#1e588f", borderTopRightRadius: "0.375rem" }}>
                                                                                <CreditCardOutlined style={{ fontSize: "24px", color: "#fff" }} />
                                                                            </div>
                                                                            <div onClick={() => setTableSettings(table)} className='d-flex justify-content-center align-items-center' style={{ background: "#B42626", borderBottomRightRadius: "0.375rem" }}>
                                                                                <SettingOutlined style={{ fontSize: "24px", color: "#fff" }} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                                : <></>
                                                        }
                                                    </Row>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>

                {
                    selectTableMode && (
                        <div className='d-flex justify-content-center '>
                            <div className=' position-fixed ' style={{ bottom: "100px", zIndex: 3 }}>
                                <Row gutter={[24, 24]}>
                                    <Col sm={24} md={12} className='d-flex justify-content-center'>
                                        <Button className=' rounded-5 shadowPulse ' style={{ height: "50px", width: "120px" }} onClick={() => {
                                            setSelectTableMode(false);
                                        }}>
                                            İptal
                                        </Button>
                                    </Col>
                                    <Col sm={24} md={12} className='d-flex justify-content-center'>
                                        <Button type='primary' className=' rounded-5 shadowPulse ' style={{ height: "50px", width: "120px" }} onClick={() => {
                                            if (selectTableMode.transferMode) {
                                                transferOrder();
                                            } else if (selectTableMode.mergeMode) {
                                                mergeOrder();
                                            }
                                        }}>
                                            Kaydet
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                }
            </div>

            <Modal
                zIndex={1700}
                title="Hızlı Öde"
                isOpen={fastPay}
                toggle={() => {
                    setFastPay(false);
                }}
                size='sm'
                centered
            >
                <ModalBody className='p-4'>
                    <h1 className=' fs-4 fw-bold'>{fastPay?.name}</h1>
                    <div className='w-100 d-flex flex-row justify-content-between'>
                        <h5 className=' fs-6 fw-normal '>Toplam:</h5>
                        <h5 className=' fs-6 fw-normal '>{fastPay?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2)}₺</h5>
                    </div>

                    <Row className='' style={{ marginTop: "2.5rem" }}>
                        {place?.cash && <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                            <Button onClick={() => { fastPayHandler("cash") }} className='text-white w-100 mx-4' size='large' style={{ background: "#c72328" }}>Nakit</Button>
                        </Col>}
                        {place?.creditCard && <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                            <Button onClick={() => { fastPayHandler("creditCard") }} className='text-white w-100 mx-4' size='large' style={{ background: "#c72328" }}>Kredi/Banka Kartı</Button>
                        </Col>}
                        {place?.setCard && <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                            <Button onClick={() => { fastPayHandler("setCard") }} className='text-white w-100 mx-4' size='large' style={{ background: "#c72328" }}>SetCard</Button>
                        </Col>}
                        {place?.metropol && <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                            <Button onClick={() => { fastPayHandler("metropol") }} className='text-white w-100 mx-4' size='large' style={{ background: "#c72328" }}>Metropol</Button>
                        </Col>}
                    </Row>
                </ModalBody>
            </Modal>

            <Modal
                zIndex={1700}
                title={`${payment.name}`}
                isOpen={payment}
                toggle={() => { setNumpadValue(null); setPayment(false) }}
                //size='xl'
                centered
                size="lg" style={{ maxWidth: '90%', width: '90%' }}
            >
                <ModalHeader toggle={() => { setNumpadValue(null); setPayment(false) }}>
                    <h1 className=' fs-4 fw-bold'>{payment?.name}</h1>
                </ModalHeader>
                <ModalBody style={{ padding: "0.65rem" }}>
                    <Row gutter={24}>
                        <Col xs={7} sm={7} md={5} lg={7} className='p-0'>
                            <div>
                                <div className='w-100 d-flex align-items-center' style={{ height: "55px", borderLeft: "0px", borderRight: "0px", border: "1px solid #CCCCCC" }}>
                                    <h1 style={{ fontSize: "19px" }} className=' ms-3 fw-semibold'>Siparişler</h1>
                                </div>
                                <div className='mx-3 overflow-y-auto ' style={{ minHeight: "510px", maxHeight: "510px" }}>
                                    {
                                        payment?.order?.items
                                            /*.filter((item, index, self) =>
                                                index === self.findIndex((t) => (
                                                    t.item?.id === item.item?.id && t.portion === item.portion
                                                ))
                                            )*/
                                            ?.sort((a, b) => a.item.name.localeCompare(b.item.name))
                                            ?.map((item, itemIndex) => (
                                                <div onClick={() => {
                                                    if (!payment.selectMode) {
                                                        return;
                                                    }

                                                    setNumpadValue((prevValue) => {
                                                        if (item.selected) {
                                                            return prevValue ? (parseFloat(prevValue) - (item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions?.find((plt) => plt.title == item.portion)?.price : item.item?.price)) : item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions?.find((plt) => plt.title == item.portion)?.price : item.item?.price;
                                                        } else {
                                                            return prevValue ? (parseFloat(prevValue) + (item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions?.find((plt) => plt.title == item.portion)?.price : item.item?.price)) : item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions?.find((plt) => plt.title == item.portion)?.price : item.item?.price;
                                                        }
                                                    });

                                                    var newPayment = { ...payment };
                                                    newPayment.order.items[itemIndex].selected = !item.selected;
                                                    setPayment(newPayment);
                                                }} className={`user-select-none ${item.paid ? " text-decoration-line-through " : ""}`}>
                                                    <div className='d-flex flex-row justify-content-between align-items-center border-bottom' style={{ minHeight: "40px" }}>
                                                        {
                                                            item?.portion ? <>
                                                                <div className='  d-flex flex-row align-items-center '>
                                                                    <h4 className='ms-2 fw-medium' style={{ fontSize: "19px" }}>{item.item.name} {/*<span>X {item?.quantity}</span>*/}</h4>
                                                                    <div className='ms-2 px-1 rounded-1 d-flex flex-row justify-content-start align-items-center' style={{ background: "#c72328", marginTop: "4px", marginBottom: "4px" }}>
                                                                        <h4 className='fw-medium text-white' style={{ fontSize: "12px", margin: "1px" }}>{item.portion}</h4>
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                <h4 className='ms-2 fw-medium' style={{ fontSize: "19px" }}>{item.item.name} {/*<span>X {item?.quantity}</span>*/}</h4>
                                                            </>
                                                        }
                                                        <div className='d-flex flex-row align-content-center justify-content-center'>
                                                            {
                                                                payment.selectMode && <>
                                                                    <Checkbox className='mx-1' checked={item.selected} />
                                                                </>
                                                            }
                                                            <h4 className='fw-bold' style={{ fontSize: "17px" }}>{(item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions?.find((plt) => plt.title == item.portion)?.price : item.item?.price)?.toFixed(2)}₺</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                            <div className='mx-3'>
                                {
                                    /*
                                    <div className='d-flex flex-row justify-content-between align-items-center mt-1' style={{ minHeight: "40px" }}>
                                        <h4 className='fs-6 fw-bold'>Toplam</h4>
                                        <h4 className='fs-6 fw-normal'>{payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2)}₺</h4>
                                    </div>
                                    */
                                }
                                <div>
                                    <Button className=' w-100 ' size='large' style={{ height: "55px" }} onClick={() => {
                                        if ((payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0))) <= 0) {
                                            toast.error('Bu sipariş için ödemenin tamamı alındı!');
                                            return;
                                        }

                                        let newPayment = { ...payment };
                                        newPayment.selectMode = !payment.selectMode;
                                        newPayment.order.items.map((item) => item.selected = false);
                                        
                                        setNumpadValue("0");
                                        setPayment(newPayment);
                                    }} type='primary'>Parçalı Öde{payment.selectMode ? "meyi kapat" : ""}</Button>
                                </div>
                                <div>
                                    {/*<Button type='primary' className='mt-2' disabled={
                                        !(payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) <= 0)
                                    } onClick={() => paidOrder()} >Parçalı Öde</Button>*/}
                                </div>
                            </div>
                        </Col>
                        <Col xs={10} sm={10} md={14} lg={10} className='p-0'>
                            <div className='h-100 d-flex flex-column justify-content-between'>
                                <div>
                                    <div className='w-100 d-flex align-items-center' style={{ height: "55px", borderLeft: "0px", borderRight: "0px", border: "1px solid #CCCCCC" }}>
                                        <div className='mx-3 d-flex flex-row justify-content-between align-items-center' style={{ minHeight: "40px", flex: 1 }}>
                                            <h4 style={{ fontSize: "19px" }} className='fw-semibold'>Toplam</h4>
                                            <h4 style={{ fontSize: "19px" }} className='fw-normal'>{payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2)}₺</h4>
                                        </div>
                                    </div>
                                    <div className=' border-end border-start  overflow-y-auto ' style={{ maxHeight: "250px" }}>
                                        <div className='mx-3'>
                                            {payment?.order?.payments?.map((payment, index) => (
                                                <div key={index} className='my-2 d-flex flex-row justify-content-between align-items-center' style={{ fontSize: "19px", borderRadius: "5px" }}>
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <div className='d-flex justify-content-center align-items-center text-white fw-bold px-4' style={{ minWidth: "50px", minHeight: "30px", background: "#c72328", borderRadius: "4px" }}>
                                                            {
                                                                payment?.paymentType == "cash" ? "Nakit"
                                                                    : payment?.paymentType == "creditCard" ? "Kredi Kartı"
                                                                        : payment?.paymentType == "setCard" ? "SetCard"
                                                                            : payment?.paymentType == "metropol" ? "Metropol"
                                                                                : "Kredi Kartı"
                                                            }
                                                        </div>
                                                        <DeleteOutlined className='ms-2' style={{ color: "#c72328" }} onClick={() => {
                                                            deletePayHandler(payment.id);
                                                        }} />
                                                    </div>
                                                    <h4 clssName='fw-medium' style={{ fontSize: "16px" }}>{payment.price?.toFixed(2)}₺</h4>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid #CCCCCC" }}>
                                    <div className='mx-3 d-flex flex-row justify-content-between align-items-center my-1' style={{ minHeight: "20px" }}>
                                        <h4 style={{ fontSize: "19px" }} className='fw-semibold'>Ödenecek Tutar</h4>
                                        <h4 style={{ fontSize: "19px" }} className='fw-medium'>
                                            {numpadValue ? (parseFloat(numpadValue).toFixed(2).toString()) : 
                                                (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString().includes("-") ?
                                                (parseFloat(0).toFixed(2).toString()) :
                                                (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0))?.toFixed(2)?.toString()
                                            }₺
                                        </h4>
                                        {/*<h3>{JSON.stringify(payment?.order)}</h3>*/}
                                    </div>
                                    {(payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0))) < 0 ? (<>
                                        <div className='mx-3 d-flex flex-row justify-content-between align-items-center my-1' style={{ minHeight: "20px" }}>
                                            <h4 style={{ color: "red", fontSize: "19px" }} className='fw-semibold'>Para Üstü</h4>
                                            <h4 style={{ color: "red", fontSize: "19px" }} className='fw-medium'>
                                                {(payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0))).toFixed(2).toString().replaceAll("-", "")}₺
                                            </h4>
                                        </div>
                                    </>) : <></>}

                                    <Numpad
                                        payment={payment}
                                        autoFocus
                                        disabled={payment.selectMode || (payment?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) - (payment?.order?.payments?.reduce((total, payment) => total + payment.price, 0) || 0) <= 0)}
                                        className='mx-3'
                                        //onChange={(value) => { payHandler(payment.paymentType, parseFloat(value)) }}
                                        inputValue={numpadValue}
                                        setInputValue={setNumpadValue}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={7} sm={7} md={5} lg={7} className=' p-0 '>
                            <div className='d-flex flex-column h-100'>
                                <div className='w-100 d-flex align-items-center' style={{ minHeight: "55px", borderLeft: "0px", borderRight: "0px", border: "1px solid #CCCCCC" }}>
                                    <h3 style={{ fontSize: "19px" }} className='ms-3 fw-semibold'>Ödeme Tipi</h3>
                                </div>
                                <div className=' d-flex flex-column justify-content-between h-100 '>
                                    <div className='d-flex flex-column '>
                                        {place?.cash && <Button /*type={payment?.paymentType == "cash" ? "primary" : undefined}*/ onClick={() => {
                                            var newPayment = { ...payment };
                                            newPayment.paymentType = "cash";

                                            payHandler(newPayment.paymentType, parseFloat(numpadValue));
                                        }} className=' my-2 mx-3 ' style={{ height: "40px" }}>
                                            <span className='fw-semibold fs-6'>
                                                Nakit
                                            </span>
                                        </Button>}
                                        {place?.creditCard && <Button /*type={payment?.paymentType == "creditCard" ? "primary" : undefined}*/ onClick={() => {
                                            var newPayment = { ...payment };
                                            newPayment.paymentType = "creditCard";

                                            payHandler(newPayment.paymentType, parseFloat(numpadValue));
                                        }} className=' my-2 mx-3 ' style={{ height: "40px" }}>
                                            <span className='fw-semibold fs-6'>
                                                Kredi / Banka Kartı
                                            </span>
                                        </Button>}
                                        {place?.setCard && <Button /*type={payment?.paymentType == "setCard" ? "primary" : undefined}*/ onClick={() => {
                                            var newPayment = { ...payment };
                                            newPayment.paymentType = "setCard";

                                            payHandler(newPayment.paymentType, parseFloat(numpadValue));
                                        }} className=' my-2 mx-3 ' style={{ height: "40px" }}>
                                            <span className='fw-semibold fs-6'>
                                                SetCard
                                            </span>
                                        </Button>}
                                        {place?.metropol && <Button /*type={payment?.paymentType == "metropol" ? "primary" : undefined}*/ onClick={() => {
                                            var newPayment = { ...payment };
                                            newPayment.paymentType = "metropol";

                                            payHandler(newPayment.paymentType, parseFloat(numpadValue));
                                        }} className=' my-2 mx-3 ' style={{ height: "40px" }}>
                                            <span className='fw-semibold fs-6'>
                                                Metropol
                                            </span>
                                        </Button>}
                                    </div>

                                    <div className=' d-flex flex-column '>
                                        <Button type={"primary"} onClick={() => closeOrder()} className=' my-2 mx-3 ' style={{ height: "55px" }}>
                                            <span className='fw-semibold fs-6'>
                                                Masayı Kapat
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal
                zIndex={1500}
                isOpen={tableOrder}
                fullscreen
                toggle={() => {
                    setTableOrder(false);
                }}
            >
                <ModalHeader cssModule={{ 'modal-title': 'd-flex flex-row justify-content-between align-items-center w-100' }} style={{ height: "90px", background: "#3a3a3a", borderBottom: "0px" }}>
                    <div>
                        <div className='w-100 d-flex flex-row text-white' style={{ justifyItems: "center" }}>
                            <LeftOutlined onClick={() => { setTableOrder(false) }} />
                            <h1 className='fs-4 fw-bold text-white mx-1'>{tableOrder?.name}</h1>
                        </div>
                    </div>
                    <div>
                        <Input.Search
                            size='large'
                            placeholder='Ara...'
                            style={{ minWidth: width <= 767 ? "" : "380px" }}
                            onChange={(e) => {
                                // ! Search items in menuCategories.items
                                if (e.target.value.length == 0) {
                                    setFindedItems(null);
                                    return;
                                }
                                let newFindedItems = [];
                                menuCategories.map((category) => {
                                    category.items.map((item) => {
                                        if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                            newFindedItems.push(item);
                                        }
                                    })
                                });

                                setFindedItems(newFindedItems);
                            }}
                        />
                    </div>
                </ModalHeader>
                <ModalBody className=' overflow-hidden ' style={{ padding: "0px" }}>
                    <div className='d-grid h-100' style={{ gridTemplateColumns: width <= 767 ? "100% 100%" : "70% 30%" }}>
                        <div className='menu'>
                            <div ref={scrollRef} className='d-flex flex-row overflow-x-auto overflow-y-hidden w-100 no-scrollbar' style={{ borderBottom: "1px solid #d9d9d9", maxHeight: "75px" }}>
                                {
                                    menuCategories.map((category, categoryIndex) => (
                                        <Button
                                            onClick={() => {
                                                let newData = [...menuCategories];
                                                newData.map((item) => item.active = false);
                                                newData[categoryIndex].active = true;
                                                setMenuCategories(newData);
                                            }}
                                            size='lg'
                                            className=' rounded-0 button-l '
                                            style={{ borderTop: "0px", borderLeft: "0px", borderRight: "1px solid #d9d9d9", minWidth: "190px", minHeight: "75px", fontSize: "24px", borderColor: "#d9d9d9", background: category?.active ? "#c72328" : "#fff", color: category?.active ? "#fff" : "#000" }}
                                        >{category?.name}</Button>
                                    ))
                                }
                            </div>
                            <div className=' overflow-y-auto ' style={{ height: `${height - 110}px`, background: "#F3F3F4" }}>
                                {findedItems ? <>
                                    <div className='mt-2 p-3'>
                                        <Row className='d-flex flex-row overflow-x-auto overflow-y-hidden w-100 no-scrollbar'>
                                            {
                                                findedItems.map((item, itemIndex) => {
                                                    return (
                                                        <div className='ms-2' onClick={() => {
                                                            if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                return;
                                                            }

                                                            addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                        }}>
                                                            <div className='rounded d-flex flex-column bg-white justify-content-between mt-2 mb-2 user-select-none' style={{ minHeight: "155px", minWidth: "250px", border: "0.5px solid #DCDCDC", cursor: "pointer" }} >
                                                                <div style={{ minHeight: "50px" }} onClick={() => {
                                                                    if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                        addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                    }
                                                                }}>
                                                                    <h4 className='mx-2 mt-2 fs-5 fw-semibold'>{item?.name}</h4>
                                                                </div>
                                                                <div style={{ minHeight: "25px" }} className='d-flex flex-row justify-content-between align-items-end mx-2 mb-2'>
                                                                    <div onClick={() => {
                                                                        if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                            addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                        }
                                                                    }}><h6 className='fs-5 fw-semibold'>{item?.price?.toFixed(2)}₺</h6></div>
                                                                    {tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0 ? <>
                                                                        <div className='d-flex flex-row align-items-center'>
                                                                            <Button size='small' onClick={() => { removeItem({ ...item }); }} type="primary" icon={<MinusOutlined className=' fs-4 ' />} />
                                                                            <h2 className='mx-2 fs-5'>{tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity || 0}</h2>
                                                                            <Button size='small' onClick={() => { addItem({ ...item, area: tableOrder?.area }); }} type="primary" icon={<PlusOutlined className=' fs-4 ' />} />
                                                                        </div>
                                                                    </> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </> :
                                    menuCategories.map((category, categoryIndex) => {
                                        return (
                                            <div className=''>
                                                {category?.active && category?.subcategory ? <div className=' overflow-auto '>
                                                    {category?.subCategories.map((subCategory, subCategoryIndex) => (
                                                        <div className='mt-2 p-3'>
                                                            <h1 className=' fw-medium ' style={{ fontSize: "24px" }}>{subCategory?.name}</h1>
                                                            <Row className='d-flex flex-row overflow-x-auto overflow-y-hidden w-100 no-scrollbar'>
                                                                {
                                                                    subCategory?.items.map((item, itemIndex) => (
                                                                        <div className='me-2' onClick={() => {
                                                                            if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                return;
                                                                            }

                                                                            addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                        }}>
                                                                            <div className='rounded d-flex flex-column bg-white justify-content-between mt-2 mb-2 user-select-none' style={{ minHeight: "125px", minWidth: "250px", border: "0.5px solid #DCDCDC", cursor: "pointer" }} >
                                                                                <div style={{ minHeight: "50px" }} onClick={() => {
                                                                                    if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                        addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                                    }
                                                                                }}>
                                                                                    <h4 className='mx-3 mt-3 fs-5'>{item?.name}</h4>
                                                                                </div>
                                                                                <div style={{ minHeight: "25px" }} className='d-flex flex-row justify-content-between align-items-end mx-3 mb-3'>
                                                                                    <div onClick={() => {
                                                                                        if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                            addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                                        }
                                                                                    }}><h6 className='fs-5'>
                                                                                            {item?.price?.toFixed(2)}
                                                                                            <span className='fw-normal'>₺</span>
                                                                                        </h6></div>
                                                                                    {tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0 ? <>
                                                                                        <div className={`d-flex ${width <= 767 ? "flex-column h-100" : "flex-row"} align-items-center`}>
                                                                                            <Button size='small' onClick={() => { removeItem({ ...item }); }} type="primary" icon={<MinusOutlined className=' fs-4 ' />} />
                                                                                            <h2 className={`${width <= 767 ? "my-2" : "mx-2"} fs-5`}>
                                                                                                {/*tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity || 0*/}
                                                                                                {tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id).length}
                                                                                            </h2>
                                                                                            <Button size='small' onClick={() => { addItem({ ...item, area: tableOrder?.area }); }} type="primary" icon={<PlusOutlined className=' fs-4 ' />} />
                                                                                        </div>
                                                                                    </> : <></>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </div>
                                                    ))}
                                                </div> : <>
                                                    {
                                                        category.active && (
                                                            <div className='mt-2 p-3'>
                                                                <Row className='d-flex flex-row overflow-x-auto overflow-y-hidden w-100 no-scrollbar'>
                                                                    {
                                                                        category?.items.map((item, itemIndex) => {
                                                                            return (
                                                                                <div className='ms-2' onClick={() => {
                                                                                    if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                        return;
                                                                                    }

                                                                                    addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                                }}>
                                                                                    <div className='rounded d-flex flex-column bg-white justify-content-between mt-2 mb-2 user-select-none' style={{ minHeight: "125px", minWidth: "250px", border: "0.5px solid #DCDCDC", cursor: "pointer" }} >
                                                                                        <div style={{ minHeight: "50px" }} onClick={() => {
                                                                                            if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                                addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                                            }
                                                                                        }}>
                                                                                            <h4 className='mx-2 mt-2 fs-5 fw-semibold'>{item?.name}</h4>
                                                                                        </div>
                                                                                        <div style={{ minHeight: "25px" }} className='d-flex flex-row justify-content-between align-items-end mx-2 mb-2'>
                                                                                            <div onClick={() => {
                                                                                                if (tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0) {
                                                                                                    addItem({ ...item, area: tableOrder?.area, table: tableOrder?.id });
                                                                                                }
                                                                                            }}><h6 className='fs-5 fw-semibold'>{item?.price?.toFixed(2)}₺</h6></div>
                                                                                            {tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity > 0 ? <>
                                                                                                <div className='d-flex flex-row align-items-center'>
                                                                                                    <Button size='small' onClick={() => { removeItem({ ...item }); }} type="primary" icon={<MinusOutlined className=' fs-4 ' />} />
                                                                                                    <h2 className='mx-2 fs-5'>{tableOrder?.order?.items?.filter((plt) => plt.item.id == item.id)[0]?.quantity || 0}</h2>
                                                                                                    <Button size='small' onClick={() => { addItem({ ...item, area: tableOrder?.area }); }} type="primary" icon={<PlusOutlined className=' fs-4 ' />} />
                                                                                                </div>
                                                                                            </> : <></>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                </>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className=''>
                            <div className='sipyo-title d-flex text-center align-items-center justify-content-center' style={{ borderBottom: "0.1px solid #d9d9d9", borderLeft: "0.1px solid #d9d9d9", minHeight: "75px" }}>
                                Siparişler
                            </div>
                            <div className='d-flex flex-column justify-content-between overflow-hidden'>
                                <div className=' overflow-y-auto overflow-x-hidden' style={{ height: `${tableOrder?.order?.items?.filter((plt) => plt.selected).length > 0 ? height - 426 : height - 290}px` }}>
                                    {
                                        // ! Render Unique items
                                        tableOrder?.order?.items
                                            /*.filter((item, index, self) =>
                                                index === self.findIndex((t) => (
                                                    t.item?.id === item.item?.id && t.portion === item.portion
                                                ))
                                            )*/
                                            ?.sort((a, b) => a.item.name.localeCompare(b.item.name))
                                            ?.map((item, itemIndex) => (
                                                <div style={{ borderBottom: "1px solid #d9d9d9" }}>
                                                    <div className='d-grid align-items-center' style={{ gridTemplateColumns: "60% 40%", minHeight: "50px" }}>
                                                        <div className='d-flex flex-row align-items-center'>
                                                            <Checkbox className='ms-2 item-selector' style={{ height: "21px", width: "21px" }} onChange={(e) => { selectItem(item, itemIndex) }} checked={item.selected} />
                                                            <div className=' d-flex flex-row align-items-center '>
                                                                <h4 className='ms-2 text-2'>{item.item.name} {/*<span>X {item?.quantity}</span>*/}</h4>
                                                                {item.portion && <div className='ms-2 px-1 rounded-1 d-flex justify-content-start align-items-center' style={{ background: "#c72328", marginTop: "4px", marginBottom: "4px" }}>
                                                                    <h4 className='fw-medium text-white' style={{ fontSize: "12px", margin: "1px" }}>{item.portion}</h4>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row justify-content-end align-items-center me-2'>
                                                            <div className='d-flex flex-row justify-content-start align-items-center fs-3 fw-medium '><h6 className='text-2 me-2'>
                                                                {(item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions[item?.item?.portions?.findIndex((plt) => plt.title == item.portion)].price : item.item.price)?.toFixed(2)}
                                                                <span className='fw-normal'>₺</span>
                                                            </h6></div>
                                                            <Button size='medium' onClick={() => { deleteItem(item); }} className='d-flex align-items-center justify-content-center me-1' icon={<DeleteOutlined className='text-white' />} style={{ background: "#c33737" }} />
                                                            <Button size='medium' onClick={() => { setItemSettings({ ...item, itemIndex: itemIndex }) }} className='d-flex align-items-center justify-content-center ' icon={<MoreOutlined className='text-white' />} style={{ background: "#c33737" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                                <div className='w-100 d-flex flex-column justify-content-end'>
                                    {
                                        tableOrder?.order?.items?.find((item) => item?.selected) ? <>
                                            <div className='' style={{ marginBottom: "10px" }}>
                                                <Row gutter={8} className='px-3'>
                                                    <Col xs={24} sm={24} md={12} lg={12} className='d-flex justify-items-center align-items-center mt-2'>
                                                        <Button onClick={() => { removeItems() }} className='border-0 button-l w-100' style={{ minHeight: "55px", fontSize: "24px", background: "#c72328", color: "#fff" }}>Ürünü Çıkar</Button>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} className='d-flex justify-items-center align-items-center mt-2'>
                                                        <Button onClick={() => { }} className='border-0 button-l w-100 opacity-50' style={{ minHeight: "55px", fontSize: "24px", background: "#c72328", color: "#fff" }} disabled >Ürünü Taşı</Button>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                                                        <Button onClick={() => { unselectAllItems() }} className='border-0 button-l w-100' style={{ minHeight: "55px", fontSize: "24px", background: "#686464", color: "#fff" }}>Seçimi İptal Et</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </> : <></>
                                    }
                                    <div className=' fs-4 w-100 d-flex justify-content-between px-3' style={{ borderTop: "1px solid #d9d9d9", padding: "10px" }}>
                                        <span className='sipyo-alttitle' style={{ fontWeight: "650", fontSize: "21.5px" }}>Toplam Tutar</span>
                                        <span className='sipyo-alttitle' style={{ fontWeight: "650", fontSize: "21.5px" }}>{(tableOrder?.order?.items.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) || 0)?.toFixed(2)}₺</span>
                                    </div>
                                    <Row gutter={8} className=' px-3 '>
                                        {tableOrder?.order?.id ? <>
                                            <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2'>
                                                <Button onClick={() => {
                                                    updateOrder();
                                                }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c72328", color: "#fff" }}>Kaydet</Button>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2'>
                                                <Button onClick={() => {
                                                    setFastPay(tableOrder);
                                                    setTableOrder(false);
                                                }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c9b618", color: "#fff" }}>Hızlı Öde</Button>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2'>
                                                <Button onClick={() => {
                                                    setPayment({ ...tableOrder, paymentType: "cash" });
                                                }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#309737", color: "#fff" }}>Öde</Button>
                                            </Col>
                                        </> : <>
                                            <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                                                <Button onClick={() => {
                                                    createOrder();
                                                }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c72328", color: "#fff" }}>Kaydet</Button>
                                            </Col>
                                        </>}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BottomSheet
                        className='my-bottom-sheet'
                        zIndex={1600}
                        blocking={false}
                        style={{ zIndex: 1600 }}
                        open={width <= 767 ? true : false}
                        sibling={false}
                        skipInitialTransition
                        defaultSnap={({ maxHeight }) => maxHeight / 6}
                        snapPoints={({ maxHeight }) => [
                            maxHeight - maxHeight / 10,
                            maxHeight / 6,
                            maxHeight * 0.6,
                            70,
                        ]}
                        //expandOnContentDrag={true}
                    >
                        <div>
                            <div className='d-flex flex-column justify-content-between align-items-center' style={{ minHeight: "40px", flex: 1 }}>
                                <h4 className='sipyo-alttitle fw-semibold'>Siparişler</h4>
                                <h4 className='sipyo-alttitle fw-normal' style={{ color: "#c72328" }}>{tableOrder?.order?.items?.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0)?.toFixed(2) || parseFloat(0).toFixed(2)}₺</h4>
                            </div>
                        </div>
                        <div className=' overflow-hidden d-flex flex-column justify-content-between overflow-hidden'>
                            <div className=' overflow-y-auto overflow-x-hidden' style={{ height: `${tableOrder?.order?.items?.filter((plt) => plt.selected).length > 0 ? height - 526 : height - 390}px` }}>
                                {
                                    // ! Render Unique items
                                    tableOrder?.order?.items
                                        /*.filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.item?.id === item.item?.id && t.portion === item.portion
                                            ))
                                        )*/
                                        ?.sort((a, b) => a.item.name.localeCompare(b.item.name))
                                        ?.map((item, itemIndex) => (
                                            <div style={{ borderBottom: "1px solid #d9d9d9" }}>
                                                <div className='d-grid align-items-center' style={{ gridTemplateColumns: "60% 40%", minHeight: "50px" }}>
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <Checkbox className='ms-2 item-selector' style={{ height: "21px", width: "21px" }} onChange={(e) => { selectItem(item, itemIndex) }} checked={item.selected} />
                                                        <div className=' d-flex flex-row align-items-center '>
                                                            <h4 className='ms-2 text-2'>{item.item.name} {/*<span>X {item?.quantity}</span>*/}</h4>
                                                            {item.portion && <div className='ms-2 px-1 rounded-1 d-flex justify-content-start align-items-center' style={{ background: "#c72328", marginTop: "4px", marginBottom: "4px" }}>
                                                                <h4 className='fw-medium text-white' style={{ fontSize: "12px", margin: "1px" }}>{item.portion}</h4>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-row justify-content-end align-items-center me-2'>
                                                        <div className='d-flex flex-row justify-content-start align-items-center fs-3 fw-medium '><h6 className='text-2 me-2'>
                                                            {(item.price != null && item.price != undefined ? item.price : item.portion ? item?.item?.portions[item?.item?.portions?.findIndex((plt) => plt.title == item.portion)].price : item.item.price)?.toFixed(2)}
                                                            <span className='fw-normal'>₺</span>
                                                        </h6></div>
                                                        <Button size='medium' onClick={() => { deleteItem(item); }} className='d-flex align-items-center justify-content-center me-1' icon={<DeleteOutlined className='text-white' />} style={{ background: "#c33737" }} />
                                                        <Button size='medium' onClick={() => { setItemSettings({ ...item, itemIndex: itemIndex }) }} className='d-flex align-items-center justify-content-center ' icon={<MoreOutlined className='text-white' />} style={{ background: "#c33737" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className='w-100 d-flex flex-column justify-content-end'>
                                {
                                    tableOrder?.order?.items?.find((item) => item?.selected) ? <>
                                        <div className='' style={{ marginBottom: "10px" }}>
                                            <Row gutter={8} className='px-3'>
                                                <Col xs={24} sm={24} md={12} lg={12} className='d-flex justify-items-center align-items-center mt-2'>
                                                    <Button onClick={() => { removeItems() }} className='border-0 button-l w-100' style={{ minHeight: "55px", fontSize: "24px", background: "#c72328", color: "#fff" }}>Ürünü Çıkar</Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} className='d-flex justify-items-center align-items-center mt-2'>
                                                    <Button onClick={() => { }} className='border-0 button-l w-100 opacity-50' style={{ minHeight: "55px", fontSize: "24px", background: "#c72328", color: "#fff" }} disabled >Ürünü Taşı</Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                                                    <Button onClick={() => { unselectAllItems() }} className='border-0 button-l w-100' style={{ minHeight: "55px", fontSize: "24px", background: "#686464", color: "#fff" }}>Seçimi İptal Et</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </> : <></>
                                }
                                <div className=' fs-4 w-100 d-flex justify-content-between px-3' style={{ borderTop: "1px solid #d9d9d9", padding: "10px" }}>
                                    <span className='sipyo-alttitle' style={{ fontWeight: "650", fontSize: "21.5px" }}>Toplam Tutar</span>
                                    <span className='sipyo-alttitle' style={{ fontWeight: "650", fontSize: "21.5px" }}>{(tableOrder?.order?.items.reduce((total, item) => total + (item.price != null && item.price != undefined ? item.price : item?.portion ? item?.item?.portions[item?.item?.portions?.findIndex(plt => plt.title == item?.portion) > -1 ? item?.item?.portions?.findIndex(plt => plt.title == item?.portion) : item?.item?.portions?.findIndex(plt => plt.defaultPortion)].price : item?.item?.price), 0) || 0)?.toFixed(2)}₺</span>
                                </div>
                                <Row gutter={8} className=' px-3 '>
                                    {tableOrder?.order?.id ? <>
                                        <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2'>
                                            <Button onClick={() => {
                                                updateOrder();
                                            }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c72328", color: "#fff" }}>Kaydet</Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2'>
                                            <Button onClick={() => {
                                                setFastPay(tableOrder);
                                                setTableOrder(false);
                                            }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c9b618", color: "#fff" }}>Hızlı Öde</Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} className='d-flex justify-items-center align-items-center mt-2 mb-2'>
                                            <Button onClick={() => {
                                                setPayment({ ...tableOrder, paymentType: "cash" });
                                            }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#309737", color: "#fff" }}>Öde</Button>
                                        </Col>
                                    </> : <>
                                        <Col xs={24} sm={24} md={24} lg={24} className='d-flex justify-items-center align-items-center mt-2'>
                                            <Button onClick={() => {
                                                createOrder();
                                            }} size='large' className='border-0 button-l w-100' style={{ fontSize: "24px", background: "#c72328", color: "#fff" }}>Kaydet</Button>
                                        </Col>
                                    </>}
                                </Row>
                            </div>
                        </div>
                    </BottomSheet>
                </ModalBody>
            </Modal>

            <Modal
                zIndex={1700}
                isOpen={tableSettings}
                toggle={() => {
                    setTableSettings(false);
                }}
                size='md'
            >
                <ModalHeader toggle={() => setTableSettings(false)}>
                    {tableSettings?.name}
                </ModalHeader>
                <ModalBody>
                    <Row className='p-2' gutter={[24, 24]}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='user-select-none d-flex flex-column justify-content-center align-items-center border' style={{ minHeight: "100px", cursor: "pointer" }} onClick={() => {
                                setPayment({ ...tableSettings, paymentType: "cash" });
                            }}>
                                <SettingFilled style={{ fontSize: "30px", color: "#c72328" }} />
                                <h3 className=' mt-1'>Öde</h3>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='user-select-none d-flex flex-column justify-content-center align-items-center border' style={{ minHeight: "100px", cursor: "pointer" }} onClick={() => { setFastPay(tableSettings) }}>
                                <SettingFilled style={{ fontSize: "30px", color: "#c72328" }} />
                                <h3 className=' mt-1'>Hızlı Öde</h3>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='user-select-none d-flex flex-column justify-content-center align-items-center border ' style={{ minHeight: "100px", cursor: "pointer" }} onClick={() => {
                                setTableSettings(false);
                                setSelectTableMode({ ...tableSettings, transferMode: true, mergeMode: false });
                            }}>
                                <ExportOutlined style={{ fontSize: "30px", color: "#c72328" }} />
                                <h3 className=' mt-1'>Masayı Taşı</h3>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='user-select-none d-flex flex-column justify-content-center align-items-center border' style={{ minHeight: "100px", cursor: "pointer" }} onClick={() => cancelOrder()}>
                                <SettingFilled style={{ fontSize: "30px", color: "#c72328" }} />
                                <h3 className=' mt-1'>Masayı İptal Et</h3>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className='user-select-none d-flex flex-column justify-content-center align-items-center border' style={{ minHeight: "100px", cursor: "pointer" }} onClick={() => {
                                setTableSettings(false);
                                setSelectTableMode({ ...tableSettings, transferMode: false, mergeMode: true });
                            }} >
                                <MergeOutlined style={{ fontSize: "30px", color: "#c72328" }} />
                                <h3 className=' mt-1'>Masaları Birleştir</h3>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal
                zIndex={1700}
                isOpen={itemSettings}
                toggle={() => { setItemSettings(false) }}
                centered
            >
                <ModalHeader toggle={() => { setItemSettings(false) }}>
                    <h1 className=' fw-bold '>
                        {itemSettings?.item?.name}
                    </h1>
                </ModalHeader>
                <ModalBody>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div>
                                <Label>Fiyatı Güncelle</Label>
                                <Input
                                    type='number'
                                    addonBefore="₺"
                                    min={1}
                                    value={itemSettings?.price != null && itemSettings?.price != undefined ? itemSettings?.price : itemSettings?.portion ? itemSettings?.item?.portions[itemSettings?.item?.portions?.findIndex((plt) => plt.title == itemSettings.portion)]?.price : itemSettings?.item?.price}
                                    onChange={(e) => {
                                        if (parseFloat(e.target.value) < 0) { return; }
                                        if (parseFloat(e.target.value) == 0) { e.target.value = 1 };
                                        var newSetting = { ...itemSettings };
                                        newSetting.price = parseFloat(e.target.value);

                                        var newTableOrder = { ...tableOrder };
                                        newTableOrder.order.items[itemSettings.itemIndex].price = parseFloat(e.target.value);

                                        setItemSettings(newSetting);
                                        setTableOrder(newTableOrder);
                                    }}
                                />
                            </div>

                            <div className='d-flex flex-column mt-3'>
                                <Label>Adet</Label>
                                <Input readOnly addonBefore={<MinusOutlined onClick={() => { removeItem(itemSettings.item) }} />} addonAfter={<PlusOutlined onClick={() => {
                                    itemSettings.item.portions.forEach((item) => item.defaultPortion = false);
                                    itemSettings.item.portions[itemSettings.item.portions.findIndex((plt) => plt.title == itemSettings.portion)].defaultPortion = true;
                                    addItem(itemSettings.item);
                                }} />} value={tableOrder?.order?.items?.filter((item) => item.item.id == itemSettings?.item?.id).length} />
                            </div>

                            <Button type='primary' className='w-100 mt-3' onClick={() => removeItem({ ...itemSettings.item, itemIndex: itemSettings.itemIndex })}>Ürünü Sil</Button>
                            <Button type='primary' className='w-100 mt-3' onClick={() => {
                                let newTableOrder = { ...tableOrder };
                                newTableOrder.order.items[itemSettings.itemIndex].price = 0;
                                newTableOrder.order.items[itemSettings.itemIndex].free = true;

                                let newSetting = { ...itemSettings };
                                newSetting.price = 0;

                                setTableOrder(newTableOrder);
                                setItemSettings(newSetting);
                            }}>İkram Et</Button>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h2 className=' fw-bold fs-6'>Porsiyonlar</h2>
                            <div className=' mt-3'>
                                {
                                    itemSettings?.item?.portions?.map((portion, index) => (
                                        <div onClick={() => {
                                            var newSetting = { ...itemSettings };
                                            newSetting.portion = portion.title;

                                            var itemIndex = newSetting.itemIndex;

                                            var newTableOrder = { ...tableOrder };
                                            newTableOrder.order.items[itemIndex].portion = portion.title;
                                            setTableOrder(newTableOrder);


                                            setItemSettings(newSetting);

                                            /*var tabIndex = tabs?.findIndex(tab => tab.id == (itemSettings.area || itemSettings.item.area));
                                            var tableIndex = tabs[tabIndex]?.tables?.findIndex(tab => tab.id == (itemSettings.table || itemSettings.item.table));
                                            var itemIndex = newSetting.itemIndex;
    
                                            var newTabs = [...tabs];
                                            console.log("checking indexes", tabIndex, tableIndex, itemIndex)
                                            if (tabIndex > -1 && tableIndex > -1 && itemIndex > -1) {
                                                if (tabs[tabIndex].tables[tableIndex]?.order) {
                                                    //newTabs[tabIndex].tables[tableIndex].order.items[itemIndex].portion = portion?.title;
                                                    let newTable = newTabs[tabIndex].tables[tableIndex];
                                                    newTable.portion = portion?.title;
                                                    
                                                    newTabs[tabIndex].tables[tableIndex] = newTable;
                                                    
                                                    setTabs(newTabs);
                                                    setTableOrder(newTable);
                                                } else {
                                                    var newTableOrder = { ...tableOrder };
                                                    newTableOrder.order.items[itemIndex].portion = portion.title;
                                                    setTableOrder(newTableOrder);
                                                }
    
                                                setItemSettings(newSetting);
                                            } else {
                                                console.log("not found", tabIndex, tableIndex, itemIndex)
                                                return;
                                            }*/
                                        }} className=' p-1 mt-2 d-flex flex-row justify-content-between align-items-center' style={{ minHeight: "35px", cursor: "pointer", borderTop: "1px solid #eff2f7", borderRight: "1px solid #eff2f7", borderLeft: "1px solid #eff2f7", borderBottom: itemSettings?.portion == portion?.title ? "2px solid #c72328" : "1px solid #eff2f7" }}>
                                            <h4 className='fs-6 fw-semibold'>{portion?.title}</h4>
                                            <h4 className='fs-6 fw-semibold'>{(portion?.price)?.toFixed(2)}₺</h4>
                                        </div>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type='primary' onClick={() => setItemSettings(false)}>Kaydet</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default OrdersPage;
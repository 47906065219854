import React, { useState } from "react";
import { Button, Checkbox, Col, DatePicker, Input, Row } from 'antd';
import { CaretDownOutlined, DownloadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, ButtonGroup, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import { AreaColumns } from './columns/area'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import { loadBrandUsersService } from "../../services/brand-user";
import { loadReportsService } from "../../services/report";
import { ItemColumns } from "./columns/item";
import { CategoryColumns } from "./columns/category";

const Reports = () => {
    document.title = "Raporlar | Sipyo";

    const [width, setWidth] = useState(window.innerWidth);
    const [data, setData] = useState([]);
    const [managers, setManagers] = useState([]);
    const [filter, setFilter] = useState({ period: "DAILY", unit: "AREA" });
    const [loading, setLoading] = useState(false);

    const loadReports = () => {
        setLoading(true);
        loadReportsService(filter).then((res) => {
            res = res?.data;
            console.log(res)
            if (!res?.errors) {
                var data = res?.data?.getSaleReports;

                // ! Total
                /*data.push({
                    name: "Toplam",
                    //totalItem: data.reduce((a, b) => a + (b.totalItem || 0), 0),
                    totalPayment: data.reduce((a, b) => a + (b.totalPayment || 0), 0),
                    //price: data.reduce((a, b) => a + (b.price || 0), 0),
                    menuItemCountInOrders: data.reduce((a, b) => a + (b.menuItemCountInOrders || 0), 0),
                    percentage: 100,
                    totalOrder: data.reduce((a, b) => a + (b.totalOrder || 0), 0),
                    orderTotalPrice: data.reduce((a, b) => a + (b.orderTotalPrice || 0), 0),
                    category: "",
                    bold: true
                });*/
                setData(res?.data?.getSaleReports);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        loadReports(filter);

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        loadReports(filter);

    }, [filter]);

    const checkParameters = (managerParameters, object) => {
        for (var parameter of managerParameters) {
            if (!object?.hasOwnProperty(parameter.value)) {
                toast.error(`Lütfen ${parameter.label} alanını doldurunuz!`);

                return false;
            }
        }

        return true;
    }

    return (
        <React.Fragment>
            <div className={`${JSON.parse(localStorage.getItem('authUser'))?.place?.trial ? "page-content-trial" : "page-content"}`} style={{ borderRadius: "7px" }}>
                <Container fluid={true} style={{
                    background: "#3a3a3a",
                    height: width < 580 ? "135px" : "75px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                }}>
                    <Row gutter={16} justify="space-between" align="middle" style={{ height: width < 580 ? "135px" : "75px" }} className={`${width < 580 ? "py-2" : ""}`}>
                        {
                            width < 580 ? <>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <div className={`${width <= 991 && "justify-content-center"} w-100 d-flex flex-row `}>
                                        <Button size="large" type={filter.unit == "AREA" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "AREA" }) }}>Bölge Bazında</Button>
                                        <Button size="large" type={filter.unit == "MENU_ITEM" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "MENU_ITEM" }) }}>Ürün Bazında</Button>
                                        <Button size="large" type={filter.unit == "MENU_CATEGORY" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "MENU_CATEGORY" }) }}>Kategori Bazında</Button>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <DatePicker
                                        className={`me-4 text-center w-100`}
                                        picker={filter.period == "YEARLY" ? "year" : filter.period == "MONTHLY" ? "month" : filter.period == "WEEKLY" ? "week" : "date"}
                                        onChange={(date, dateString) => {
                                            if (filter.period == "YEARLY") {
                                                console.log(date.year);
                                                setFilter({ ...filter, year: date.year() })
                                            } else if (filter.period == "MONTHLY") {
                                                console.log(date.year())
                                                console.log(date.month())

                                                setFilter({ ...filter, year: date.year(), month: date.month() })
                                            } else if (filter.period == "WEEKLY") {
                                                console.log(date.week())

                                                //setFilter({...filter, week: date.week()})
                                            } else {
                                                console.log(date.year())
                                                console.log(date.month())
                                                console.log(date.date())

                                                setFilter({ ...filter, year: date.year(), month: date.month(), day: date.date() })
                                            }
                                        }}
                                        style={{ minWidth: "175px" }}
                                        size="large"
                                        placeholder={`${filter.period == "YEARLY" ? "Yıl" : filter.period == "MONTHLY" ? "Ay" : filter.period == "WEEKLY" ? "Hafta" : "Gün"} Seçiniz`}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <div className={` ${width <= 991 ? "justify-content-center" : "justify-content-end"} w-100 d-flex align-items-center `} style={{ float: "right" }}>
                                        <ButtonGroup className="me-2">
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "DAILY" })} type={filter.period == "DAILY" && "primary"}>
                                                Günlük
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "WEEKLY" })} type={filter.period == "WEEKLY" && "primary"}>
                                                Haftalık
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "MONTHLY" })} type={filter.period == "MONTHLY" && "primary"}>
                                                Aylık
                                            </Button>
                                        </ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                // ! Data to xlsx and download
                                                var resultData = [...data];
                                                if (filter.unit == "AREA") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Bölge": row.name,
                                                            "Satılan Ürün Sayısı": row.totalItem,
                                                            "Tutar (₺)": row.totalPayment,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                } else if (filter.unit == "MENU_ITEM") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Ürün": row.name,
                                                            "Miktar": row.menuItemCountInOrders,
                                                            "Kategori": row.category,
                                                            "Birim Fiyatı (₺)": row.price,
                                                            "Tutar (₺)": row.price * row.menuItemCountInOrders || 0,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                } else if (filter.unit == "MENU_CATEGORY") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Kategori": row.name,
                                                            "Miktar": row.totalOrder,
                                                            "Tutar (₺)": row.orderTotalPrice,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                }

                                                console.log(resultData);
                                                var xlsx = require('xlsx');
                                                var wb = xlsx.utils.book_new();
                                                var ws = xlsx.utils.json_to_sheet(resultData);
                                                xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
                                                xlsx.writeFile(wb, `${filter.unit == "MENU_CATEGORY" ? "Kategoriye Göre" : filter.unit == "MENU_ITEM" ? "Ürüne Göre" : "Bölgeye Göre"} Raporlar.xlsx`);


                                            }}
                                            icon={<DownloadOutlined style={{ fontSize: "22px" }} />}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                            size="large"
                                        >
                                            İndir
                                        </Button>

                                    </div>
                                </Col>
                            </> : <>
                                <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={16} >
                                    <div className={`${width <= 991 && "justify-content-center"} w-100 d-flex flex-row `}>
                                        <Button size="large" type={filter.unit == "AREA" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "AREA" }) }}>Bölge Bazında</Button>
                                        <Button size="large" type={filter.unit == "MENU_ITEM" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "MENU_ITEM" }) }}>Ürün Bazında</Button>
                                        <Button size="large" type={filter.unit == "MENU_CATEGORY" ? "primary" : ""} className=" mx-1 text-white " onClick={() => { setFilter({ ...filter, unit: "MENU_CATEGORY" }) }}>Kategori Bazında</Button>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={8} >
                                    <div className={` ${width <= 991 ? "justify-content-center" : "justify-content-end"} w-100 d-flex align-items-center `} style={{ float: "right" }}>
                                        <DatePicker
                                            className={`me-4 text-center`}
                                            picker={filter.period == "YEARLY" ? "year" : filter.period == "MONTHLY" ? "month" : filter.period == "WEEKLY" ? "week" : "date"}
                                            onChange={(date, dateString) => {
                                                if (filter.period == "YEARLY") {
                                                    console.log(date.year);
                                                    setFilter({ ...filter, year: date.year() })
                                                } else if (filter.period == "MONTHLY") {
                                                    console.log(date.year())
                                                    console.log(date.month())

                                                    setFilter({ ...filter, year: date.year(), month: date.month() })
                                                } else if (filter.period == "WEEKLY") {
                                                    console.log(date.week())

                                                    //setFilter({...filter, week: date.week()})
                                                } else {
                                                    console.log(date.year())
                                                    console.log(date.month())
                                                    console.log(date.date())

                                                    setFilter({ ...filter, year: date.year(), month: date.month(), day: date.date() })
                                                }
                                            }}
                                            style={{ minWidth: "175px" }}
                                            size="large"
                                            placeholder={`${filter.period == "YEARLY" ? "Yıl" : filter.period == "MONTHLY" ? "Ay" : filter.period == "WEEKLY" ? "Hafta" : "Gün"} Seçiniz`}
                                        />
                                        <ButtonGroup className="me-2">
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "DAILY" })} type={filter.period == "DAILY" && "primary"}>
                                                Günlük
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "WEEKLY" })} type={filter.period == "WEEKLY" && "primary"}>
                                                Haftalık
                                            </Button>
                                            <Button size="large" className=" text-white " onClick={() => setFilter({ ...filter, period: "MONTHLY" })} type={filter.period == "MONTHLY" && "primary"}>
                                                Aylık
                                            </Button>
                                        </ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                // ! Data to xlsx and download
                                                var resultData = [...data];
                                                if (filter.unit == "AREA") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Bölge": row.name,
                                                            "Satılan Ürün Sayısı": row.totalItem,
                                                            "Tutar (₺)": row.totalPayment,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                } else if (filter.unit == "MENU_ITEM") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Ürün": row.name,
                                                            "Miktar": row.menuItemCountInOrders,
                                                            "Kategori": row.category,
                                                            "Birim Fiyatı (₺)": row.price,
                                                            "Tutar (₺)": row.price * row.menuItemCountInOrders || 0,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                } else if (filter.unit == "MENU_CATEGORY") {
                                                    resultData = resultData.map((row) => {
                                                        return {
                                                            "Kategori": row.name,
                                                            "Miktar": row.totalOrder,
                                                            "Tutar (₺)": row.orderTotalPrice,
                                                            "Oran": row.percentage
                                                        }
                                                    })
                                                }

                                                console.log(resultData);
                                                var xlsx = require('xlsx');
                                                var wb = xlsx.utils.book_new();
                                                var ws = xlsx.utils.json_to_sheet(resultData);
                                                xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
                                                xlsx.writeFile(wb, `${filter.unit == "MENU_CATEGORY" ? "Kategoriye Göre" : filter.unit == "MENU_ITEM" ? "Ürüne Göre" : "Bölgeye Göre"} Raporlar.xlsx`);


                                            }}
                                            icon={<DownloadOutlined style={{ fontSize: "22px" }} />}
                                            style={{ backgroundColor: "#c72328", borderColor: "#c72328", color: "#fff" }}
                                            size="large"
                                        >
                                            İndir
                                        </Button>

                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </Container>

                {
                    loading ? <>
                        <div className=" w-100 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <LoadingOutlined style={{ color: "#c72328", fontSize: "42px" }} />
                        </div>
                    </> :
                        filter.unit == "AREA" ?
                            <>
                                <DataTable
                                    paginationComponentOptions={{ noRowsPerPage: false }}
                                    noHeader
                                    //pagination
                                    columns={!loading ? AreaColumns() : []}

                                    paginationPerPage={10}
                                    className="react-dataTable"
                                    sortIcon={<CaretDownOutlined />}
                                    //paginationDefaultPage={currentPage}
                                    //paginationComponent={DatatableCustomPagination}
                                    data={data}
                                />
                                <div style={{ width: "100%" }} id="row-Toplam" role="row" class="sc-jsEeTM itluUR rdt_TableRow">
                                    <div id="cell-1-Toplam" data-column-id="1" role="cell"
                                        class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf fewBcI cJTPDY rdt_TableCell" data-tag="allowRowEvents"
                                        minwidth="250px">
                                        <div data-tag="allowRowEvents">
                                            <b>
                                                Toplam
                                            </b>
                                        </div>
                                    </div>
                                    <div id="cell-2-Toplam" data-column-id="2" role="cell"
                                        class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                        <div data-tag="allowRowEvents">
                                            <span class=" paragraph-2 ">
                                                {data.reduce((a, b) => a + (b.totalItem || 0), 0)}
                                            </span>
                                        </div>
                                    </div>
                                    <div id="cell-3-Toplam" data-column-id="3" role="cell"
                                        class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                        <div data-tag="allowRowEvents">
                                            <span class=" paragraph-2 ">
                                                {data.reduce((a, b) => a + (b.totalPayment || 0), 0)}₺
                                            </span>
                                        </div>
                                    </div>
                                    <div id="cell-4-Toplam" data-column-id="4" role="cell"
                                        class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                        <div data-tag="allowRowEvents">
                                            <span class=" paragraph-2 ">
                                                100%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </> : filter.unit == "MENU_ITEM" ?
                                <>
                                    <DataTable
                                        paginationComponentOptions={{ noRowsPerPage: false }}
                                        noHeader
                                        //pagination
                                        columns={!loading ? ItemColumns() : []}

                                        paginationPerPage={10}
                                        className="react-dataTable"
                                        sortIcon={<CaretDownOutlined />}
                                        //paginationDefaultPage={currentPage}
                                        //paginationComponent={DatatableCustomPagination}
                                        data={data}
                                    />
                                    <div style={{ width: "100%" }} id="row-Toplam" role="row" class="sc-jsEeTM itluUR rdt_TableRow">
                                        <div id="cell-1-Toplam" data-column-id="1" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf fewBcI cJTPDY rdt_TableCell" data-tag="allowRowEvents"
                                            minwidth="250px">
                                            <div data-tag="allowRowEvents">
                                                <b>
                                                    -
                                                </b>
                                            </div>
                                        </div>
                                        <div id="cell-2-Toplam" data-column-id="2" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    Toplam
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-3-Toplam" data-column-id="3" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    {data.reduce((a, b) => a + (b.menuItemCountInOrders || 0), 0)}
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-4-Toplam" data-column-id="4" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-5-Toplam" data-column-id="5" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-6-Toplam" data-column-id="6" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    {data.reduce((a, b) => a + (b.totalPayment || 0), 0)}₺
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-7-Toplam" data-column-id="7" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    100%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <DataTable
                                        paginationComponentOptions={{ noRowsPerPage: false }}
                                        noHeader
                                        //pagination
                                        columns={!loading ? CategoryColumns() : []}

                                        paginationPerPage={10}
                                        className="react-dataTable"
                                        sortIcon={<CaretDownOutlined />}
                                        //paginationDefaultPage={currentPage}
                                        //paginationComponent={DatatableCustomPagination}
                                        data={data}
                                    />
                                    <div style={{ width: "100%" }} id="row-Toplam" role="row" class="sc-jsEeTM itluUR rdt_TableRow">
                                        <div id="cell-1-Toplam" data-column-id="1" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf fewBcI cJTPDY rdt_TableCell" data-tag="allowRowEvents"
                                            minwidth="250px">
                                            <div data-tag="allowRowEvents">
                                                <b>
                                                    Toplam
                                                </b>
                                            </div>
                                        </div>
                                        <div id="cell-2-Toplam" data-column-id="2" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    {data.reduce((a, b) => a + (b.totalOrder || 0), 0)}
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-3-Toplam" data-column-id="3" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    {data.reduce((a, b) => a + (b.totalPayment || 0), 0)}₺
                                                </span>
                                            </div>
                                        </div>
                                        <div id="cell-4-Toplam" data-column-id="4" role="cell"
                                            class="sc-hLQSwg sc-eDLKkx sc-jTQCzO kyDEvf gfKXFa cJTPDY rdt_TableCell" data-tag="allowRowEvents">
                                            <div data-tag="allowRowEvents">
                                                <span class=" paragraph-2 ">
                                                    100%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                }
            </div>

        </React.Fragment>
    );
};

export default Reports;
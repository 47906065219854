import React, { useEffect } from 'react';
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import logosm from "../../assets/images/logo-sm.png";
import { ButtonGroup } from 'reactstrap';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

const AuthenticationHeader = () => {
    var [width, setWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Row className='d-flex flex-row justify-content-between w-100 bg-white px-4' style={{ boxShadow: '0px 7px 14px rgba(52, 58, 64, 0.05)', position: "relative", zIndex: "20" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className='d-flex align-items-center' style={{ justifyContent: width <= 768 ? "center" : "start" }}>
                    <img src={logodark} alt="Logo" style={{ height: '110px' }} />
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className='h-100 d-flex align-items-center' style={{ justifyContent: width <= 768 ? "center" : "end" }}>
                    <ButtonGroup>
                        <Link to="/login"><Button style={{ fontWeight: "550" }} type="primary" className='mx-1' size='large'>Giriş Yap</Button></Link>
                        <Link to="/register"><Button style={{ fontWeight: "550" }} type="primary" className='mx-1' size='large'>Kayıt Ol</Button></Link>
                    </ButtonGroup>
                </div>
            </Col>
        </Row>
    );
};

export default AuthenticationHeader;